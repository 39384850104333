////////////////////////
// Variables Partials
//
// All of your variables should go in this file.
////////////////////////

//////////////////////////////
// Breakpoints
//////////////////////////////

$grid-gutter: 10px;

$lg-width:    1178px;
$md-width:    1024px;
$sm-width:    768px;
$xs-width:    480px;

$container-md-width: $md-width + $grid-gutter;
$container-sm-width: $sm-width + $grid-gutter;
$container-xs-width: $xs-width + $grid-gutter;

$breakpoint-md-width: $container-md-width + $grid-gutter;
$breakpoint-sm-width: $container-sm-width + $grid-gutter;
$breakpoint-xs-width: $container-xs-width + $grid-gutter;


//////////////////////////////
// Named Colors : http://chir.ag/projects/name-that-color
// Sorted by groups and intensities
//////////////////////////////


// White/Black/Gray
$colorWhite:            #ffffff;
$colorBlack:            #000000;
$colorRed:              #fa0003;
$colorTuna:             #323337;
$colorBostonBlue:       #3d8aae;
$colorPelorous:         #4c9fc7;
$colorPelorous2:        #50a0c5;
$colorPelorous3:        #5aa6c9;
$colorPelorous4:        #55a7cd;
$colorSwissCoffee:      #e0d7d6;
$colorShark:            #2a2a2c;
$colorShark2:           #1d1d1f;
$colorShark3:           #27282c;
$colorJumbo:            #808185;
$colorWoodsmoke:        #151516;
$colorShakespeare:      #60b1d7;
$colorWildSand:         #f6f6f6;
$colorWildSand2:        #f4f4f4;
$colorAlto:             #dcdcdc;
$colorGallery:          #ebeaea;
$colorEbb:              #ebebeb;
$colorSteelGray:        #1f212d;
$colorCrimson:          #e10f21;
$colorDoveGray:         #676767;
$colorJellyBean:        #296c8c;
$colorGray:             #828282;
$colorSteelBlue:        #4591b4;
$colorAbbey:            #45474b;
$colorAluminium:        #a7a8ac;
$colorBoulder:          #7d7d7d;
$colorScorpion:         #595959;
$colorSwissCoffee:      #e1d7d5;
$colorNileBlue:         #1a3d4d;
$colorSilver:           #c8c8c8;
$colorFountainBlue:     #5cc5bd;
$colorBittersweet:      #ff6566;
$colorManatee:          #999ba2;
$colorBlumine:          #205e7b;
$colorFirefly:          #0f2936;
$colorAstronaut:        #245971;
$colorGrayChateau:      #a7acaf;
$colorCuriousBlue:      #1d7fd7;
$colorThunderbird:      #d51414;
$colorDustyGray:        #979797;
$colorSunsetOrange:     #fc4e52;
$colorMercury:          #e7e7e7;
$colorMercury2:         #e4e2e2;
$colorCalypso:          #2d6f8e;
$colorNevada:           #667076;
$colorDarkerRed:        #E30511;
$colorBlogHeader:       #297DA9;
$colorBlogHeaderLink:   #0F6187;
$colorMenuFirst:        #E5E3E3;
$colorMenuSecond:       #EFEDED;
$colorMenuThird:        #F9F7F7;
$colorMenuHover:        #2F86AF;
$colorBlueForm:         #227daa;
$colorBlueMenu:         #2A7EA9;
$colorBlueSearch:       #E5F4FC;
$colorEnseignant:       #D74120;
$colorGrisEnseignant:   #323337;
$colorGrey20:           #b9bed0;
$colorGreenSuccess:     #569425;

// Default font family
$defaultFont: 'Nunito', sans-serif;
$fontMontserrat: 'Montserrat', sans-serif;
$fontWorkSans: 'Work Sans', sans-serif;
$fontPTSans: 'PT Sans', sans-serif;
$fontYanone: 'Yanone Kaffeesatz', sans-serif;
$fontLora: 'Lora', serif;

// ==========================================================================
// Plyr variables
// https://github.com/selz/plyr
// https://robots.thoughtbot.com/sass-default
// ==========================================================================

// Settings
$plyr-border-box:                       true !default;
$plyr-touch-action:                     true !default;
$plyr-sr-only-important:                true !default;

// Colors
$plyr-color-main:                       $colorThunderbird !default;

// Font sizes
$plyr-font-family:                      $defaultFont !default;
$plyr-font-size-small:                  14px !default;
$plyr-font-size-base:                   16px !default;

// Captions
$plyr-captions-bg:                      transparentize($colorBlack, .3) !default;
$plyr-captions-color:                   $colorWhite !default;
$plyr-font-size-captions-base:          $plyr-font-size-base !default;
$plyr-font-size-captions-medium:        ceil($plyr-font-size-base * 1.5) !default;
$plyr-font-size-captions-large:         ($plyr-font-size-base * 2) !default;

// Controls
$plyr-control-icon-size:                14px !default;
$plyr-control-spacing:                  5px !default;
$plyr-control-padding:                  ($plyr-control-spacing * .7) !default;
$plyr-video-controls-bg:                $colorBlack !default;
$plyr-video-control-color:              $colorWhite !default;
$plyr-video-control-color-hover:        $colorWhite !default;
$plyr-video-control-bg-hover:           $plyr-color-main !default;
$plyr-audio-controls-bg:                transparent !default;
$plyr-audio-controls-border:            none !default;
$plyr-audio-control-color:              $colorPelorous !default;
$plyr-audio-control-color-hover:        $colorGray !default;
$plyr-audio-control-bg-hover:           $plyr-color-main;

// Tooltips
$plyr-tooltip-bg:                       transparentize($colorBlack, .3) !default;
$plyr-tooltip-color:                    $colorWhite !default;
$plyr-tooltip-padding:                  ($plyr-control-spacing / 2) !default;
$plyr-tooltip-arrow-size:               4px !default;
$plyr-tooltip-radius:                   0px !default;

// Progress
$plyr-progress-loading-size:            25px !default;
$plyr-progress-loading-bg:              transparentize($colorBlack, .85) !default;
$plyr-video-progress-bg:                transparentize($colorWhite, .75) !default;
$plyr-video-progress-buffered-bg:       $plyr-video-progress-bg !default;
$plyr-audio-progress-bg:                transparentize($colorBlack, .8) !default;
$plyr-audio-progress-buffered-bg:       $plyr-audio-progress-bg !default;

// Range sliders
$plyr-range-track-height:               4px !default;
$plyr-range-thumb-height:               8px !default;
$plyr-range-thumb-width:                8px !default;
$plyr-range-thumb-bg:                   $colorThunderbird !default;
$plyr-range-thumb-border:               2px solid transparent !default;
$plyr-range-thumb-shadow:               0 !default;
$plyr-range-thumb-active-border-color:  $colorThunderbird !default;
$plyr-range-thumb-active-bg:            $plyr-video-control-bg-hover !default;
$plyr-range-thumb-active-scale:         1.25 !default;
$plyr-video-range-track-bg:             $plyr-video-progress-buffered-bg !default;
$plyr-audio-range-track-bg:             $plyr-audio-progress-buffered-bg !default;
$plyr-range-selected-bg:                $plyr-color-main !default;

// Breakpoints
$plyr-bp-screen-sm:                     $xs-width !default;
$plyr-bp-screen-md:                     $sm-width !default;
$plyr-bp-screen-lg:                     $lg-width !default;
