#postface {
  margin-top: 65px;

  @include sm {
    margin-top: 0;
  }
}
#block-lerobert-block-commerce-infos-rassurantes {
  background: $colorWhite;
  padding: 25px 0;
  color: $colorBlack;
  @include fullWidthBackground($colorWhite);
  &:before {
    border-top: 4px solid $colorAlto;
  }
  > .content {
    @extend %container;
    padding: 0;
    text-align: center;
    @include clearfix;

    .reference,
    .livraison,
    .paiement,
    .service {
      @extend .col-sm-3;
      padding: 95px 0 0 0;
      background-position: bottom 50px center;
      background-repeat: no-repeat;

      &:before {
        @include seperatorLine($color: $colorRed, $margin: 0 auto);
        margin-bottom: 5px;
      }

      @include sm {
        font-size: 2.6rem;
        width: 50%;
        margin-bottom: 10px;
        background-position: bottom 108px center;
        padding-top: 130px;
        float: left;

        &:before {
          @include seperatorLine($colorRed);
          margin: 20px auto !important;
        }
      }
    }

    .reference {
      background-image: url(../images/avantages-ref.png);
      @include sm {
        background-image: url(../images/avantages-ref-mobile.png);
      }
    }
    .livraison {
      background-image: url(../images/livraison.png);
      @include sm {
        background-image: url(../images/livraison-mobile.png);
      }
    }
    .paiement {
      background-image: url(../images/paiement.png);
      @include sm {
        background-image: url(../images/paiement-mobile.png);
      }
    }
    .service {
      background-image: url(../images/service-client.png);
      @include sm {
        background-image: url(../images/service-client-mobile.png);
      }
    }
  }
}

footer {
  color: $colorJumbo;
  font-size: 1.3rem;
  background-color: $colorTuna;
  a {
    color: $colorWhite;
    &:hover {
      color: $colorPelorous;
    }
  }
}
// Footer Top
.region-footer {
  @extend .row;
  padding: 56px 0 56px;
}

#block-lerobert-block-lerobert-footer-left {
  @extend .col-sm-offset-1;
  @extend .col-sm-2;

  .logo {
    margin-bottom: 26px;

    img {
      width: 160px;

      @include sm {
        width: 283px;
      }
    }
  }
  .social-link {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 0 22px 20px 0;
  }
}
#block-menu-menu-footer-menu {
  @extend .col-sm-6;
  * {
    box-sizing: border-box;
  }
  .content {
    @extend .row;
  }
  .menu {
    @include clearfix;
  }
  .expanded {
    &.first {
      @extend .col-sm-4;
    }
    &.last {
      @extend .col-sm-8;
      .menu {
        column-count: 2;
      }
    }
  }
  .nolink {
    display: block;
    margin-bottom: 20px;
    color: $colorPelorous2;
    font-size: 1.6rem;
    font-family: $fontMontserrat;
    text-transform: uppercase;
  }
  a {
    display: block;
    padding: 10px 0;
    font-size: 1.5rem;
  }

  li:nth-child(8n+0) a {
    padding-top: 0;
  }
}
#block-lerobert-block-lerobert-footer-right {
  @extend .col-sm-2;
  .faq-contact {
    li {
      margin-bottom: 24px;
    }
    a {
      font-family: $fontMontserrat;
      display: block;
      min-height: 36px;
      padding: 0 30px 0 58px;
      position: relative;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 16px;
      background-repeat: no-repeat;
      background-position: left center;
      &:before {
        content: '';
        display: block;
        width: 2px;
        height: 36px;
        position: absolute;
        left: 44px;
        background-color: $colorRed;
      }
    }
    .code {
      background-image: url('../images/code-icon-blanc.png');
    }
    .abonnement {
      background-image: url('../images/icon-abonnement-footer.png');
    }
    .faq {
      background-image: url('../images/questions_footer.png');
      background-size: 38px 34px;
    }
    .contact {
      background-image: url('../images/mail.png');
      background-size: 34px 20px;
    }
  }
}
// Footer Bottom
.region-footer-bottom {
  padding: 12px 0 10px;
  @include fullWidthBackground($colorShark);
}
.footer-bottom {
  .liens-utiles {
    text-align: center;
    li {
      display: inline-block;
      padding-right: 4px;
      &:before {
        content: '|';
        margin-right: 6px;
        color: $colorShark2;
      }
      &.first {
        &:before {
          content: '';
        }
      }
    }
  }
}
@include sm {
  .region-footer,
  #block-lerobert-block-lerobert-footer-left,
  #block-menu-menu-footer-menu,
  #block-menu-menu-footer-menu .content,
  #block-lerobert-block-lerobert-footer-right,
  #block-menu-menu-footer-menu .expanded.first,
  #block-menu-menu-footer-menu .expanded.last {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
  }
  #block-menu-menu-footer-menu .expanded.last .menu {
    column-count: 1;
  }
  .region-footer {
    padding: 30px 0 40px;
  }
  #block-lerobert-block-lerobert-footer-left {
    .logo {
      margin-bottom: 30px;
      text-align: center;
      a {
        display: inline-block;
      }
    }
    .social-links {
      margin-top: 10px;
      @include clearfix;

      .social-link {
        display: block;
        float: left;
        width: 33.3333%;
        height: 100px;
        margin-right: 0;
        margin-bottom: 38px;
        text-align: center;

        img {
          display: inline-block;
        }
      }
    }
  }
  #block-menu-menu-footer-menu {
    margin-bottom: 30px;
    .expanded {
      position: relative;
      border-top: 2px solid $colorShark2;
      border-bottom: 2px solid $colorShark2;
      &.last {
        border-top: 0;
      }
      &:after {
        position: absolute;
        right: 40px;
        top: 31px;
        content: "";
        width: 13px;
        height: 23px;
        background-image: url(../images/lg-blue-next-arrow.png);
        background-position: center;
        background-repeat: no-repeat;
      }
      span.nolink {
        font-size: 3rem;
        padding: 20px 40px;
        margin-bottom: 0;
      }
      > ul {
        display: none;
        background: $colorShark2;

        &.open {
          display: block;
        }

        a {
          padding: 20px 40px;
          font-size: 3rem;
          border-bottom: 1px solid $colorShark;
        }
        li.last a {
          border-bottom: 0;
        }
      }
      &.open {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
  #block-lerobert-block-lerobert-footer-right {
    box-sizing: border-box;
    padding: 0 20px;
    .faq-contact {
      a {
        min-height: 76px;
        padding: 0 0 0 110px;
        line-height: 76px;
        font-size: 3rem;
        &:before {
          left: 95px;
          height: 76px;
        }
      }
      .faq {
        background-size: 76px 71px;
      }
      .contact {
        background-size: 69px 42px;
      }
      .last {
        margin-bottom: 0;
      }
    }
  }
  .footer-bottom {
    font-size: 2.6rem;
    .liens-utiles {
      li {
        padding: 0;
        a {
          padding: 0 20px;
        }
      }
      li.first {
        display: block;
        width: 100%;
        margin-bottom: 40px;
      }
      li:nth-child(2) {
        &:before {
          content: "";
        }
      }
    }
    .region-footer-bottom {
      padding: 40px 0 50px;
    }
  }
}
.newsletter-select {
  box-shadow: none;

  .select2-results {

    > .select2-results__options {
      max-height: 100%;
    }

    @include sm {
      padding: 15px 0;
    }
  }

  .select2-results__option {
    font-family: $defaultFont;
    font-weight: 300;
    font-size: 1.4rem;
    padding: 1rem 3rem;
    cursor: pointer;
    border: none;
    &[aria-selected=true] {
      background-color: transparent;
      color: $colorBostonBlue;
    }

    @include sm {
      font-size: 2.6rem;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #E5F4FC;
  }
}

.block-edu-prospect {
  .content {
    @extend %container;
    box-sizing: border-box;
    padding: 0;
  }
}

// Email subscription form (footer and iframe)
#edu-prospect-email-form {
  padding: 5.4rem 0 4.5rem;
  text-align: center;
  @include fullWidthBackground($colorBlueForm);
  z-index: 9;

  &:before {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }

  @include sm {
    font-size: 0;
  }

  >div {
    display: flex;
    flex-wrap: wrap;
  }

  .form-item {
    display: block;
    flex: 1 1 auto;
    margin-right: 2rem;

    @include sm {
      margin-right: 0;
      margin-bottom: 2.5rem;
      width: calc(100% - 40px) !important;
    }

    &.form-item-profile {
      @include sm {
        max-width: 100%;
      }
    }

    &.form-type-select {
      &, & select {
        width: 22.7rem;
        flex: unset;

        @include sm {
          flex: 1 1 auto;
        }
      }
    }
  }

  .mailcatcher-title {
    color: $colorWhite;
    text-transform: uppercase;
    font-family: $fontMontserrat;
    font-size: 2.5rem;
    width: 100%;
    text-align: left;

    @include sm {
      padding: 0 2rem;
      font-size: 5.5vw;
      min-height: 10rem;
      white-space: nowrap;
    }

    @include xs {
      min-height: 5rem;
    }

    .logo-title {
      position: relative;
      padding-left: 9.5rem;
      margin-right: 22rem;

      @include sm {
        padding-left: 11rem;
        display: block;
        text-align: left;
        margin-right: 0;

        b {
          display: block;
        }
      }

      @include xs {
        padding-left: 8rem;
      }

      @include max(340px) {
        padding-left: 7rem;
      }

      ::before {
        content: "";
        width: 9.5rem;
        height: 7.4rem;
        background-image: url(../images/logo-bracket.png);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 8.3rem;
        position: absolute;
        left: 0;
        bottom: -1.5rem;

        @include sm {
          background-size: contain;
          background-position: left center;
          top: 50%;
          bottom: auto;
          transform: translateY(-50%);
          width: 10rem;
          height: 10rem;
        }

        @include xs {
          width: 7.5rem;
        }

        @include max(340px) {
          width: 6rem;
        }
      }
    }
  }

  input {
    height: 6.4rem;
    line-height: 6.4rem;
    background-color: $colorWhite;
  }

  input[type="checkbox"] + label::before {
    background-color: $colorWhite;
    border: 0.1rem solid $colorAlto;

    @include sm {
      transform: scale(0.45);
      transform-origin: top center;
    }
  }

  input[disabled="disabled"],
  input[type="checkbox"][disabled="disabled"] + label::before {
    opacity: 0.6;
  }

  input.error,
  input[type="checkbox"].error + label::before {
    border-color: $colorSunsetOrange;
  }

  label {
    font-family: $fontMontserrat;
    color: $colorWhite;
    font-size: 1.4rem;
    line-height: 1.6;
    margin-top: 2rem;
    text-align: left;
    padding-left: 3rem;

    @include sm {
      padding-left: 6.5rem;
      line-height: 1.6;
      font-size: 2.3rem;
    }

    &::before {
      transform: scale(1.2);
    }
  }

  a {
    text-decoration: underline;
    line-height: 1.4;
    color: inherit;

    @include sm {
      font-size: 1.4rem;
    }
  }

  input,
  select,
  .select2 {
    background-color: $colorWhite;
    margin: 30px 0 0;
    border: 0.2rem solid $colorAlto;
    height: 6.4rem;
    line-height: 6.4rem;

    @include sm {
      box-sizing: border-box;
      height: 66px;
      padding: 0 30px;
      font-size: 2.6rem;
      font-family: $defaultFont;
      font-weight: 300;
      margin: 30px 0 0;
      line-height: 66px;

      &[type="submit"] {
        font-family: $fontMontserrat;
        font-size: 3.1rem;
        line-height: 62px;
      }
    }
  }

  .select2 {
    margin: 30px 0 0;
  }

  .select2-container--default {
    .select2-selection--single {
      text-align: left;
      border: 0;

      .select2-selection__rendered {
          line-height: 6.4rem;
          padding: 0 26px 0 13px;
          font-family: $defaultFont;
          font-weight: 300;
          font-size: 1.4rem;
      }

      .select2-selection__arrow {
        height: 6.4rem;
        position: absolute;
        top: 0;
        right: 0;
        width: 27px;
        border-left: 0;

        b {
          border-color: transparent;
          border-width: 0;
          height: 6px;
          left: 50%;
          margin-left: -5.5px;
          margin-top: -3px;
          position: absolute;
          top: 50%;
          width: 11px;
          background-image: url(../images/select-arrow.png);
          background-color: $colorWhite;
          @include transition;

          @include sm {
            transition: none;
          }
        }
      }

      @include sm {
        height: 50px !important;

        .select2-selection__rendered {
          box-sizing: border-box;
          height: 66px;
          line-height: 66px !important;
          padding: 0 !important;
          font-size: 2.6rem !important;
        }

        .select2-selection__arrow {
          height: 66px !important;
          width: 59px !important;

          b {
            border-color: transparent;
            border-width: 0 !important;
            height: 22px;
            left: 50% !important;
            margin-left: -30px !important;
            margin-top: -11px !important;
            position: absolute;
            top: 50% !important;
            width: 44px !important;
            background-image: url(../images/lg-select-arrow.png);
            background-position: center;
            background-repeat: no-repeat;
            @include transition;

            @include sm {
              transition: none;
            }
          }
        }
      }
    }

    &.select2-container--open {
      .select2-selection__arrow {
        b {
          transform: rotate(-180deg);
        }
      }
    }
  }

  input {
    @include sm {
      width: calc(100% - 40px);
    }
  }

  input,
  select {
    width: 100%;

    @include sm {
      width: calc(100% - 40px);
      margin: 0;
    }
  }

  select,
  .select2 {
    width: 100% !important;
    max-width: 100%;;

    @include sm {
      width: calc(100% - 40px) !important;
    }
  }

  input[type="submit"] {
    width: 100%;
    max-width: 247px;
    color: $colorWhite;
    border: 2px solid $colorWhite;
    background-color: transparent;
    font-weight: 700;
    font-family: $defaultFont;
    font-size: 1.8rem;

    &:hover {
      background-color: $colorWhite;
      color: $colorPelorous;
    }

    @include sm {
      width: 100%;
      max-width: 365px;
      background-color: transparent;
      margin: 0 auto;
      font-size: 3.5rem;
    }
  }

  #mailcatcher-messages {
    margin-top: 2rem;

    @include sm {
      padding: 0 2rem;
    }

    &:not(.inline-messages) div.messages {
      &.error,
      &.warning {
        background-image: url(../images/icon-error-cross.png);
      }

      &.status {
        background-image: url(../images/icon-success-check.png);
      }
    }

    &.inline-messages {
      margin: 0;
    }

    &.inline-messages .messages {
      background-color: transparent;
      padding: 0;
      background-image: none;

      ul {
        margin: 0;

        li {
          margin-top: 2rem;
        }
      }

      &.error li {
        background-color: $colorSunsetOrange;
        background-image: url(../images/icon-error-cross.png);
      }

      &.status li {
        background-color: #40bf00;
        background-image: url(../images/icon-success-check.png);
      }
    }

    &:not(.inline-messages) div.messages,
    &.inline-messages li {
        border: 0.2rem solid $colorWhite;
        border-radius: 0.8rem;
        padding-left: 6rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-right: 1.5rem;
        background-repeat: no-repeat;
        background-position: left 10px center;
        background-size: 3.5rem;
        text-align: left;
        line-height: 1.6;

        @include xs {
          font-size: 1.6rem;
        }
    }
  }
}

// iframe newsletter
.page-newsletter-dis-moi-robert {
  background-color: $colorBlueForm;
  width: 100vw;
  overflow: hidden;

  #main-wrapper,
  .section {
    background-color: $colorBlueForm;

    &::before {
      background-color: $colorBlueForm;
    }
  }

  #edu-prospect-email-form {
    label {
      @include sm {
        font-size: 1.4rem;
        display: block;
        max-width: 100%;

        p {
          max-width: 100%;
          display: inline-block;
          font-size: 1.4rem;
        }
      }
    }

    input {
      @include sm {
        width: 100%;
        font-size: 2.1rem;
      }
    }

    input[type="submit"] {
      @include sm {
        font-size: 2.1rem;
        max-width: calc(100vw - 42px);
        min-width: 180px;
        overflow: hidden;
      }
    }

    .mailcatcher-title {
      @include sm {
        font-size: 5.5vw;
        padding: 0;
        min-height: 10rem;
      }

      @include xs {
        min-height: 7.5rem;

        .logo-title {
          white-space: nowrap;
        }
      }
    }
  }
}

.popin-savoir-plus {
  padding: 1.5rem 3.5rem 1.5rem 2rem;
  background-color: $colorWhite;
  text-align: left;
  border: 0.2rem solid $colorPelorous;
  width: 100%;
  max-width: 97rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 14;
  display: none;
  box-sizing: border-box;
  max-height: 70vh;
  overflow-y: auto;

  @include sm {
    top: 3rem;
    max-width: calc(100vw - 4rem);
    padding-top: 6rem;
  }

  &.popin-news-open {
    display: block;
  }

  .wrapper-texte h2 {
    color: $colorPelorous;
    font-size: 2rem;
    font-family: $fontMontserrat;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 4rem;
    text-align: center;
    display: block;
  }

  p {
    margin-bottom: 3rem;
    font-size: 1.8rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: $colorSunsetOrange;
  }

  .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem;
    height: 2rem;
    background-image: url(../images/icon-burger-close.svg);
    background-position: center center;
    background-size: 2rem;
    font-size: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.block-edu-prospect {
  #edu-prospect-email-form {
    @include fullWidthBackground($colorPelorous);

    &::after {
      position: absolute;
      content: "";
      background: #50a9d5;
      -ms-transform: skewX(25deg);
      transform: skewX(25deg);
      right: 90%;
      height: 100%;
      width: 100%;
      top: 0;

      @include sm {
        display: none;
      }
    }

    .mailcatcher-title {
      text-align: center;
    }
  }
}

.page-checkout-panier {
  .section {
    &.footer-top {
      display: none;
    }
  }
}
