// ========================================
//
// @file _grid.scss
// Main grid classes
//
// ========================================

// Import necessary mixins
@import 'framework';
@import 'system';

//widths from _variables.scss
$screen-xs: $xs-width;
$screen-sm: $sm-width;
$screen-md: $md-width;
$screen-lg: $lg-width;

$screen-xs-min: $screen-xs;
$screen-sm-min: $screen-sm;
$screen-md-min: $screen-md;
$screen-lg-min: $screen-lg;

$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: $screen-lg;

$grid-columns: 12;
$grid-gutter: 10px;
$grid-gutter-width: ($grid-gutter / 2);

$container-xs: ($screen-xs);
$container-sm: ($screen-sm);
$container-md: ($screen-md);
$container-lg: ($screen-lg);

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.container-fluid {
  @include container-fixed;
}

.row {
  @include make-row;
}

@include make-grid-columns;

@include make-grid(xs);

@media (min-width: $screen-sm-min) {
  @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
  @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}
