#form_account {
  width: 100% !important;
  font-family: $defaultFont;
  font-size: 1.6rem;
  margin: 0 -5px;
  @include clearfix;

  @include sm {
    margin: 0;
    font-size: 2.6rem;
    display: block;
  }

  &:after {
    padding-bottom: 25px;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .description {
    font-size: 1.4rem;

    @include sm {
      font-size: 2.4rem;
    }
  }

  .user-password {
    color: $colorNileBlue;
  }

  span.pwd_show {
    @include eyeShowPassword;
  }

  .fieldset {
    padding: 0;
    border: 0;
    height: auto;
    background: transparent;
  }

  .form-item-inscription-groupe {
    label {
      font-weight: normal;
    }
    label:first-child {
      font-size: 1.4rem;

      @include sm {
        font-size: 2.4rem;
      }
    }
  }
  #edit-inscription-groupe {
    margin-top: 60px;
    margin-bottom: 28px;
  }

  #div_profil_choice,
  #div_personal_number {
    box-sizing: border-box;
    border: 0;
    padding: 30px 60px;
    margin: 0 5px;
    width: calc(50% - 10px);
    min-height: 43.6rem;

    h2 {
      font-family: $fontMontserrat;
      font-size: 2rem;
      border-bottom: 0;
      padding-bottom: 0;
      text-transform: uppercase;
      &:after {
        @include seperatorLine;
      }
    }
    p {
      font-size: 1.4rem;
      font-weight: bold;
    }

    @include sm {
      width: 100%;
      margin: 0 0 40px;
      padding: 22px 18px;

      h2 {
        font-size: 3rem;

        &:after {
          width: 86px;
        }
      }

      p {
        font-size: 2.4rem;
      }
    }
  }

  #div_profil_choice {
    background-color: $colorWhite;
    color: $colorBlack;
    h2 {
      color: $colorBlack;
      &:after {
        background: $colorRed;
      }
    }
    p {
      width: 60%;

      @include sm {
        width: 100%;
      }
    }
    .form-submit {
      background-image: url(../images/lg-blue-next-arrow.png);
      background-size: 7px 13px;
      box-sizing: border-box;
      font-size: 1.6rem;
      font-weight: bold;
      height: 44px;
      margin-top: 17px;
      width: 100%;
      &:hover {
        background-image: url(../images/lg-white-next-arrow.png);
      }

      @include sm {
        background-size: auto;
        font-size: 2.6rem;
        height: 66px;
      }
    }
    label {
      margin-bottom: 14px;
    }
    input[type="radio"] + label.option {
      width: auto;
      &:before {
        background-image: url('../images/radio-sprite-white.png');

        @include sm {
          background-image: url('../images/radio-sprite-white-mobile.png');
        }
      }
    }
  }

  #div_personal_number {
    background-color: $colorPelorous;
    color: $colorWhite;
    h2 {
      color: $colorWhite;
      &:after {
        background: $colorBostonBlue;
      }
    }
    label {
      display: block;
      margin: 8px 0;
    }
    input {
      background-color: $colorWhite;
      box-sizing: border-box;
      height: 30px;

      @include sm {
        height: 70px;
      }
    }
    .form-submit {
      background-color: transparent;
      background-image: url(../images/lg-white-next-arrow.png);
      background-size: 7px 13px;
      border: 2px solid $colorWhite;
      box-sizing: border-box;
      color: $colorWhite;
      font-size: 1.6rem;
      font-weight: bold;
      height: 44px;
      margin: 25px 0;
      width: 100%;

      &:hover {
        background-color: $colorWhite;
        background-image: url(../images/lg-blue-next-arrow.png);
        color: $colorPelorous;
      }

      @include sm {
        background-size: auto;
        font-size: 2.6rem;
        height: 66px;
      }
    }
  }

  .institution_edit_link,
  .password_edit_link {
    text-align: left !important;
    a {
      background-color: $colorWhite;
      color: $colorPelorous;
      height: 40px;
      line-height: 40px;
      border: 2px solid $colorPelorous;
      box-sizing: content-box;
      text-decoration: none;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 8px 20px;

      @include sm {
        line-height: 70px;
        height: 70px;
        font-size: 2.6rem;
      }
    }
  }
}

.logged-in {
  #form_account {
    #div_profil_choice,
    #div_personal_number {
      width: 100%;

      .submit {
        text-align: left;
      }
    }
  }
}

.form-required {
  color: $colorNileBlue;
}

#user-login #div_profil_choice,
#user-login #div_personal_number {
  .fieldset {
    @include sm {
      height: auto;
    }
  }
}

.abonnement-details {
  a {
    color: $colorPelorous;
  }
}

#user-login-form,
#user-login {
  .form-type-password {
    position: relative;
  }
  span.pwd_show {
    @include eyeShowPassword;
    top: 17px;
    right: 5px;
  }

  a.show_pwd {
    display: none;
  }
}

#user-login {
  span.pwd_show {
    top: 7px;
  }
}

#bordsso-change-password-form {
  .form-type-password {
    position: relative;
  }
  span.pwd_show {
    @include eyeShowPassword;
    right: -155px;

    @include sm {
      top: 28px;
      right: 20px;
    }
  }

  a.show_pwd {
    display: none;
  }
}

.page-user-change-password {
  #form_account {
    span.pwd_show {
      @include eyeShowPassword;
      right: 150px;

      @include sm {
        top: 28px;
        right: 20px;
      }
    }
  }
}

#commerce-checkout-form-checkout {
  .form-item-connexion-pass {
    position: relative;
    span.pwd_show {
      @include eyeShowPassword;
      right: 4rem !important;
      top: 1.7rem !important;
    }
  }
}
