.page-taxonomy-term {
  h1.title {
    background-color: transparent;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-left: 100px;
    box-sizing: border-box;

    &:before {
      display: none;
    }

    @include sm {
      padding-left: 21px;
      padding-bottom: 10px;
    }
  }

  .accroche {
    box-sizing: border-box;
    font-weight: bold;
    line-height: 1.5em;
    margin-bottom: 10px;
    padding-left: 100px;
    width: 50%;

    @include sm {
      width: 100%;
      padding-left: 0;
    }
  }

  .field-name-field-collection-visuel {
    @include fullWidthElement;
    margin-top: 18px;
    margin-bottom: 27px;

    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }

  .field-name-field-collection-description  {
    box-sizing: border-box;
    line-height: 1.5em;
    margin-bottom: 40px;
    padding-left: 100px;
    width: 70%;

    @include sm {
      padding-left: 0;
      width: 100%;
    }
  }

  .videos-tab {
    display: flex;
    justify-content: center;
    @include fullWidthBackground($colorTuna);

    @include sm {
      display: block;
      padding-bottom: 35px;
    }

    .current-video {
      float: none;
    }

    &:before {
      display: block;
    }
  }

  .videos-tab + .field-collection-container {
    > .field-name-field-collection-ouvrages {
      > .field-items {
        > .field-item:first-of-type h3 {
          border-top: 0;
        }
      }
    }
  }

  .section .field-collection-view {
    padding: 0;
    margin: 0 0 28px 0;
    border-bottom: 0;

    h3 {
      @extend .field-name-field-para-titre;
      font-size: 3.2rem;
      margin-top: 0;
      border-top: 1px solid $colorSwissCoffee;
      margin-bottom: 26px;

      &:after {
        display: none !important;
      }

      @include sm {
        font-size: 4.2rem !important;
        margin-bottom: 0;
      }
    }
  }

  .field-name-field-collection-ouvrages-ouv {
    @include clearfix;

    > .field-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, 28rem);
      gap: 1rem;
    }
  }
}
