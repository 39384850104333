.page-prescription {
  h1.title {
    padding-bottom: 0;
    margin-bottom: 0;

    &:after {
      margin-bottom: 0;
    }
  }

  .field-type-text-long,
  .message-wrapper .prescription-paragraph:first-of-type,
  .prescription-forbidden-wrapper .prescription-paragraph-title {
    background-color: $colorWhite;
    padding-bottom: 28px;
    padding-top: 10px;
    margin-bottom: 46px;
    @include fullWidthBackground($colorWhite);

    &:before {
      z-index: -1;
    }
  }

  .prescription-wrapper {
    width: 83.33%;
    margin: 0 auto 100px auto;

    @include sm {
      width: 100%;
    }
  }

  .prescription-header-wrapper {
    display: flex;
    margin: 0 -5px 10px;

    @include sm {
      flex-wrap: wrap;
    }

    > div {
      background: $colorWhite;
      margin: 0 5px;
      padding: 0 0 30px;
      width: 50%;

      @include sm {
        width: 100%;
        margin-bottom: 20px;
      }

      ul {
        margin-bottom: 0 !important;
      }

      li {
        padding: 0 !important;
        background-image: none !important;
        display: flex;

        .field-label {
          flex: 1 1 40%;
          text-align: right;
          padding-right: 5px;
          font-weight: 600;

          &:after {
            content: " :";
          }
        }

        .field-value {
          flex: 1 1 60%;
          padding-left: 5px;
        }
      }

      &:nth-child(2) {
        .field-value {
          text-transform: uppercase;
        }
      }
    }
  }

  .prescription-encart-title {
    @include sectionTitle(dark, small);

    @include sm {
      font-size: 3rem;
    }
  }

  .prescription-attestation-wrapper {
    border: 2px solid $colorWhite;
    padding: 32px 10% 0;
    box-sizing: border-box;

    @include sm {
      padding: 20px;
    }

    .prescription-attestation-title {
      text-align: center;
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-weight: bold;
      font-size: 2rem;
      margin-bottom: 38px;

      @include sm {
        font-size: 3rem;
        margin-bottom: 20px;
      }
    }

    .prescription-paragraph:first-of-type {
      font-weight: bold;
      font-size: 1.8rem;

      @include sm {
        font-size: 2.8rem;
      }
    }

    li {
      background-image: url(../images/sm-blue-next-arrow.png) !important;
      background-size: auto !important;

      @include sm {
        background-image: url(../images/lg-blue-next-arrow.png) !important;
      }
    }
  }

  .btn-validation-wrapper {
    @include cta-gradient;
    display: block;
    background-color: $colorPelorous;
    margin: 40px auto 0;
    width: 208px;

    @include sm {
      width: 50%;
    }

    a {
      color: $colorWhite;
      display: inline-block;
      width: 100%;
      text-align: center;
      box-sizing: border-box;
      font-size: 1.8rem;

      @include sm {
        font-size: 2.8rem;
        line-height: 52px;
      }

      &:after {
        content: "";
        background-image: url(../images/sm-white-check.png);
        background-position: center;
        background-repeat: no-repeat;
        display: inline-block;
        height: 11px;
        width: 16px;
        margin-left: 18px;

        @include sm {
          background-image: url(../images/white-check.png);
          height: 19px;
          width: 24px;
          margin-left: 24px;
        }
      }
    }

    &:after {
      border: 0;
    }
  }

  .message-wrapper {
    background-image: none;
    background-color: transparent;
    color: $colorTuna;

    .prescription-paragraph {
      border-left: 2px solid $colorWhite;
      border-right: 2px solid $colorWhite;
      margin-bottom: 0;
      padding: 0px 10% 18px;

      &.highlighted {
        color: $colorFountainBlue;
        font-size: 2rem;
        font-weight: bold;
        margin: 15px 0 10px;
        text-align: center;
      }
    }

    .prescription-paragraph:nth-child(2) {
      border: 2px solid $colorWhite;
      border-width: 2px 2px 0 2px;
      padding: 32px 10% 18px;
      margin-bottom: 0;
      box-sizing: border-box;
      position: relative;

      @include sm {
        padding: 50px 20px 0;
      }

      &:before {
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;
        content: "";
        height: 50px;
        margin-top: -25px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        top: 0;

        @include sm {
          height: 80px;
          margin-top: -40px;
          width: 80px;
        }
      }
    }

    &.success {
      .prescription-paragraph:nth-child(2) {
        &:before {
          background-color: $colorFountainBlue;
          background-image: url(../images/white-check.png);

          @include sm {
            background-color: $colorFountainBlue;
            background-image: url(../images/check-white-mobile.png);
          }
        }
      }
    }

    &.error {
      .prescription-paragraph:nth-child(2) {
        &:before {
          background-color: $colorSunsetOrange;
          background-image: url(../images/white-times.png);

          @include sm {
            background-color: $colorSunsetOrange;
            background-image: url(../images/white-times-mobile.png);
          }
        }
      }
    }

    .prescription-paragraph:last-child {
      border: 2px solid $colorWhite;
      border-width: 0 2px 2px 2px;
      padding: 0 10% 32px;
      margin-bottom: 18px;

      @include sm {
        padding: 0 20px 20px;
      }
    }
  }

  .prescription-forbidden-wrapper {
    li {
      background-image: url(../images/sm-blue-next-arrow.png) !important;
      background-size: auto !important;
      background-position: left top 8px !important;

      @include sm {
        background-image: url(../images/lg-blue-next-arrow.png) !important;
        background-position: left top 13px !important;
      }
    }

    a {
      color: $colorPelorous2;
      text-decoration: underline;
    }

    .prescription-paragraph:first-of-type {
      background: none;
    }

    &.error {
      .prescription-paragraph:nth-child(2) {
        &:before {
          background-color: $colorSunsetOrange;
          background-image: url(../images/white-times.png);
          z-index: 1;

          @include sm {
            background-color: $colorSunsetOrange;
            background-image: url(../images/white-times-mobile.png);
          }
        }
      }
    }

    div.prescription-paragraph-title {
      &+ .item-list,
      &+ .prescription-paragraph {
        border: 2px solid $colorWhite;
        border-width: 2px 2px 0 2px;
        padding: 32px 10% 18px;
      }
    }

    .item-list {
      border: 2px solid $colorWhite;
      border-width: 0 2px 0 2px;
      padding: 0 10% 18px;
      margin-bottom: 0;
      box-sizing: border-box;

      @include sm {
        padding: 20px 20px 0;
      }
    }

    .prescription-paragraph {
      border: 2px solid $colorWhite;
      border-width: 0 2px 0 2px;
      padding: 0 10% 18px;
      margin-bottom: 0;
      box-sizing: border-box;
      @include sm {
        padding: 0 20px 18px;
      }

      &.title {
        color: $colorSunsetOrange;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        @include sm {
          font-size: 3rem;
        }
      }
    }

    .prescription-paragraph:last-child {
      border: 2px solid $colorWhite;
      border-width: 0 2px 2px 2px;
      padding: 0 10% 32px;
      margin-bottom: 40px;

      @include sm {
        padding: 0 20px 20px;
      }
    }
  }
}

.page-prescription-declaration {
  background-color: $colorWildSand;

  #content {
    @extend %container;
    @include sm {
      padding: 0 21px;
    }

    h4 {
      text-transform: none;
    }
  }

  .prescription-wrapper {
    @include sm {
      box-sizing: border-box;
    }
  }

  .prescription-attestation-wrapper {
    padding-bottom: 32px;
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
    font-family: $fontMontserrat;
    padding-top: 28px;

    &:after {
      @include seperatorLine($width: 57px, $margin: 4px 0);
    }

    @include sm {
      font-size: 3rem;
      box-sizing: border-box;

      &:after {
        width: 104px;
        height: 2px;
        margin: 5px 0 0;
      }
    }
  }

  p, ul {
    margin-bottom: 10px;

    &.prescription-paragraph2 {
      margin-bottom: 0!important;
    }
  }

  li {
    background-repeat: no-repeat;
    background-position: left top 4px;
    padding-left: 10px;

    @include sm {
      background-position: left top 9px;
      padding-left: 20px;
    }
  }
}
