.select2-container--default {
  @import "single";
  @import "multiple";

  &.select2-container--focus,
  &:focus,
  .select2-selection {
    outline: 0;
  }

  &.select2-container--open.select2-container--above {
    .select2-selection--single, .select2-selection--multiple {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.select2-container--open.select2-container--below {
    .select2-selection--single, .select2-selection--multiple {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid #aaa;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      background: transparent;
      border: none;
      outline: 0;
      box-shadow: none;
      -webkit-appearance: textfield;
    }
  }

  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role=group] {
      padding: 0;
    }

    &[aria-disabled=true] {
      color: #999;
    }

    &[aria-selected=true] {
      background-color: #f1f1f1;
    }

    .select2-results__option {
      padding-left: 1em;

      .select2-results__group {
        padding-left: 0;
      }

      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;

        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;

          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;

            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;

              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: #f1f1f1;
  }
  .select2-results__option {
    border-bottom: 1px solid #ededed;
    cursor: pointer;
    color: #000;
    display: block;
    padding: 11px;
    font-family: 'Montserrat', sans-serif;
    &:last-child {
      border-bottom: 0;
    }
  }
}
.select2-dropdown {
  background-color: white;

  border: 0;
  border-radius: 0;

  box-shadow: 0 0 5px rgba(#000, 0.16);
}
