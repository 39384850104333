#commerce-checkout-form-checkout {
  margin: 0 -10px;

  @include sm {
    @include clearfix;
    margin: 0 0 40px 0;
  }
}
.checkout-pane-register,
.checkout-pane-connexion {
  box-sizing: border-box;
  float: left;
  padding: 0 10px;
  width: 50%;
  margin-bottom: 40px;

  @include sm {
    padding: 0;
    width: 100%;
  }
}
.register,
.connexion {
  box-sizing: border-box;
  padding: 30px 60px;
  height: 450px;
  width: 100%;
  h3 {
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
  }
  p {
    font-size: 1.4rem;
  }

  @include sm {
    padding: 30px 19px;
    height: auto;

    h3 {
      font-size: 3rem;
      text-transform: uppercase !important;

      &:after {
        margin: 5px 0 0 !important;
      }
    }

    p {
      font-size: 2.4rem;
    }
  }
}
.register {
  background-color: $colorWhite;
  color: $colorBlack;
  h3 {
    &:after {
      background: $colorRed !important;
    }
  }
  p {
    width: 60%;
  }
  .form-submit {
    background-image: url(../images/lg-blue-next-arrow.png);
    background-size: 7px 13px;
    box-sizing: border-box;
    font-weight: bold;
    height: 44px;
    margin-top: 30px;
    width: 100%;
    &:hover {
      background-image: url(../images/lg-white-next-arrow.png);
    }
  }
  label {
    margin-bottom: 14px !important;
  }
  input[type="radio"] + label.option {
    width: auto;
    &:before {
      background-image: url('../images/radio-sprite-white.png');
    }

    @include sm {
      &:before {
        background-image: url('../images/radio-sprite-white-mobile.png');
      }
    }
  }

  @include sm {
    .form-submit {
      width: 364px;
      height: 70px;
      font-size: 2.6rem;
      background-size: auto;
      background-position: right 34px center;
      margin: 50px auto 0;
      display: block;
    }
  }
}
.connexion {
  background-color: $colorPelorous;
  color: $colorWhite;
  h3 {
    &:after {
      background: $colorBostonBlue !important;
    }
  }
  label {
    display: block;
    margin: 15px 0;
  }
  input {
    background-color: $colorWhite;
    box-sizing: border-box;
    height: 30px;
  }
  .form-submit {
    background-color: transparent;
    background-image: url(../images/lg-white-next-arrow.png);
    background-size: 7px 13px;
    border: 2px solid $colorWhite;
    box-sizing: border-box;
    color: $colorWhite;
    font-weight: bold;
    height: 44px;
    margin-top: 30px;
    width: 100%;
    &:hover {
      background-color: $colorWhite;
      background-image: url(../images/lg-blue-next-arrow.png);
      color: $colorPelorous;
    }
  }

  @include sm {
    margin-bottom: 40px;

    label {
      margin: 35px 0 11px;
    }

    input {
      height: 70px;
      width: 100%;
    }

    .form-submit {
      width: 364px;
      height: 70px;
      font-size: 2.6rem;
      background-size: auto;
      background-position: right 34px center;
      margin: 50px auto 0;
      display: block;
    }
  }
}
.forgot-password {
  margin-top: 25px;
  a {
    color: $colorNileBlue;
  }

  @include sm {
    margin-top: 50px;
  }
}
.recap-page-cart {
  margin-bottom: 60px;
  padding: 0 5px;
  h3 {
    background: $colorEbb;
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0 !important;
    padding: 0 27px 15px;
    text-align: center;

    @include sm {
      padding-bottom: 43px;
      text-transform: uppercase !important;
    }

    &:before {
      background-color: $colorRed;
      content: "";
      display: block;
      height: 8px;
      margin: 0 auto 10px;
      width: 89px;
    }
    &:after {
      display: none !important;
    }
  }
  > a {
    @include button;
    background-image: none;
    right: 27px;
    line-height: 30px;
    padding: 0 5px;
    position: absolute;
    top: 12px;
    vertical-align: middle;
    min-width: auto;
    &:hover {
      background-image: none;
    }

    @include sm {
      right: 40px;
      top: 15px;
      line-height: 70px;
      height: 70px;
      width: 140px;
      text-align: center;
      padding: 0;
      font-size: 2.4rem;
    }
  }
  .view-commerce-cart-summary-custom {
    background-color: $colorWhite;
    padding: 0 27px 35px;
    text-align: right;

    @include sm {
      padding: 0 17px 35px;
    }
  }
  ul {
    margin-bottom: 0 !important;
  }
  li {
    background-image: none !important;
    border-bottom: 1px solid $colorEbb;
    padding: 20px 70px 20px 0 !important;
    position: relative;
    .item-ouvrage {
      color: $colorBlack;
      line-height: 1;
      margin-bottom: 4px;
    }
    .item-quantite {
      font-family: $fontMontserrat;
      font-size: 1.4rem;
      position: absolute;
      right: 0;
      top: 20px;
      &:before {
        content: "X ";
      }
    }
    .item-format {
      img,
      .format {
        display: inline-block;
        font-size: 1.1rem;
        font-weight: bold;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .format {
        margin-left: 5px;
      }
    }

    @include sm {
      border-bottom: 3px solid $colorMercury2;
      padding: 20px 95px 25px 20px !important;

      .item-ouvrage {
        font-weight: bold;
        margin-bottom: 16px;
      }

      .item-quantite {
        font-size: 2.4rem;
        top: 27px;
      }

      .item-format {
        img,
        .format {
          font-size: 2.2rem;
        }
        .format {
          margin-left: 19px;
          font-weight: normal;
        }
      }
    }
  }
  table {
    margin-bottom: 0;
    tbody {
      tr {
        background-color: $colorWhite;
        display: block;
        margin: 8px 0;
        text-align: right;

        @include sm {
          border-bottom: 0;
        }
      }
      td {
        color: $colorBlack;
        display: inline-block;
        padding: 0;
        &.component-total {
          min-width: 125px;
          font-size: 1.4rem;
          font-family: $fontMontserrat;
          text-align: right;
        }

        @include sm {
          float: none;
          width: auto;

          .tablesaw-cell-content {
            max-width: 100%;
          }

          &.component-total {
            font-size: 2.4rem;
            min-width: 225px;
          }
        }
      }
      .component-type-commerce-price-formatted-amount {
        td {
          font-family: $fontMontserrat;
          font-size: 1.9rem;
          font-weight: bold;
          text-transform: uppercase;
          &.component-total {
            color: $colorPelorous;
            font-size: 1.8rem;
          }

          @include sm {
            font-size: 2.9rem;
            &.component-total {
              font-size: 2.8rem;
            }
          }
        }
      }
    }
  }
}
#content {
  .customer_profile_billing,
  .customer_profile_shipping,
  .email.form-wrapper {
    background-color: $colorWhite;
    box-sizing: border-box;
    float: left;
    padding: 26px;
    margin: 5px;
    width: calc(50% - 10px);

    @include sm {
      width: 100%;
      margin: 0;
      padding: 22px 18px;
      margin-bottom: 20px;
    }

    legend {
      float: left;
      font-family: $fontMontserrat;
      font-size: 2rem;
      font-weight: bold;
      width: 100%;
      text-transform: uppercase;
      &:after {
        @include seperatorLine($colorRed);
      }

      @include sm {
        font-size: 3rem;
        &:after {
          width: 86px;

        }
      }
    }
    label {
      display: block;
      font-weight: bold;
      margin-bottom: 4px;
      margin-top: 8px;
      &.option {
        font-weight: normal;
        display: inline;
      }

      @include sm {
        margin-top: 16px;

        &.option {
          display: inline-block;
        }
      }
    }
  }
}
.email.form-wrapper {
  input {
    width: 100%;
  }
}

.customer_profile_billing,
.customer_profile_shipping {
  box-sizing: border-box;
  color: $colorBlack;
  width: 50%;
  padding: 0 5px;
  fieldset {
    background-color: $colorWhite;
    box-sizing: border-box;
    color: $colorBlack;
    padding: 26px;
  }
  .field-name-commerce-customer-address {
    margin: 20px 0;
  }
  .form-submit {
    background-image: none;
    display: block;
    line-height: 44px;
    margin: 0 auto;
    height: auto;
    &:hover {
      background-image: none;
    }

    @include sm {
      line-height: 66px;
    }
  }
  input, select {
    width: 100%;
  }
  .addressfield-container-inline {
    > .form-item {
      width: calc(50% - 0.5em);
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-last-name,
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-last-name {
      margin-right: 0;
    }

    .form-item-customer-profile-billing-commerce-customer-address-und-0-first-name,
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-first-name {
      margin-left: 1em;
      margin-right: 0;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-postal-code,
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-postal-code {
      width: 30%;
    }
    .form-item-customer-profile-billing-commerce-customer-address-und-0-locality,
    .form-item-customer-profile-shipping-commerce-customer-address-und-0-locality {
      width: calc(70% - 1em);
      margin-right: 0;
    }

    .form-item-customer-profile-shipping-commerce-customer-address-und-0-civility,
    .form-item-customer-profile-billing-commerce-customer-address-und-0-civility {
      float: none;
    }
  }

  .select2-container--default {
    .select2-selection--single {
      border-color: $colorPelorous;
      height: 30px;

      .select2-selection__rendered {
        line-height: 30px;
        padding: 0 30px 0 14px;
        font-family: $defaultFont;
        font-size: 1.4rem;
      }

      .select2-selection__arrow {
        height: 28px;
        position: absolute;
        top: 1px;
        right: 1px;
        width: 30px;
        border-color: $colorPelorous;

        b {
          border-color: $colorDoveGray transparent transparent transparent;
          border-width: 6px 6px 0 6px;
          margin-left: -6px;
          margin-top: -3px;
        }
      }
    }

    @include sm {
      .select2-selection--single {
        height: 70px;

        .select2-selection__rendered {
          line-height: 70px;
          padding: 0 70px 0 14px;
          font-size: 2.4rem;
        }

        .select2-selection__arrow {
          height: 68px;
          width: 70px;

          b {
            border-width: 12px 12px 0 12px;
            margin-left: -12px;
            margin-top: -6px;
          }
        }
      }
    }
  }
  .select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: $colorBlack transparent transparent transparent;
        }
      }
    }
  }
}
.checkout-buttons {
  clear: both;
  display: flex;
  justify-content: center;
  padding: 31px 0;
  text-align: center;
  .checkout-continue {
    background-color: $colorPelorous;
    background-image: url(../images/lg-white-next-arrow.png);
    background-position: right 20px center;
    border: 0;
    color: $colorWhite;
    font-size: 1.8rem;
    font-family: $fontMontserrat;
    height: auto;
    line-height: 50px;
    padding: 0 50px 0 20px;
    margin-bottom: 10px;
    &:hover {
      background-color: $colorBostonBlue;
      background-image: url(../images/lg-white-next-arrow.png);
      box-shadow: 0 0 10px rgba($colorBlack, 0.2);
    }
  }
  .button-operator {
    display: block;
  }

  @include sm {
    padding: 40px 0;

    .checkout-continue {
      font-size: 2.8rem;
      line-height: 70px;
      padding: 0 50px 0 20px;
      margin-bottom: 0px;
      width: calc(100% - 40px);
    }
  }
}
h3.checkout-page-title {
  color: $colorBlack;
  font-size: 2.4rem !important;
  line-height: 40px;
  margin: 0 5px 25px;
  overflow: hidden;
  position: relative;
  .number {
    background-color: $colorPelorous;
    border-radius: 50%;
    color: $colorWhite;
    display: inline-block;
    height: 40px;
    margin-right: 10px;
    width: 40px;
    text-align: center;
  }
  &:after {
    background-color: $colorSilver !important;
    display: inline-block !important;
    margin: 28px 0 0 15px !important;
    position: absolute;
    width: 100% !important;
  }

  @include sm {
    font-size: 3.4rem !important;
    line-height: 50px;
    text-transform: uppercase !important;

    .number {
      height: 50px;
      margin-right: 20px;
      width: 50px;
      font-size: 3rem;
    }
  }
}
.recap-page-adresse,
.recap-page-livraison {
  position: relative;
  margin-bottom: 35px;
  h3 {
    color: $colorBlack;
    font-size: 2.4rem !important;
    line-height: 40px;
    margin: 0 5px 25px;
    overflow: hidden;
    position: relative;
    &:before {
      background-image: url(../images/green-check.png);
      background-position: center top 10px;
      background-repeat: no-repeat;
      border: 2px solid $colorFountainBlue;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: inline-block;
      height: 40px;
      margin-right: 10px;
      width: 40px;
      vertical-align: bottom;
      line-height: 1;
    }
    .number {
      background-color: $colorPelorous;
      border-radius: 50%;
      color: $colorWhite;
      display: inline-block;
      height: 40px;
      margin-right: 10px;
      width: 40px;
      text-align: center;
    }
    &:after {
      background-color: $colorSilver !important;
      display: inline-block !important;
      margin: 28px 0 0 15px !important;
      position: absolute;
      width: 100% !important;
    }

    @include sm {
      font-size: 3.4rem !important;
      line-height: 50px;
      text-transform: uppercase !important;

      &:before {
        background-position: center top 15px;
        height: 50px;
        margin-right: 20px;
        width: 50px;
      }
      .number {
        height: 50px;
        margin-right: 20px;
        width: 50px;
      }
    }
  }
  > a {
    @include button;
    background-color: $colorWildSand;
    background-image: none;
    right: 0;
    line-height: 30px;
    height: auto;
    margin-right: 5px;
    padding: 0 5px;
    position: absolute;
    top: 0;
    vertical-align: middle;
    min-width: auto;
    &:hover {
      background-image: none;
    }
    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      background-color: $colorWildSand;
      height: 100%;
      position: absolute;
      left: -12px;
    }

    @include sm {
      line-height: 70px;
      width: 140px;
      text-align: center;
      padding: 0;
      top: -12px;
    }
  }
}
.recap-page-adresse {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  h3 {
    flex: 0 1 100%;
  }
  @include clearfix;
  .recap-customer_profile_shipping,
  .recap-customer_profile_billing,
  .recap-email {
    background-color: $colorWhite;
    box-sizing: border-box;
    flex: 1 0 calc(50% - 10px);
    padding: 26px;
    margin: 0 5px;

    @include sm {
      padding: 30px 18px;
      flex: 1 0 calc(100% - 10px);
      margin: 10px 5px;
    }
  }
  h4 {
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    &:after {
      @include seperatorLine($colorRed);
    }

    @include sm {
      text-transform: uppercase !important;
    }
  }
  .field-type-addressfield {
  }
}
.mode_expedition {
  legend {
    display: none;
  }
  #edit-mode-expedition-expedition {
    display: flex;
    margin-top: 25px;

    @include sm {
      margin-top: 0;
    }
  }
  .form-item-mode-expedition-expedition {
    background-color: $colorEbb;
    box-sizing: border-box;
    color: $colorBlack;
    margin: 0 5px;
    padding: 28px;
    position: relative;
    width: 50%;
    cursor: pointer;
    @include transition;

    label {
      font-family: $fontMontserrat;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 0;
      position: static;
      &:before {
        left: auto;
        right: 28px;
        top: 50%;
        transform: translateY(-50%);
      }
      &:after {
        @include seperatorLine($colorRed);
      }
    }

    .amount {
      font-family: $fontMontserrat;
      font-size: 2rem;
      font-weight: bold;
      margin-top: 15px;
    }

    &:hover,
    &.selected {
      background-color: $colorEbb;
      background-color: $colorPelorous;
      color: $colorWhite;

      label {
        &:after {
          @include seperatorLine($colorWhite);
        }
      }
    }

    @include sm {
      padding: 30px 18px;

      label {
        font-size: 3rem;
        line-height: 1;

        &:after {
          width: 86px;
        }
      }

      .description {
        padding-right: 75px;
      }

      .amount {
        font-size: 3rem;
        margin-top: 28px;
      }
    }
  }
}
.recap-page-livraison {
  > div {
    background-color: $colorWhite;
    box-sizing: border-box;
    margin: 0 5px;
    padding: 0 26px;
    width: calc(50% - 10px);
    &.title {
      font-family: $fontMontserrat;
      font-size: 2rem;
      padding-top: 26px;
      padding-bottom: 10px;
      .name {
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
      }
      &:after {
        @include seperatorLine($colorRed);
        margin-bottom: 0 !important;
      }
    }
    &:last-child {
      padding-bottom: 26px;
    }
    &.amount {
      font-family: $fontMontserrat;
      font-size: 2rem;
    }

    @include sm {
      padding: 0 18px;
      width: 100%;
      margin: 0;

      &.title {
        font-size: 3rem;
        padding-top: 30px;
        text-transform: uppercase;
      }

      &:last-child {
        padding-bottom: 30px;
      }

      &.amount {
        font-family: $fontMontserrat;
        font-size: 3rem;
      }
    }
  }
}
.edu_coupon {
  margin-bottom: 30px;
  padding-left: 26px;
  .fieldset-wrapper {
    .form-item-edu-coupon-code,
    .btn-validate {
      display: inline-block;
    }
    .form-text {
      border-color: $colorWhite;
      background: $colorWhite;
      height: 30px;
      max-width: 258px;

      @include sm {
        height: 70px;
        font-size: 2.8rem;
        max-width: 400px;
      }
    }
    .btn-default {
      @include button;
      box-sizing: border-box;
      background-color: transparent;
      background-image: none;
      line-height: 26px;
      height: auto;
      margin-left: 10px;
      padding: 0 10px;
      min-width: auto;
      &:hover {
        background-image: none;
      }

      @include sm {
        line-height: 66px;
        font-size: 2.8rem;
      }
    }
  }
}
.code-used {
  margin-top: 20px;
  div {
    width: auto;
    font-weight: bold;
  }
  .delete {
    display: inline-block;

    a {
      @include button;
      display: inline-block;
      box-sizing: border-box;
      background-color: transparent;
      background-image: none;
      font-family: $defaultFont;
      line-height: 26px;
      height: auto;
      padding: 0 10px;
      min-width: auto;
      margin-left: 10px;
      &:hover {
        background-image: none;
      }
    }
  }
  .component-title {
    display: inline-block;
    float: none;

    &:after {
      @include seperatorLine($colorRed);
    }
  }

  .component-total {
    float: none;
    display: inline-block;
  }
}
.commerce_payment {
  .form-item-commerce-payment-payment-method {
    float: left;
    position: relative;

    @include sm {
      width: 50%;
    }

    label {
      background-color: $colorEbb;
      color: $colorBlack;
      display: block;
      font-family: $fontMontserrat;
      height: 50px;
      line-height: 50px;
      padding: 0px 10px;
      text-align: center;
      text-transform: uppercase;
      &:before {
        display: none;
      }

      @include sm {
        width: 100%;
        height: 78px;
        line-height: 78px;
        font-size: 2.4rem;
      }
    }
    input[type="radio"]:checked + label {
      background-color: $colorWhite;
    }
  }
  .ajax-progress {
    position: absolute;
    right: 3px;
    top: 3px;
    .message {
      display: none;
    }
  }
  #payment-details {
    background-color: $colorWhite;
    padding: 35px;
    text-align: center;

    @include sm {
      padding: 30px 18px;

      label[for="edit-commerce-payment-payment-details-credit-card-type"] {
        display: none;
      }
    }

    > div {
      margin: 15px 0;
    }
    .form-item,
    .commerce-credit-card-expiration {
      text-align: left;
    }
    label {
      color: $colorBlack;
      display: inline-block;
      margin-right: 28px;
      width: 25%;
      text-align: right;
      line-height: 36px;
      vertical-align: middle;

      @include sm {
        width: 100%;
        margin-right: 0;
        text-align: left;
      }
    }
    input, select {
      border: 1px solid $colorEbb;
      min-width: 70px;

      @include sm {
        border: 2px solid $colorEbb;
        width: 40%;
      }
    }

    .select2-container--default .select2-selection--single {
      border: 1px solid $colorEbb;
      border-radius: 0;
      height: 30px;

      .select2-selection__rendered {
        line-height: 28px;
        padding: 0 24px 0 12px;
        font-family: $defaultFont;
        font-size: 1.4rem;
      }

      .select2-selection__arrow {
        height: 28px;
        top: 1px;
        right: 1px;
        width: 24px;
        border-left: 1px solid $colorEbb;
      }

      @include sm {
        height: 70px;
        border: 2px solid $colorEbb;

        .select2-selection__rendered {
          line-height: 68px;
          padding: 0 44px 0 12px;
          font-size: 2.4rem;
        }

        .select2-selection__arrow {
          height: 68px;
          width: 44px;
          border-left: 2px solid $colorEbb;
        }
      }
    }
    .form-item,
    .form-radios,
    .commerce-month-year-divider {
        vertical-align: middle;
    }
    #edit-commerce-payment-payment-details-credit-card-number {
      width: 340px;

      @include sm {
        width: 580px;
      }
    }
    input[type="radio"] + label.option {
      width: auto;
      color: transparent;

      &:before {
        background-image: url('../images/radio-sprite-white.png');
        height: 100%;
        background-position: left center;
        background-repeat: no-repeat;

        @include sm {
          background-image: url('../images/radio-sprite-white-mobile.png');
        }
      }
      &[for="edit-commerce-payment-payment-details-credit-card-type-visa"],
      &[for="edit-commerce-payment-payment-details-credit-card-type-mastercard"],
      &[for="edit-commerce-payment-payment-details-credit-card-type-cb"] {
        width: 58px;
        height: 36px;
        position: relative;
        overflow: hidden;
        &:after {
          content: "";
          background-image: url(../images/visa.png);
          background-repeat: no-repeat;
          background-position: left top;
          width: 100%;
          height: 36px;
          display: block;
          position: absolute;
          left: 28px;
          background-color: #fff;
          top: 0;
        }

        @include sm {
          width: 144px;
          height: 50px;

          &:after {
            background-image: url(../images/visa-mobile.png);
            width: 100%;
            height: 50px;
            left: 80px;
          }
        }
      }
      &[for="edit-commerce-payment-payment-details-credit-card-type-mastercard"] {
        &:after {
          background-image: url(../images/mastercard.png);

          @include sm {
            background-image: url(../images/mastercard-mobile.png);
          }
        }
      }

      &[for="edit-commerce-payment-payment-details-credit-card-type-cb"] {
        &:after {
          background-image: url(../images/cb.png);

          @include sm {
            background-image: url(../images/cb-mobile.png);
          }
        }
      }
    }
    input[type="radio"]:checked + label {
      &:before {
        background-position: -110px center;

        @include sm {
          background-position: -100px center;
        }
      }
    }
    .adresse {
      color: $colorBlack;
      font-weight: bold;
      line-height: 1.5;
      &:before {
        @include seperatorLine($colorEbb, 187px, 1px, 19px auto);
      }

      @include sm {
        &:before {
          margin: 36px auto;
          width: 269px;
        }
      }
    }

    .edu-paybox-details {
      text-align: left;
    }
    .link-help-paybox {
      color: #4c9fc7;
      text-decoration: underline;
    }

  }
  #edit-commerce-payment-payment-details-credit-card-type {
    display: inline-block;
    .form-type-radio {
      display: inline-block;
    }
  }
}

#edit-cgv {
  margin-top: 20px;
  a {
    color: $colorPelorous;
    font-weight: bold;
  }

  @include sm {
    margin-top: 50px;

    label {
      line-height: 1.1;

      i {
        display: block;
        margin-left: -86px;
        margin-top: 24px;
      }
    }
  }
}
#edit-commerce-payment-payment-method {
  @include clearfix;
}
#commerce-checkout-form-complete {
  background-color: $colorWhite;
  color: $colorBlack;
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 26px;
  position: relative;
  margin-bottom: 26px;
  text-align: center;

  @include sm {
    margin-top: 50px;
    padding: 30px 18px;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  .commerce-pane-outer {
    &:before {
      background-color: $colorFountainBlue;
      background-image: url(../images/white-check.png);
      background-position: center center;
      background-repeat: no-repeat;
      border-radius: 50%;
      content: "";
      height: 50px;
      margin-top: -25px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      top: 0;

      @include sm {
        background-color: $colorFountainBlue;
        background-image: url(../images/check-white-mobile.png);
        height: 80px;
        margin-top: -40px;
        width: 80px;
      }
    }
    .title {
      color: $colorFountainBlue;
      font-size: 2rem;
      font-weight: bold;
      margin: 15px 0 10px;

      @include sm {
        font-size: 3rem;
        margin: 30px 0;
      }
    }

    &.checkout-pane-message_fin_erreur {
      &:before {
        background-color: $colorSunsetOrange;
        background-image: url(../images/white-times.png);
        @include sm {
          background-image: url(../images/white-times-mobile.png);
        }
      }

      .title {
        color: $colorSunsetOrange;
      }
    }
  }

  .addr {
    font-weight: bold;
    margin-bottom: 20px;
    &:before {
      @include seperatorLine($colorEbb, 187px, 1px, 19px auto);

      @include sm {
        width: 268px;
        height: 3px;
        margin: 30px auto;
      }
    }
  }
}

#commerce-paybox-offsite-redirect-form {
  text-align: center;
  img {
    margin: 20px auto;
  }
  .form-submit {
    font-size: 120%;
    font-weight: bold;
    height: 42px;
    margin: 50px 0;
    padding: 10px 25px 10px 20px;
  }
}

.page-checkout-panier  {
  h2 {
    display: none;
  }

  .view-commerce-cart-summary-custom {
    background-color: $colorWhite;
    padding: 0 27px 35px;
    text-align: right;

    @include sm {
      padding: 0 17px 35px;
    }

    ul {
      margin-bottom: 0 !important;
    }

    li {
      background-image: none !important;
      border-bottom: 1px solid $colorEbb;
      padding: 20px 70px 20px 0 !important;
      position: relative;

      .item-ouvrage {
        color: $colorBlack;
        line-height: 1;
        margin-bottom: 4px;
      }

      .item-quantite {
        font-family: $fontMontserrat;
        font-size: 1.4rem;
        position: absolute;
        right: 0;
        top: 20px;

        &:before {
          content: "X ";
        }
      }

      .item-format {
        img,
        .format {
          display: inline-block;
          font-size: 1.1rem;
          font-weight: bold;
          text-transform: uppercase;
          vertical-align: middle;
        }

        .format {
          margin-left: 5px;
        }
      }

      @include sm {
        border-bottom: 3px solid $colorMercury2;
        padding: 20px 95px 25px 20px !important;

        .item-ouvrage {
          font-weight: bold;
          margin-bottom: 16px;
        }

        .item-quantite {
          font-size: 2.4rem;
          top: 27px;
        }

        .item-format {
          img,
          .format {
            font-size: 2.2rem;
          }

          .format {
            margin-left: 19px;
            font-weight: normal;
          }
        }
      }
    }

    table {
      margin-bottom: 0;

      tbody {
        tr {
          background-color: $colorWhite;
          display: block;
          margin: 8px 0;
          text-align: right;

          @include sm {
            border-bottom: 0;
          }
        }

        td {
          color: $colorBlack;
          display: inline-block;
          padding: 0;

          &.component-total {
            min-width: 125px;
            font-size: 1.4rem;
            font-family: $fontMontserrat;
            text-align: right;
          }

          @include sm {
            float: none;
            width: auto;

            .tablesaw-cell-content {
              max-width: 100%;
            }

            &.component-total {
              font-size: 2.4rem;
              min-width: 225px;
            }
          }
        }

        .component-type-commerce-price-formatted-amount {
          td {
            font-family: $fontMontserrat;
            font-size: 1.9rem;
            font-weight: bold;
            text-transform: uppercase;

            &.component-total {
              color: $colorPelorous;
              font-size: 1.8rem;
            }

            @include sm {
              font-size: 2.9rem;
              &.component-total {
                font-size: 2.8rem;
              }
            }
          }
        }
      }
    }
  }

  .panier-action {
    text-align: right;
    .commerce-line-item-actions {
      > a {
        display: inline-block;
        background-color: $colorPelorous;
        background-image: url(../images/lg-white-next-arrow.png);
        background-position: right 40px center;
        background-repeat: no-repeat;
        border: 0;
        color: $colorWhite;
        font-size: 1.8rem;
        font-family: $defaultFont;
        height: auto;
        line-height: 59px;
        min-width: 162px;
        padding-right: 86px;

        &:hover {
          background-color: $colorBostonBlue;
          background-image: url(../images/lg-white-next-arrow.png);
          box-shadow: 0 0 10px rgba($colorBlack, 0.2);
        }
      }
    }

    @include sm {

      .commerce-line-item-actions {
        background-image: url(../images/lg-white-next-arrow.png);
        background-position: right 40px center;
        background-repeat: no-repeat;
        font-size: 2.8rem;
        font-family: $fontMontserrat;
        line-height: 70px;
        min-width: 373px;
      }
    }
  }

  .commerce-line-item-views-form {
    .form-actions {
      display: none;
    }
  }
}
