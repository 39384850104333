.select2-selection--single {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 0;
  height: 36px;

  .select2-selection__rendered {
    color: #000;
    line-height: 34px;
    padding: 0 37px 0 9px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
  }

  .select2-selection__placeholder {
    color: #000;
  }

  .select2-selection__arrow {
    height: 32px;

    position: absolute;

    top: 2px;
    right: 2px;

    width: 35px;
    border-left: 2px solid #000;

    b {
      border-color: #000 transparent transparent transparent;
      border-style: solid;
      border-width: 7px 7px 0 7px;

      height: 0;
      left: 50%;

      margin-left: -7px;
      margin-top: -3.5px;

      position: absolute;

      top: 50%;
      width: 0;
    }
  }
}

&[dir="rtl"] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--disabled {
  .select2-selection--single {
    background-color: #eee;
    cursor: default;

    .select2-selection__clear {
      display: none;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    border-color: #4c9fc7;
    .select2-selection__arrow {
      border-color: #4c9fc7;
      b {
        border-color: #4c9fc7 transparent transparent transparent;
      }
    }
  }
}
