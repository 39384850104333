header,
#main-wrapper,
footer {
  right:0;
  @include transition;
}
nav#nav {
  background: $colorShark3;
  color: $colorWhite;
  width: 30%;
  height: 100%;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  right: -30%;
  z-index: 99;
  overflow-y: auto;
  height: 100vh;
  @include transition(right);

  @include md {
    width: 50%;
    right: -50%;
  }

  @include sm {
    width: 100%;
    right: -100%;
    background: $colorTuna;
  }

  .close-button {
    right: 50px;
    top: 20px;

    @include sm {
      background-size: auto;
      height: 68px;
      width: 69px;
      top: 31px;
      right: 23px;

      &:hover {
        background-image: url(../images/mobile-close.png);
        background-size: auto;
      }
    }
  }

  .form-required {
    color: $colorRed;
  }

  .title,
  .content {
    background-color: $colorTuna;
    padding: 0 50px 0 32px;
  }
  .title {
    display: block;
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
    margin-top: -10px;
    text-transform: uppercase;
    text-align: left;
    background-color: transparent;
    padding-left: 0;
    margin-bottom: 20px;
    &:after {
      @include seperatorLine($color: $colorRed, $margin: 10px 0 0);
    }

    @include sm {
      font-size: 3rem;
      margin-top: 16px;
      height: 92px;
      @include clearfix;

      &:before {
        content: "";
        display: block;
        width: 92px;
        height: 92px;
        border-radius: 50%;
        background-color: $colorSunsetOrange;
        background-image: url(../images/moncompte-white-mobile.png);
        background-repeat: no-repeat;
        background-position: center top 10px;
        float: left;
        margin-right: 32px;
        margin-top: -16px;
      }

      &:after {
        clear: right;
        width: 104px;
        height: 2px;
      }
    }
  }

  .content {
    padding-top: 30px;

    @include sm {
      padding-bottom: 200px;
    }
  }
  label {
    display: block;
    margin-bottom: 15px;

    @include sm {
      font-size: 2.6rem;
      margin-bottom: 20px;
    }
  }
  input {
    background: $colorWhite;
    border: 0;
    width: 100%;

    @include sm {
      height: 70px;
      font-size: 2.6rem;
    }
  }
  .user-password {
    color: $colorManatee;
    margin: 25px 0 36px;

    a {
      text-decoration: underline;

      @include sm {
        text-decoration: none;
      }
    }
  }
  .user-register {
    padding-top: 38px;
    @include fullWidthBackground($colorShark3);

    @include sm {
      &:before {
        display: none;
      }
    }

    a {
      @include button-diagonal;
      font-size: 1.8rem;
      height: 44px;
      width: 100%;
    }

    @include sm {
      width: 100vw;
      position: relative;
      left: -32px;

      .title {
        display: none;
      }

      a {
        height: 70px;
        font-size: 2.8rem;
        font-weight: bold;
        color: $colorWhite;
        background-color: $colorSteelBlue;
        background-image: url(../images/lg-white-next-arrow.png);
        background-repeat: no-repeat;
        background-position: right 70px center;
        border: 0;
      }
    }
  }
  .form-item-name {
    margin-bottom: 22px;
  }
  .form-item-remember-me {
    margin: 30px 0;
    label {
      margin: 0;
    }
  }
  .form-submit {
    @include button-diagonal($colorWhite);
    font-size: 1.8rem;
    height: 44px;
    display: block;

    @include sm {
      height: 70px;
      font-family: $fontMontserrat;
      font-size: 2.6rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  input[type="checkbox"] + label {
    padding-left: 45px;
    line-height: 30px;
    &:before {
      width: 34px;
      height: 30px;
      background-image: url('../images/moncompte-checkbox-sprite.png');
    }
    &:hover {
      cursor: pointer;
    }

    @include sm {
      padding-left: 90px;
      line-height: 70px;
      &:before {
        width: 70px;
        height: 70px;
        background-image: url('../images/moncompte-checkbox-sprite-mobile.png');
      }
    }
  }
  input[type="checkbox"]:checked + label {
    &:before {
      background-position: -34px 0px;

      @include sm {
        background-position: -70px 0px;
      }
    }
  }
}

.usernav-open {
  header,
  #main-wrapper,
  footer {
    position: relative;
    right: 30%;

    @include md {
      right: 50%;
    }

    @include sm {
      right: 100%;
    }
  }
  nav#nav {
    right: 0;
    width: 30%;

    @include md {
      width: 50%;
    }

    @include sm {
      width: 100%;
    }
  }
}

.logged-in {
  nav#nav {
    @include sm {
      background: $colorShark3;
    }

    .content {
      background-color: inherit;

      @include sm {
        padding-top: 0;
      }
    }
    .ressources {
      padding: 25px 0;
      margin: 35px 0;
      @include fullWidthBackground($colorTuna);

      @include sm {
        margin: 0;
        padding: 30px 0;

        .title {
          &:before {
            display: block;
            margin-top: -27px;
          }

          &:after {
            margin-left: 124px;
          }
        }
      }

      .item-list {
        @mixin navRessourceSpacing {
          margin-left: -30px;
          margin-right: -48px;
          padding-left: 30px;
          padding-right: 48px;
        }
        .list {
          @include navRessourceSpacing;
          border-top: 1px solid $colorDoveGray;
          img {
            display: inline-block;
            background-color: $colorWhite;
            border-radius: 50%;
            margin-right: 23px;
            width: 35px;
            height: 35px;
            flex-shrink: 0;

            @include sm {
              margin-right: 43px;
              width: 60px;
              height: 60px;
            }
          }
          a {
            align-items: center;
            box-sizing: border-box;
            display: flex;
            color: $colorPelorous;
            height: 50px;
            width: 100%;
            padding-right: 38px;

            background-image: url(../images/resources-drag-icon.png);
            background-position: right center;
            background-repeat: no-repeat;

            @include sm {
              height: 118px;
              padding-right: 80px;
              background-image: url(../images/resources-drag-icon-mobile.png);
            }
          }
        }
        li {
          @include navRessourceSpacing;
          border-bottom: 1px solid $colorDoveGray;
        }
      }
      .link {
        text-align: right;
        color: $colorWhite;
        margin-top: 18px;

        a {
          text-decoration: underline;
        }
      }
    }
    .account {
      background-color: inherit;
      background-image: none;
      border-radius: 0;
      height: auto;
      text-indent: 0;
      width: auto;

      @include sm {
        margin-top: 45px;
      }
    }
    .title {
      display: block;
      font-family: $fontMontserrat;
      font-size: 2rem;
      text-transform: uppercase;
      &:after {
        @include seperatorLine($color: $colorRed, $margin: 10px 0 20px);
      }

      @include sm {
        font-size: 3rem;
        font-weight: bold;
        height: auto;
        margin-bottom: 36px;

        &:before {
          display: none;
        }

        &:after {
          width: 104px;
          height: 2px;
        }
      }
    }
    .avatar {
      align-items: center;
      background-color: $colorBittersweet;
      border-radius: 50%;
      display: inline-flex;
      font-size: 3.8rem;
      font-weight: 300;
      justify-content: center;
      height: 89px;
      text-transform: uppercase;
      width: 89px;
      vertical-align: middle;

      @include sm {
        font-size: 5.2rem;
        height: 124px;
        width: 124px;
      }
    }
    .name {
      align-items: center;
      display: inline-flex;
      font-family: $fontMontserrat;
      font-size: 2.2rem;
      height: 89px;
      margin-left: 17px;

      @include sm {
        font-size: 3.2rem;
        margin-left: 25px;
        height: 124px;
      }
    }
    a.list {
      background-image: url(../images/lg-blue-next-arrow.png);
      background-position: left center;
      background-size: 7px 14px;
      background-repeat: no-repeat;
      display: block;
      font-weight: bold;
      margin-bottom: 24px;
      padding-left: 20px;
      &:first-of-type {
        margin-top: 24px;
      }
      &:hover {
        color: $colorPelorous;
      }

      @include sm {
        background-size: 13px 23px;
        padding-left: 33px;
        margin-bottom: 44px;

        &:first-of-type {
          margin-top: 44px;
        }
      }
    }
    .logout {
      @include button-diagonal($colorWhite);
      display: flex;
      font-size: 1.8rem;
      height: 44px;
      margin-bottom: 28px;

      @include sm {
        font-size: 2.6rem;
        height: 70px;
        margin-bottom: 59px;
      }
    }
    .code,
    .assistance,
    .abonnement {
      background-position: left center;
      background-repeat: no-repeat;
      border-top: 1px solid $colorDoveGray;
      display: block;
      font-weight: bold;
      padding: 18px 0 18px 57px;
      &:hover {
        color: $colorPelorous;
      }

      @include sm {
        border-top: 2px solid $colorDoveGray;
        padding: 24px 0 24px 76px;
      }
    }
    .code {
      background-image: url(../images/code-icon.png);

      @include sm {
        background-image: url(../images/code-icon-mobile.png);
      }
    }
    .abonnement {
      background-image: url(../images/icon-gerer-abonnement-sidebar.png);
    }
    .assistance {
      background-image: url(../images/assistance-icon.png);

      @include sm {
        background-image: url(../images/assistance-icon-mobile.png);
      }
    }
  }
}
