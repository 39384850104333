//
// #1 - Container
//
// #1 - Container
//
%container {
  width: 100%;
  max-width: $lg-width;
  margin: 0 auto;
  position: relative;
}

#page-wrapper {
  overflow-x: hidden;
}

#page-title {
  @include sectionTitle;
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
  section {
    background: transparent;
  }
}

#main-wrapper {
  z-index: 0;
}
#toolbar,
#main-wrapper {
  background-color: $colorWildSand;
}
.tabs {
  @extend %container;
  padding: 0;
  h2.element-invisible {
    display: none;
  }
  ul {
    @include clearfix;
    li {
      display: block;
      float: left;
      a {
        display: inline-block;
        background: $colorEbb;
        color: $colorShark;
        text-transform: uppercase;
        font-family: $fontMontserrat;
        padding: 10px;
        &.active, &:hover {
          background: $colorPelorous;
          color: $colorWhite
        }
      }
    }
  }
}
.not-front main .section,
.field-name-field-description-footer,
.field-name-field-description-custom {
  @extend %container;
  @include clearfix;
  h3 {
    margin-bottom: 18px;
    font-family: $fontMontserrat;
    font-size: 2.2rem;
    font-weight: 700;
    text-transform: uppercase;

    &:after {
      @include seperatorLine;
    }

    &.rtecenter:after {
      margin: 10px auto;
    }

    @include sm {
      font-family: $fontMontserrat;
      font-size: 3.2rem;

      &:after {
        width: 73px;
        height: 3px;
        margin: 0 0 20px;
      }
    }
  }

  h4 {
    margin-bottom: 18px;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @include sm {
      font-size: 3rem;
    }
  }

  h5 {
    margin-bottom: 18px;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;

    @include sm {
      font-size: 2.6rem;
    }
  }

  h6 {
    margin-bottom: 18px;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: none;

    @include sm {
      font-size: 2.6rem;
    }
  }

  p {
    margin-bottom: 18px;
    line-height: 1.4;

    @include sm {
      line-height: 1.2;
    }
  }

  p a, .field-type-text-long a {
    color: $colorPelorous2;
    text-decoration: underline;

    @include sm {
      text-decoration: none;
    }

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }

  .text-align-center {
    text-align: center;
  }
  .text-align-right {
    text-align: right;
  }
  .text-align-justify {
    text-align: justify;
  }

  ul,
  ol {
    margin-bottom: 18px;

    li {
      line-height: 1.4;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      list-style: none;
      padding: 0 0 0 10px;
      background-image: url('../images/arrow-ul.png');
      background-size: 4px 8px;
      background-repeat: no-repeat;
      background-position: left top 8px;

      @include sm {
        padding: 0;
        background-image: url('../images/arrow-ul-mobile.png');
        background-size: 8px 17px;
        line-height: 1.2;
      }
    }
  }

  ol {
    counter-reset: li;
    li {
      padding: 0 0 0 8px;

      &:before {
        counter-increment: li;
        content: counter(li)'.';
        min-width: 0;
        min-height: 0;
        margin-right: 5px;
        padding: 0;
        position: static;
        color: $colorPelorous2;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
        background-color: transparent;
        border-radius: 0;
      }

      @include sm {
        &:before {
          font-size: 2.6rem;
          margin-right: 12px;
        }
      }
    }
  }
  .type-video {
    @include fullWidthBackground($colorTuna);
    text-align: center;
    padding: 90px 0;
    margin:0 -20px 50px;
    font-size: 0;

    @include sm {
      padding: 67px 21px;
    }

    iframe, video {
      width: 50%;
      height: 328px;
      max-width: 100%;
      display: inline-block;
      @include sm {
        width: 100%;
        height: calc(((100vw - 42px) / 16 ) * 9);
      }
    }
    .dnd-caption-wrapper {
      width: 50%;
      display: inline-block;
      background: $colorShark2;
      padding: 16px;
      color: $colorWhite;
      font-size: 1.7rem;
      box-sizing: border-box;
      max-width: 100%;
      @include sm {
        width: 100%;
        font-size: 2.7rem;
        padding: 29px 18px;
        height: auto;
      }
    }
  }
  .type-image {
    margin-bottom: 50px;
  }
  .type-file {
    box-sizing: border-box;
    border: 1px solid $colorEbb;
    padding: 30px;
    width: 50%;
    margin-bottom: 50px;
    .image {
      position: relative;
      overflow: hidden;
      @include clearfix;
    }
    img {
      float: left;
      margin-right: 30px;
      padding: 28px 21px;
      height: 0;
      width: 0;
      background-image: url(../images/pdf-icon.png);
      background-position: center;
      background-repeat: no-repeat;
    }
    .data {
      float: left;
      font-size: 1.7rem;
    }
    a {
      font-weight: bold;
      display: block;

      &:after {
        @include seperatorLine($color: $colorEbb, $width: 100%);
        position: absolute;
      }
    }
    .info {
      margin-top: 20px;
    }

    @include sm {
      width: 100%;
      border-width: 2px;
      padding: 30px 25px;

      img {
        margin-right: 30px;
        padding: 44.5px 33.5px;
        background-image: url(../images/lg-pdf-icon-mobile.png);
      }

      .data {
        font-size: 2.8rem;
      }

      a {
        &:after {
          width: 100%;
          height: 2px;
        }
      }
    }
  }
}

.field-name-field-description-custom {
  margin-bottom: 30px;

  .field-item {
    > h2, h3, h4, h5, h6 {
      font: inherit;
      font-weight: bold;
      margin-bottom: 18px;
      text-transform: inherit;

      &:after {
        display: none;
      }
    }
  }
}

// JS for this table is in scripts.js - Drupal.behaviors.mobileTable
table {
  width: 100%;
  margin-bottom: 50px;
  border-collapse: separate;
  border-spacing: 2px;

  th {
    padding: 16px 20px 18px;
    color: $colorWhite;
    font-family: $fontMontserrat;
    font-size: 1.7rem;
    font-weight: 400;
    background-color: $colorPelorous2;
  }

  tbody {
    tr {
      background-color: $colorWildSand;
      &:nth-child(2n) {
        background-color: $colorAlto;
      }
    }
  }

  td {
    padding: 18px 20px 16px;
  }
}

.node-type-paragraphe .field-item table {
  @include sm {
    @include fullWidthElement;
    width: 100vw !important;
  }

  tr {
    @include sm {
      background-color: transparent;
      border-bottom: 0 !important;
      @include clearfix;

      &:nth-child(2n) {
        background-color: transparent;
      }

      &:first-child {
        td:first-child {
          border-top: 2px solid $colorWhite;
        }
      }
    }
  }

  td {
    @include sm {
      background-color: $colorWildSand;
      border-bottom: 2px solid $colorWhite;
      padding: 0;
      height: 70px;
      font-size: 2.7rem;
      vertical-align: middle;

      &:nth-child(2n) {
        background-color: $colorAlto;
      }

      b {
        background-color: $colorPelorous;
        color: $colorWhite;
        font-family: $fontMontserrat;
        display: inline-flex !important;
        align-items: center;
        height: 100%;
        font-weight: normal;
        padding-left: 21px;
        width: 35% !important;
        box-sizing: border-box;
      }

      span {
        display: inline-flex !important;
        align-items: center;
        height: 100%;
        box-sizing: border-box;
        padding: 0 19px;
      }
    }
  }
}

input, select {
  border: 2px solid $colorPelorous;
  box-sizing: border-box;
  height: 30px;
  max-width: 100%;
  padding: 0 12px;
  font-size: 1.4rem;
  color: $colorBlack;
  border-radius: 0;

  @include sm {
    font-size: 3rem;
    height: 70px;
  }

  @include ie {
    font-size: 1.4rem;
    height: 30px;
  }
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
input[type="submit"] {
  @include button;

  @include sm {
    height: 70px;
    min-width: 300px;
    background-image: url(../images/lg-blue-next-arrow.png);
    background-position: right 20px center;
  }

  @include ie {
    height: 30px;
    min-width: 180px;
    background-image: url(../images/sm-blue-next-arrow.png);;
    background-position: right 10px center;;
  }
}
.type-audio {
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid $colorEbb;
  padding: 20px;
  min-width: 50%;
  margin-bottom: 50px;
  .play, .pause {
    display: none;
  }
  .control-label {
    font-weight: bold;
    display: inline-block;
  }

  @include sm {
    width: 100%;
    border-width: 2px;
    padding: 30px 25px;

    .control-label {
      font-size: 2.8rem;
    }
  }
}
.close-button {
  background-image: url(../images/mobile-close.png);
  background-position: center center;
  background-size: 28px 27px;
  cursor: pointer;
  float: none;
  height: 27px;
  margin-right: 0;
  margin-top: 0;
  position: absolute;
  right: 16px;
  width: 28px;
  top: 22px;
  z-index: 1;
  &:hover {
    background: none;
    background-size: 28px 27px;
    background-image: url(../images/mobile-close-hover.png);
  }
}
input[type="radio"],
input[type="checkbox"] {
  display: none;
}
input[type="radio"] + label,
input[type="radio"] + span[class*="label"] {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 28px;
  line-height: 21px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 21px;
    height: 21px;
    background-image: url('../images/radio-sprite.png');


  }
  &:hover {
    cursor: pointer;
  }

  @include sm {
    padding-left: 79px;
    line-height: 50px;

    &:before {
      width: 50px;
      height: 50px;
      background-image: url('../images/radio-sprite-mobile.png');
    }
  }
}
input[type="radio"] + label,
input[type="radio"] + span[class*="label"] {
  &:before {
    background-position: 0px -3px;
  }
  &:hover {
    &:before {
      background-position: -53px -3px;
    }
  }

  @include sm {
    &:before {
      background-position: 0px 0px;
    }
    &:hover {
      &:before {
        background-position: -50px 0px;
      }
    }
  }
}
input[type="radio"]:checked + label,
input[type="radio"]:checked + span[class*="label"] {
  &:before {
    background-position: -110px -3px;
  }

  @include sm {
    &:before {
      background-position: -100px 0px;
    }
  }
}
input[type="checkbox"] + label {
  display: inline-block;
  margin-bottom: 0;
  padding-left: 24px;
  line-height: 16px;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    background-image: url('../images/checkbox-sprite.png');
  }
  &:hover {
    cursor: pointer;
  }

  @include sm {
    padding-left: 86px;
    line-height: 70px;

    &:before {
      width: 70px;
      height: 70px;
      background-image: url('../images/checkbox-sprite-mobile.png');
    }
  }
}
input[type="checkbox"] + label {
  &:before {
    background-position: 0px 0px;
  }
}
input[type="checkbox"]:checked + label {
  &:before {
    background-position: -16px 0px;
  }
  @include sm {
    &:before {
      background-position: -70px 0px;
    }
  }
}

.optanon-status-checkbox + label::before {
  display: none !important;
}

div.messages {
  box-sizing: border-box;
  padding: 10px 10px 10px 81px;
  margin: 0;
  border: 0;
  color: $colorWhite;
  font-size: 1.7rem;
  font-family: $fontMontserrat;
  font-weight: normal;
  background-repeat: no-repeat;
  background-position: left 10px center;
  position: relative;

  margin-bottom: 20px;
  ul {
    margin-bottom: 0 !important;
  }

  &.warning,
  &.error {
    background-color: $colorSunsetOrange;
    background-image: none;

    @include sm {
      background-image: url(../images/icon-error-mobile.png);
    }
  }

  &.status {
    background-color: $colorFountainBlue;
    background-image: none;

    @include sm {
      background-image: url(../images/icon-ok-mobile.png);
    }
  }

  em,
  a {
    margin-left: 6px;
  }

  @include sm {
    font-size: 2.7rem;

    ul {
      margin: 0;
    }
  }
}
#messages {
  border-bottom: 5px solid rgba($colorBlack, 0.1);

  @include sm {
    border-bottom: 8px solid rgba($colorBlack, 0.1);
  }

  .messages {
    display: flex;
    align-items: center;
    overflow: visible;
    padding: 5px 0 5px 71px;
    background-position: left 0 center;
    box-sizing: border-box;
    //padding: 22px 10px 10px 81px;
    margin: 0;
    min-height: 56px;
    border: 0;
    color: $colorWhite;
    font-size: 1.7rem;
    font-family: $fontMontserrat;
    font-weight: normal;
    background-repeat: no-repeat;
    //background-position: left 10px center;
    position: relative;
    flex-wrap: wrap;

    em {
      margin-right: 6px;
    }

    &.warning,
    &.error {
      @include fullWidthBackground($colorSunsetOrange);
      background-color: transparent;
      background-image: url(../images/icon-error.png);
      background-repeat: no-repeat;

      @include sm {
        background-image: url(../images/icon-error-mobile.png);
      }

      &:before {
        z-index: -1;
      }
    }

    &.status {
      @include fullWidthBackground($colorFountainBlue);
      background-color: transparent;
      background-image: url(../images/icon-ok.png);
      background-repeat: no-repeat;

      @include sm {
        background-image: url(../images/icon-ok-mobile.png);
      }

      &:before {
        z-index: -1;
      }
    }

    @include sm {
      background-position: left 21px center;
      font-size: 2.7rem;
      font-weight: bold;
      padding: 0 0 0 101px;
      min-height: 90px;
    }
  }
}

div.error,
table tr.error {
  clear: left;
}

input.error {
  background-image: none;
  color: $colorBlack;
  border: 2px solid $colorSunsetOrange;
}

.ajax-progress .throbber {
  background: transparent url(../images/throbber-active.gif) no-repeat 0 center;
  background-size: 15px;
}

@include md {
  .section {
    padding: 0 15px;
  }

  #block-menu-menu-univers .content > .menu > li:nth-child(1) {
    padding-left: 15px !important;
  }

  #block-menu-menu-univers .content > .menu > li.last {
    padding-right: 15px;
  }

  #block-menu-menu-univers .content > .menu > li:nth-child(1) > ul, #block-menu-menu-univers .content > .menu > li:nth-child(2) > ul {
    padding: 0 15px;
    box-sizing: border-box;
  }

  #data-home-slider {
    margin: 0 -15px;
  }

  #breadcrumb {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@include sm {
  .section {
    padding: 0 21px;
  }

  header .section,
  footer .section,
  #postface .section,
  body.front .section,
  body.page-recherche .section,
  body.node-type-ouvrage .section,
  body.page-cart .section {
    padding: 0;
  }

  #block-menu-menu-univers .content > .menu > li:nth-child(1) {
    padding-left: 0 !important;
  }

  #block-menu-menu-univers .content > .menu > li.last {
    padding-right: 0;
  }

  #block-menu-menu-univers .content > .menu > li:nth-child(1) > ul, #block-menu-menu-univers .content > .menu > li:nth-child(2) > ul {
    padding: 0;
    box-sizing: border-box;
  }

  #data-home-slider {
    margin: 0;
  }
}
