.node-article {
  &.node-article-full,
  &.node-article-article-podcast,
  &.node-article-article-video {
    background: none !important;

    .wrapper-background-white {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 1;

      > .article-title,
      > .field-name-field-article-sous-titre,
      > .wrapper-author-date {
        @include fullWidthBackground($colorWhite);
        z-index: 0;

        &::before {
          z-index: -1;
        }
      }

      > .article-title,
      > .field-name-field-article-sous-titre {
        text-align: center;
        font-family: $fontMontserrat
      }

      > .article-title {
        position: relative;
        padding-top: 3rem;
        padding-bottom: 3rem;
        font-size: 4rem;
        font-weight: bold;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: calc(-50vw + 50%);
          width: 100vw;
          height: 0;
          border-top: 0.5rem solid;
        }
      }

      > .field-name-field-article-sous-titre {
        margin-top: -2rem;
        padding-bottom: 3rem;
        font-weight: 300;
        font-size: 2.4rem;
      }

      > .wrapper-author-date {
        display: flex;
        width: 72rem;
        margin: 0 auto -6rem;
        padding-bottom: 9rem;

        .field-name-field-article-auteur {
          flex: 1 1 auto;
          margin-right: 3rem;
        }

        .node-auteur-auteur-article,
        .node-auteur-blog-auteur-article {
          .content {
            position: relative;

            .field-name-field-auteur-photo {
              position: absolute;
              top: 50%;
              left: 0;
              width: 4.8rem;
              height: 4.8rem;
              margin-top: -2.4rem;
              padding: 0;

              img,
              picture {
                display: block;
                width: 4.8rem;
                height: 4.8rem;
                object-fit: cover;
                border-radius: 50%;
              }

              ~ .field-name-title-field,
              ~ .field-name-field-auteur-profession {
                padding-left: 6.5rem;
              }
            }

            .field-name-title-field,
            .field-name-field-auteur-profession {
              font-family: $fontMontserrat;
              line-height: 1;
            }

            .field-name-title-field {
              margin-top: 0.4rem;
              font-size: 1.4rem;
              font-weight: normal;
              text-transform: none;

              &::after {
                display: none;
              }
            }

            .field-name-field-auteur-profession {
              margin-top: 0.7rem;
              margin-bottom: 0;
              font-size: 1.2rem;
              font-weight: 300;
            }
          }
        }

        .date-creation {
          margin-left: auto;
          flex: 0 0 auto;
          font-family: $fontMontserrat;
          font-size: 1.4rem;
        }
      }

      .wrapper-infos {
        position: relative;
        width: 72rem;
        min-height: 50rem;
        margin: 0 auto;
        padding: 5rem 2rem 4.2rem;
        background-color: $colorWhite;
        font-family: $fontLora;
        font-size: 2rem;
        box-sizing: border-box;
        z-index: 1;

        @include sm {
          padding-bottom: 2rem;
        }

        .field-name-field-para-titre {
          left: auto;
          width: 100%;
          margin: 1em 0;
          border: 0;
          font-size: 2.8rem;
          font-weight: normal;
          text-align: left;
          color: $colorTuna;
          text-transform: none;

          &::before {
            display: none;
          }
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-bottom: 2rem;
          font-family: $fontMontserrat;
          font-weight: normal;
          text-transform: none;
        }

        h2 {
          font-size: 2.8rem;
          font-weight: bold;
        }

        h3 {
          font-size: 2.4rem;

          &::after {
            display: none;
          }
        }

        h4 {
          font-size: 2rem;
        }

        h5 {
          font-size: 1.8rem;
        }

        h6 {
          font-size: 1.6rem;
        }

        ol,
        ul {
          margin-bottom: 2rem;
          list-style: initial;
          list-style-position: inside;

          li {
            padding: 0;
            list-style: inherit;
            background: none;

            &::before {
              display: none;
            }
          }
        }

        ul ul {
          margin-bottom: 0;
          margin-left: 1em;
        }

        ol {
          list-style: decimal;
          list-style-position: inside;
          counter-reset: revert;

          ol {
            margin-bottom: 0;
            margin-left: 1em;
          }
        }

        p {
          margin-bottom: 2rem;
          line-height: 1.5;
        }

        table {
          table-layout: fixed;
          margin-bottom: 2rem;
          border-collapse: collapse;
          border-spacing: 0;

          thead th {
            font-size: 2rem;
          }

          th,
          td {
            font-size: 1.6rem;
            padding: 0.8rem;
            border: 0.1rem solid $colorAlto;
          }

          th {
            background-color: $colorWildSand;
            color: $colorTuna;
            font-family: $fontMontserrat;
          }

          td {
            background-color: $colorWhite;
            font-family: $fontLora;
          }
        }
      }

      .paragraphs-items .field-items > .field-item > .entity.paragraphs-item-rte {
        margin-bottom: 0;
      }
    }

    .wrapper-header-image,
    .wrapper-header-podcast,
    .wrapper-header-video {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 99rem;
      margin: 0 auto -2rem;

      @include sm {
        width: calc(100% + 42px);
        margin-left: -21px;
        margin-right: -21px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($colorWhite, 0.7);
      }

      > * {
        z-index: 1;
      }

      .field-name-field-article-visuel-principal,
      img {
        width: 100%;
      }

      img {
        display: block;
      }
    }

    .wrapper-header-podcast,
    .wrapper-header-video {
      padding: 4rem 0 5.5rem;

      .article-podcast {
        width: 62rem;
      }

      .article-video {
        width: 72rem;

        iframe {
          max-width: 100%;
        }
      }
    }

    .field-name-field-article-categorie-blog {
      position: absolute;
      top: -1.6rem;
      left: 50%;
      padding: 0.4rem 1rem 0;
      font-family: $fontYanone;
      text-transform: uppercase;
      font-size: 2.4rem;
      color: $colorWhite;
      background-color: $colorBlogHeader;
      z-index: 2;
      text-align: center;
      transform: translateX(-50%);
    }

    .share {
      position: absolute;
      top: 5.5rem;
      left: -7.6rem;
      width: 5.6rem;

      @include sm {
        position: static;
        width: 100%;
        margin-left: auto;
        margin-top: 2rem;

        .addthis_toolbox {
          display: flex;
          justify-content: flex-end;
        }
      }

      .title {
        display: none;
      }

      .at300b {
        display: block;
        margin: 0;
        padding: 0;

        @include sm {
          margin-left: 1rem;
        }

        .at-icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 5.6rem !important;
          height: 5.6rem !important;
          margin-bottom: 1rem;
          border-radius: 50%;
          line-height: 5.6rem !important;
        }
      }

      .addthis_toolbox {
        .button_email {
          background-color: #656F75;
          background-repeat: no-repeat;
          height: 5.6rem !important;
          width: 5.6rem;
          margin: 0;
          background-image: url("/sites/all/themes/lerobert/assets/images/icon-button-mail.svg");
          background-size: 3rem 2rem;
          background-position: center center;
          border-radius: 50%;
          float: left;

          @include sm {
            margin-left: 1rem;
          }
        }

        .addthis_button_print {
          padding-top: 9px;

          span {
            background-color: $colorWhite !important;
            background-image: url("/sites/all/themes/lerobert/assets/images/icon-button-print.svg");
            background-size: 3.2rem 3rem;
            background-position: center center;
            background-repeat: no-repeat;

            svg {
              display: none;
            }
          }

          @include sm {
            padding-top: 0;
          }
        }
      }
    }

    .abonnement {
      display: block;
      text-align: center;

      @include sm {
        margin-top: 2rem;
      }

      .link-youtube {
        display: inline-block;
        width: 27.2rem;
        height: 7.8rem;
        background-image: url("/sites/all/themes/lerobert/assets/images/link-youtube.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .wrapper-bloc-articles {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: -6rem;
      padding: 12rem 0 10rem;
      z-index: 0;

      @include sm {
        padding-bottom: 6rem;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-50vw + 50%);
        width: 100vw;
        height: 100%;
        background-color: inherit;
      }

      &::after {
        background-color: $colorWhite;
        opacity: 0.7;
      }

      .before-element {
        position: absolute;
        top: 0;
        left: calc(-50vw + 50%);
        width: 100vw;
        height: 100%;
        z-index: -1;
      }

      > * {
        position: relative;
        z-index: 1;
      }

      h3 {
        margin-bottom: 5rem;
        text-transform: uppercase;
        font-family: $fontYanone;
        font-size: 5rem;
        font-weight: normal;
        line-height: 1;

        &::after {
          display: none;
        }
      }

      .link-all-articles {
        margin-bottom: 5rem;
        margin-left: auto;
        font-family: $fontMontserrat;
        font-size: 1.6rem;
        text-decoration: underline;

        &::after {
          content: " >>";
          text-decoration: none;
        }

        &:hover,
        &:focus {
          color: $colorTuna;
        }
      }

      > .item-list {
        width: 100%;
      }
    }
  }
  .element-invisible {
    display: none;
  }
}

.blog-lignes,
.block-articles-list,
.blog-article-list {
  .node-article {
    height: 100%;
    border-radius: 1rem 1rem 0 0;
  }
}

.blog-lignes,
.block-articles-list,
.blog-article-list {
  .node-article > .content,
  .entity-article-externe {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 1rem 1rem 0 0;
    background-color: inherit;
    color: $colorTuna;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $colorWhite;
      border-radius: 1rem 1rem 0 0;
    }

    > * {
      z-index: 1;
    }

    .field-name-field-article-visuel-principal {
      position: relative;
      border-radius: 1rem 1rem 0 0;
      z-index: 2;

      img,
      picture {
        display: block;
        width: 100%;
      }

      img {
        border-radius: 1rem 1rem 0 0;
        height: 20rem;
        object-fit: cover;
      }

      .field-items {
        position: relative;
        border-radius: 1rem 1rem 0 0;
        overflow: hidden;

        &::before {
          content: "";
          position: absolute;
          left: 110%;
          top: 0;
          height: 100%;
          width: 60%;
          background-color: rgba($colorBlack, 0.15);
          transform: skewX(20deg);
          @include transition;
          pointer-events: none;
        }
      }
    }

    .wrapper-group-infos {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      padding-bottom: 2rem;
      background-color: inherit;
      z-index: auto;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorWhite;
        border-radius: 1rem 1rem 0 0;
      }

      > * {
        position: relative;
        z-index: 2;
      }

      a {
        color: inherit;
      }
    }

    .sous-categorie-article,
    .categorie-article {
      position: absolute;
      top: -1rem;
      left: 50%;
      padding: 0.6rem 1rem 0.2rem;
      background-color: $colorBlogHeader;
      font-family: $fontYanone;
      font-size: 2.2rem;
      text-transform: uppercase;
      color: $colorWhite;
      text-align: center;
      transform: translateX(-50%);
      line-height: 1;
      z-index: 3;
      overflow: hidden;
      max-width: calc(100% - 4rem);
      white-space: nowrap;
      text-overflow: ellipsis;

      &::before {
        content: "";
        position: absolute;
        right: 110%;
        top: 0;
        height: 100%;
        width: 60%;
        background-color: rgba($colorBlack, 0.08);
        transform: skewX(20deg);
        @include transition;
        z-index: -1;
      }
    }

    .article-title {
      margin-bottom: 1rem;
      padding: 3rem 1rem 0;
      font-family: $fontMontserrat;
      font-size: 2.6rem;
      font-weight: 600;
      line-height: 1.1;
      @include transition;

      &:focus,
      &:hover {
        color: $colorPelorous;
      }

      > a {
        padding: 0;
        color: inherit;
      }
    }

    .field-name-field-article-accroche {
      margin-bottom: 2rem;
      padding: 0 1rem;
      font-family: $fontLora;
      font-size: 1.8rem;

      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        strong {
          font-weight: normal;
        }
      }
    }

    .node-auteur-auteur-article,
    .node-auteur-blog-auteur-article {
      margin-bottom: 1.5rem;
      padding: 0 1rem 0 1.6rem;

      .content {
        position: relative;

        .field-name-field-auteur-photo {
          position: absolute;
          top: 50%;
          left: 0;
          width: 3.6rem;
          height: 3.6rem;
          margin-top: -1.8rem;
          padding: 0;

          img,
          picture {
            display: block;
            width: 3.6rem;
            height: 3.6rem;
            object-fit: cover;
            border-radius: 50%;
          }

          ~ .field-name-title-field,
          ~ .field-name-field-auteur-profession {
            padding-left: 4.5rem;
          }
        }

        .field-name-title-field,
        .field-name-field-auteur-profession {
          font-family: $fontMontserrat;
          font-weight: 300;
          line-height: 1;
        }

        .field-name-title-field {
          margin-top: 0.4rem;
          font-size: 1.4rem;
          text-transform: none;

          &::after {
            display: none;
          }
        }

        .field-name-field-auteur-profession {
          margin-top: 0.3rem;
          margin-bottom: 0;
          font-size: 1.2rem;
          line-height: 1.4;

          .field-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    a.lien-sous-categorie,
    .field-name-field-article-externe-url,
    .field-name-field-blog-autopromo-lien {
      order: 5;
      margin-top: auto;
      margin-right: 0.5rem;
      padding: 0 1rem;
      align-self: flex-end;
      font-family: $fontMontserrat;
      font-weight: 300;
      line-height: 1;
      font-size: 1.4rem;
      color: $colorPelorous;
      text-decoration: underline;
      text-align: right;
      @include transition;

      &::after {
        content: " >>";
        text-decoration: none;
      }

      &:hover,
      &:focus {
        color: $colorTuna;
      }
    }

    .field-name-field-article-externe-url,
    .field-name-field-blog-autopromo-lien {
      &::after {
        display: none;
      }

      a {
        &::after {
          content: " >>";
          text-decoration: none;
        }
      }
    }

    &:hover,
    &:focus {
      .field-name-field-article-visuel-principal .field-items::before {
        left: 50%;
      }

      .sous-categorie-article,
      .categorie-article {
        &::before {
          right: 50%;
        }
      }
    }

    .element-invisible {
      display: none;
    }
  }

  .entity-blog-autopromo {
    height: 100%;
    padding: 9rem 2rem 7rem;
    border-radius: 1rem;
    background-color: $colorPelorous;
    color: $colorWhite;
    font-family: $fontMontserrat;
    font-size: 2.6rem;
    box-sizing: border-box;
    text-align: center;
    font-weight: 300;

    .field-name-field-blog-autopromo-visuel {
      margin-bottom: 2rem;

      img {
        display: block;
        width: 15rem;
        height: 15rem;
        object-fit: cover;
        border-radius: 50%;
        border: 0.3rem solid $colorWhite;
        box-shadow: 0 0.5rem 1rem rgba($colorBlack, 0.15);
      }
    }

    .article-title {
      margin-bottom: 3rem;
      font-weight: 600;
    }

    .field-type-link-field a {
      display: inline-block;
      padding: 0.8rem 3.5rem;
      border: 0.2rem solid $colorWhite;
      @include transition;

      &:hover,
      &:focus {
        background-color: $colorWhite;
        color: $colorPelorous;
      }
    }

    &.blog-autopromo-no-title {
      position: relative;
      min-height: 39rem;

      .field-name-field-blog-autopromo-visuel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0;
        border-radius: 1rem;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          border: 0;
          z-index: 0;
        }
      }

      .wrapper-group-infos {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        z-index: 1;

        .field-type-link-field a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 0;
          border: 0;
          font-size: 0;
          color: transparent;

          &:focus,
          &:hover {
            background: none;
            color: transparent;
          }
        }
      }
    }
  }
}

.block-articles-list .node-article .content > *:last-child {
  margin-bottom: 2rem;
}

.node-type-article {
  #postface {
    margin-top: 0;
  }
}

.wrapper-duree-icon {
  position: relative;
  margin-left: auto;
  margin-right: 2rem;
  margin-top: -1.2rem;
  margin-bottom: -1.2rem;
  padding-right: 4.6rem;
  text-align: center;
  font-weight: 300;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  background-color: #ffffff;
  width: 9.8rem;
  line-height: 2.4rem;
  height: 2.4rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0,0,0,0.14);
  box-sizing: border-box;
}

.wrapper-duree-icon .icon-type {
  position: absolute;
  right: -1rem;
  top: -1.6rem;
  width: 5.6rem;
  height: 5.6rem;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 0.4rem rgba(0,0,0,0.14);
  background-size: 3.8rem auto;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper-duree-icon .icon-type.icon-Podcast {
  background-image: url('/sites/all/themes/lerobert/assets/images/icon-podcast.png');
}

.wrapper-duree-icon .icon-type.icon-Video {
  background-image: url('/sites/all/themes/lerobert/assets/images/icon-video.png');
}

.not-front main .section ul.blog-article-list li:first-child .wrapper-duree-icon,
.not-front main .section .blog-lignes ul li.mea-3-tiers .wrapper-duree-icon,
.not-front main .section .blog-lignes ul li.mea-2-tiers .wrapper-duree-icon {
  @include min(770px) {
    position: absolute;
    bottom: 4rem;
    margin-left: -14.8rem;
    z-index: 2;
  }
}
