h1.title {
  @extend %container;
  background-color: $colorWhite;
  color: $colorBlack;
  font-family: $fontMontserrat;
  font-size: 3.2rem;
  font-weight: bold;
  margin-bottom: 40px;
  padding: 28px 0;
  text-transform: uppercase;
  @include fullWidthBackground($colorWhite);
  &:after {
    @include seperatorLine($width: 57px, $margin: 4px 0);
  }
  &:before {
    z-index: -1;
  }

  @include md {
    padding: 0 15px;
    box-sizing: border-box;
  }

  @include sm {
    font-size: 4.2rem;
    padding: 49px 21px;

    &:after {
      width: 104px;
      height: 2px;
      margin: 5px 0 0;
    }
  }
}

.view-commerce-cart-form-custom {
  table {
    border-collapse: collapse;
    margin-bottom: 0;
  }
  thead {
    th {
      background: transparent;
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 1.4rem;
      text-align: left;
      text-transform: uppercase;
      padding: 25px 0;
      &.views-field-line-item-title {
        text-align: center;
      }
    }

    @include sm {
      display: none;
    }
  }
  tbody {
    tr {
      background-color: $colorWhite;
      border-bottom: 10px solid $colorWildSand;
      &:nth-child(2n) {
        background-color: $colorWhite;
      }

      @include sm {
        border: 0;
        padding: 36px 21px 140px;
        box-sizing: border-box;
        margin-bottom: 5px;
        display: block;
        position: relative;
        @include clearfix;
      }
    }
    td {
      vertical-align: middle;
      padding: 25px 0;

      @include  sm {
        padding: 0;
        display: block;
        float: left;

        &.views-field-commerce-unit-price {
          display: none;
        }
      }
    }
    .views-field-field-ouvrage-image {
      text-align: center;
      padding: 25px 15px;
      width: 8.33%;
      img {
        display: inline-block;
      }

      @include sm {
        padding: 0 20px 0 0;
        width: 228px;
        height: 228px;
        background-image: url(../images/image_default_small.jpg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 228px 228px;

        img {
          background-color: $colorWhite;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .views-field-line-item-title {
      width: 40%;
      @include sm {
        width: calc(100% - 248px);
      }
    }
    .line-item-title {
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 1.9rem;
      font-weight: bold;
      padding-right: 15px;

      @include sm {
        font-size: 3rem;
      }
    }
    .views-field-edit-price-type {
      width: 33%;
      .form-type-radio {
        label {
          margin-bottom: 1rem;
        }
      }
    }
    .line-item-format {
      color: $colorBlack;
      font-size: 1.3rem;
      font-weight: bold;
      text-transform: uppercase;
      .format, img {
        display: inline-block;
        vertical-align: middle;
      }
      .format {
        margin-left: 10px;
      }

      @include sm {
        font-size: 2.4rem;
        color: $colorTuna;
      }
    }
    .line-item-ean13 {
      color: $colorGray;
      font-size: 1.2rem;
      margin-top: 5px;

      @include sm {
        position: absolute;
        bottom: 60px;
        left: 40px;
        font-size: 2.2rem;
        margin-top: 0;
      }
    }
    .line-item-delete {
      margin-top: 25px;
      input[type="submit"] {
        background-image: url(../images/cart-delete.png);
        background-position: left center;
        border: none;
        color: $colorBoulder;
        font-size: 1.5rem;
        height: 20px;
        line-height: 1;
        margin:0;
        padding: 0 0 0 30px;
        text-align: left;
        text-transform: lowercase;
        &:hover {
          background-color: transparent;
          text-decoration: underline;
        }
      }

      @include sm {
        position: absolute;
        bottom: 60px;
        right: 21px;
        margin-top: 0;

        input[type="submit"] {
          background-image: none;
          font-size: 2.6rem;
          font-family: $fontMontserrat;
          height: auto;
          padding: 0;
          text-align: right;
          text-transform: capitalize;
          min-width: 0;
          text-decoration: underline;
        }
      }
    }
    .views-field-edit-quantity {
      font-size: 1.8rem;
      height: 36px;
      color: $colorBoulder;
      font-family: $fontMontserrat;
      text-align: center;

      input {
        box-sizing: border-box;
        background-color: $colorWhite;
        border: 2px solid $colorBoulder;
        color: $colorBoulder;
        font-family: $fontMontserrat;
        font-size: 1.8rem;
        height: 36px;
        padding: 0;
        width: 55px;
        text-align: center;
      }

      @include sm {
        margin-top: 30px;
        line-height: 70px;
        font-size: 2.8rem;

        input {
          border: 0;
          height: 70px;
          font-size: 2.8rem;
          width: 88px;
        }

        .qty-button {
          border: 2px solid $colorBoulder;
          height: 66px;
          width: 66px;
          text-align: center;
          font-size: 3.2rem;
          color: $colorBoulder;
          font-family: $fontMontserrat;
          display: inline-block;
          vertical-align: middle;
          line-height: 66px;
        }
      }
    }
    .views-field-commerce-total {
      color: $colorPelorous;
      font-size: 2rem;
      font-weight: bold;
      font-family: $fontMontserrat;

      @include sm {
        float: right;
        font-size: 4rem;
        margin-top: 30px;
        line-height: 70px;
      }
    }
    .form-radios {
      label {
        font-size: 1.5rem;
        margin-bottom: 12px;
      }

      input {
        display: inline-block;
        appearance: auto;
      }

      .description {
        font-size: 1.1rem;
        margin-bottom: 12px;
      }
    }

    input[type="radio"] {
      &+label {
        &:before {
          content: none;
        }

        display: inline-block;
        line-height: 21px;
        position: relative;
        font-size: 1.3rem;
        margin-bottom: 5px !important;
        padding-left: 0;
      }
      &+span[class*="label"] {
        &:before {
          content: none;
        }
      }
    }
  }

  input[type=number] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
    }
  }
}
.line-item-summary {
  @include clearfix;
  margin-bottom: 10px;
  .line-item-total-label-wrapper {
    align-items: center;
    background-color: $colorWhite;
    box-sizing: border-box;
    display: flex;
    float: right;
    justify-content: space-between;
    padding: 25px 8.33%;
    width: 50%;
  }
  .line-item-total-label {
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 1.9rem;
    font-weight: bold;
    text-align: left;
    .sub {
      font-size: 1.4rem;
      font-weight: normal;
    }
  }
  .line-item-total-raw {
    color: $colorPelorous;
    font-family: $fontMontserrat;
    font-size: 2.7rem;
    font-weight: bold;
    margin-left: auto;
  }

  @include sm {
    margin-bottom: 33px;
    margin-top: 22px;
    .line-item-total-label-wrapper {
      align-items: flex-start;
      float: none;
      padding: 33px 21px 33px 125px;
      width: 100%;
      line-height: 1;
    }

    .line-item-total-label {
      font-size: 2.9rem;
      .sub {
        font-size: 2.4rem;
      }
    }

    .line-item-total-raw {
      font-size: 4.5rem;
    }
  }
}
.line-item-summary-notice {
  width: 100%;
  color: $colorScorpion;
  font-size: 1.4rem;
  margin-bottom: 30px;
  text-align: right;

  @include sm {
    box-sizing: border-box;
    padding: 0 21px;
    font-size: 2.4rem;
    margin-bottom: 45px;
  }
}
.commerce-line-item-views-form .commerce-line-item-actions {
  input[type="submit"] {
    background-color: $colorPelorous;
    background-image: url(../images/lg-white-next-arrow.png);
    background-position: right 40px center;
    border: 0;
    color: $colorWhite;
    font-size: 1.8rem;
    font-family: $defaultFont;
    height: auto;
    line-height: 59px;
    min-width: 247px;
    padding-right: 40px;
    &:hover {
      background-color: $colorBostonBlue;
      background-image: url(../images/lg-white-next-arrow.png);
      box-shadow: 0 0 10px rgba($colorBlack, 0.2);
    }
  }

  @include sm {
    text-align: center;
    box-sizing: border-box;
    padding: 0 21px;

    input[type="submit"] {
      background-image: url(../images/lg-white-next-arrow.png);
      background-position: right 40px center;
      font-size: 2.8rem;
      font-family: $fontMontserrat;
      line-height: 70px;
      min-width: 373px;
    }
  }
}

@include sm {
  .upper-checkout-button {
    text-align: right;
    box-sizing: border-box;
    padding: 0 21px;
    margin: 7px 0 44px;

    input[type="submit"] {
      background-color: $colorPelorous;
      background-image: url(../images/lg-white-next-arrow.png);
      background-position: right 24px center;
      border: 0;
      color: $colorWhite;
      font-size: 2.8rem;
      font-family: $fontMontserrat;
      height: 70px;
      min-width: 248px;
      padding: 0 58px 0 15px;
    }
  }
}

.additional {
  margin-top: 20px;
  text-align: right;
  .first {
    color: $colorPelorous;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
  .second {
    color: $colorScorpion;
    font-size: 1.4rem;
  }

  @include sm {
    margin-top: 40px;

    .first {
      font-size: 2.8rem;
    }
    .second {
      font-size: 2.4rem;
    }
  }
}
.field-name-field-para-titre {
  @include sectionTitle;
  @include fullWidthElement;
  border-top: 2px solid $colorWhite;
  text-align: center;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 48px;
}

#block-lerobert-block-lerobert-recemment-consultes {
  h2 {
    @include sectionTitle;
    @include fullWidthElement;
    border-top: 1px solid $colorSwissCoffee;
    text-align: center;
    padding: 0;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
.page-cart {
  @include sm {
    #block-lerobert-block-lerobert-recemment-consultes {
      overflow: hidden;
      padding: 0 21px;

      > .content {
        display: block;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        margin-bottom: 78px;
        font-size: 0;

        &::-webkit-scrollbar {
          height: 14px;
          background-color: $colorMercury;
          border-radius: 7px;
          -webkit-appearance: none;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 7px;
            background-color: $colorPelorous;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0;
        }

        > .node-ouvrage-search-result {
          display: inline-block;
          float: none;
          padding: 0;
          margin-bottom: 87px;
          margin-right: 10px;

          > .content {
            margin: 0;
          }

          .product-title {
            white-space: normal;
          }
        }
      }
    }
  }
}

.cart-empty-page {
  @include sm {
    padding: 0 21px;
    margin-bottom: 40px;
  }
}

.view-commerce-cart-summary-custom {
  .product-type-Cadeau {
    .item-ouvrage {
      &::before {
        content: 'Cadeau :';
        font-weight: bold;
        padding-right: .5em;
      }
    }
    .item-format {
      display: none;
    }
    input[type="radio"] + label:before {
      background-position: -53px -3px;
    }
    .form-type-radios {
      margin-top: 15px;
      > label {
        font-weight: 600;
      }
    }
  }
}

.form-item-cgv-remember-cb {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .description {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    // iPhone 5 (SE)
    label {
      font-size: 31px;
    }
    .description {
      font-size: 2.3rem;
    }
  }
  @media only screen and (min-device-width: 360px) and (max-device-width: 480px) {
    // a little bigger phones
    label {
      font-size: 24px;
    }
    .description {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 788px) {
  .view-commerce-cart-form-custom {
    tbody {
      .views-field-field-ouvrage-image {
        padding: 0 20px 0 0;
        width: 89px;
        height: 89px;
        background-image: url(../images/image_default_small.jpg);
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 89px 89px;
      }
      tr {
        margin-bottom: 15px;
      }
      .views-field-line-item-title {
        width: calc(100% - 109px);
      }
      .views-field-edit-price-type {
        width: calc(100% - 109px);
        margin-top: 1.5rem;
        margin-left: 109px;
      }
      .line-item-ean13 {
        position: relative;
        bottom: 0;
        left: 0;
        font-size: 2.2rem;
        margin-top: 15px;
      }
      .views-field-edit-price-type {
        padding-top: 5px;
        label {
          font-size: 2rem;
        }
        .form-type-radios {
          .selecteur-prix input {
            -ms-transform: scale(1.5); /* IE 9 */
            -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
            transform: scale(1.5);
            height: auto;
          }
          .selecteur-prix label {
            font-size: 2rem !important;
          }
          .description {
            font-size: 1.7rem;
            margin-top: 5px;
          }
        }
      }

      .views-field-edit-quantity {
        margin-top: 10px;
        line-height: normal;
        font-size: 2.8rem;
        height: 70px;
        margin-left: 109px;
      }
      .views-field-commerce-total {
        float: right;
        font-size: 4rem;
        margin-top: 10px;
        line-height: 70px;
      }
    }
  }
}
