//
// JS for sidebar is in scripts.js - Drupal.behaviors.sidebar
//
#page-wrapper {
  width: 100%;
  position: absolute;
  transition: transform 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;
  min-height: 100vh;

  &.pushed {
    transition-duration: 0.5s;
    transform: translateX(-396px);
  }
}

#sidebar {
  width: 0;
  height: 0;
  background-color: $colorBlack;
  position: absolute;
  right: 0;
  z-index: 2;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0.1s;

  &.open {
    right: -376px;
    width: 396px;
    height: 200px;
  }
}
