////////////////////////
// Drupal elements
//
// Override here the prime rules.
//
////////////////////////

#toolbar .section,
#header .section,
#main .section,
#postface .section,
#footer .section {
  @extend %container;
  box-sizing: border-box;
}
