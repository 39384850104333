#sliding-popup {
  height: auto !important;

  .popup-content {
    max-width: 100%;
    position: relative;
    padding: 8px;

    #popup-text {
      max-width: 65%;
      float: none;
      margin: 0 auto;

      @include sm {
        max-width: 100%;
      }

      p {
        font-weight: 300;
        margin: 0 0 5px 0;
        display: inline-block;
        padding-left: 5px;

        @include sm {
          font-size: 1.6rem;
          line-height: 1.2;
          display: inline;
        }
      }
    }

    #popup-buttons {
      float: none;
      width: 80px;
      margin: 0;

      button {
        margin: 0;

        &.agree-button {
          position: absolute;
          top: 8px;
          right: 25px;
          background-color: transparent;
          background-image: url(../images/icon-white-close.png);
          background-position: right center;
          background-repeat: no-repeat;
          border: none;
          color: white;
          border-radius: 0;
          box-shadow: none;
          text-shadow: none;
          height: 19px;
          width: auto;
          padding: 0 27px 0 0;
          font-weight: 300;
        }

        &.find-more-button {
          display: none;
        }
      }

      @include sm {
        width: 100%;
        text-align: center;
        max-width: 100%;
        button {
          &.agree-button {
            position: static;
            font-size: 2.6rem;
            height: auto;
          }
        }
      }
    }
  }

  a {
    text-decoration: underline;
  }
}
