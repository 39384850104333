.vocabulary-blog {
  background-color: transparent !important;

  .group-sub-header .field-name-field-blog-accroche,
  .group-bottom {
    padding: 6rem 0;
    text-align: center;
    font-family: $fontLora;
    font-size: 2rem;
    background-color: $colorWildSand;

    @include sm {
      padding: 3rem 0;
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul:not(.contextual-links):not(.pager) {
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        background-position: left top 1.1rem;
      }
    }
  }

  .title,
  .sous-category-subtitle,
  .field-name-field-blog-sous-titre {
    + .field-name-field-blog-accroche {
      margin-top: 4rem;
    }
  }

  .group-sub-header .field-name-field-blog-accroche {
    @include fullWidthBackground($colorWildSand);
    padding-bottom: 0;

    &::before {
      z-index: -1;
    }
  }

  .group-sub-header {
    @include fullWidthBackground($colorWhite);

    &::before {
      z-index: -1;
    }

    .category-title,
    .title {
      position: relative;
      padding-top: 4rem;
      font-family: $fontYanone;
      font-size: 6rem;
      text-transform: uppercase;
      line-height: 1;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: calc(-50vw + 50%);
        width: 100vw;
        height: 0;
        border-top: 0.5rem solid;
      }

      &:last-child {
        padding-bottom: 4rem;
      }
    }

    .sous-category-subtitle,
    .field-name-field-blog-sous-titre {
      position: relative;
      padding-top: 1rem;
      font-family: $fontMontserrat;
      font-size: 1.6rem;
      font-weight: 300;
      color: $colorTuna;

      &:first-child {
        padding-top: 4rem;
      }
    }

    .sous-categories-blog {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      padding-top: 2rem;
      padding-bottom: 4rem;

      @include sm {
        flex-direction: column;
        margin: 0 -21px;
      }

      a {
        position: relative;
        margin-right: 1.6rem;
        padding: 1rem 1rem 0.6rem;
        border: 0.2rem solid;
        font-family: $fontYanone;
        font-size: 2.4rem;
        text-transform: uppercase;
        box-sizing: border-box;
        z-index: 0;

        @include sm {
          border-color: $colorAlto !important;
          border-width: 0.2rem 0;
          margin-top: -0.2rem;
          margin-right: 0;
          padding-left: 21px;
          padding-right: 21px;
          transition: none;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: $colorWhite;
          @include transition;
          z-index: -1;

          @include sm {
            transition: none;
          }
        }

        &.active {
          color: $colorWhite;

          &::before {
            display: none;
          }
        }

        &:hover,
        &:focus {
          color: $colorWhite !important;

          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  .blog-articles,
  .blog-lignes {
    margin-top: 6rem;
  }

  ul.pager {
    display: flex;
    margin-top: 0;
    margin-bottom: 6rem !important;
    padding-left: 0;
    align-items: center;
    justify-content: center;

    li {
      margin: 0 5px;

      &.pager-current {
        width: 4.8rem;
        height: 4.8rem;
        background-color: $colorPelorous;
      }

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0.2rem solid $colorPelorous;
        color: $colorPelorous;
        width: 4.8rem;
        height: 4.8rem;
        box-sizing: border-box;

        &:focus,
        &:hover {
          background-color: $colorPelorous;
          color: $colorWhite;
        }
      }

      &.pager-previous,
      &.pager-next {
        a {
          font-size: 0;
          color: transparent;

          &::before,
          &::after {
            content: "";
            background: none;
            width: auto;
            height: auto;
            margin: 0;
          }

          &::after {
            content: "<<";
            font-size: 1.4rem;
            color: $colorPelorous;
          }

          &:focus,
          &:hover {
            &::after {
              color: $colorWhite;
            }
          }
        }
      }

      &.pager-next a::after {
        content: ">>";
      }
    }
  }

  &.sous-categorie-page {
    .group-sub-header {
       .category-title,
       .title {
         text-align: center;
       }

       .sous-category-subtitle,
       .field-name-field-blog-sous-titre {
         font-size: 2.4rem;
       }
    }

    .field-name-field-blog-accroche {
      margin-top: 0;
      margin-bottom: 3rem;
      padding-top: 2rem;
      padding-bottom: 4rem;
      font-size: 1.8rem;
      text-align: left;
      @include fullWidthBackground($colorWhite);

      &::before {
        z-index: -1;
      }
    }
  }

  &.categorie-page,
  &.sous-categorie-page {
    ul.blog-article-list li:first-child,
    .blog-lignes ul li.mea-3-tiers,
    .blog-lignes ul li.mea-2-tiers {
      @include sm {
        width: 50% !important;

        .field-name-field-article-visuel-principal {
          margin: 0 !important;

          img,
          .field-items {
            border-radius: 1rem 1rem 0 0 !important;
          }

          img {
            height: 20rem !important;
          }
        }
      }
    }
  }
}

.group-header,
.wrapper-header {
  @include fullWidthBackground($colorBlogHeader);

  .blog-subtitle {
    display: none;
  }

  .blog-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 5rem 0 3rem;
    text-align: center;
    font-family: $fontYanone;
    font-size: 8rem;
    font-weight: normal;
    color: $colorWhite;
    text-transform: uppercase;
    z-index: 0;

    &::before {
      z-index: -1;
    }

    &::after {
      content: "";
      display: block;
      width: 16.7rem;
      height: 12.2rem;
      margin-left: 0.7rem;
      margin-top: -2rem;
      @include backgroundImage('bg-blog-header.svg', 100% 100%, center, no-repeat);
    }
  }

  .categories-blog {
    position: relative;
    display: flex;
    padding-top: 1rem;
    justify-content: center;
    overflow: hidden;
    z-index: 1;

    a {
      display: block;
      margin: 0 2rem;
      padding: 0 2.4rem;
      border-radius: 0.8rem 0.8rem 0 0;
      line-height: 4.8rem;
      color: $colorWhite;
      font-family: $fontYanone;
      font-size: 2.4rem;
      text-transform: uppercase;
      background-color: $colorBlogHeaderLink;
      @include transition;

      &:focus,
      &:hover {
        box-shadow: 0 0 1rem rgba($colorBlack, 0.3);
      }

      @include sm {
        margin: 0 0.6rem;
        padding: 0 1.8rem;
      }
    }
  }
}

.not-front main .section {
  ul.blog-article-list,
  ul.block-articles-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.2rem;

    @include sm {
      margin: 0 -0.5rem;
    }

    li {
      width: calc(100% / 3);
      margin-bottom: 6rem;
      padding: 0 1.2rem;
      background: none;
      box-sizing: border-box;

      @include sm {
        width: 50%;
        margin-bottom: 3rem;
        padding: 0 0.5rem;
      }
    }
  }

  ul.blog-article-list {
    margin-bottom: -6rem;

    @include sm {
      margin-bottom: -3rem;
    }
  }

  ul.block-articles-list {
    @include sm {
      display: block;
      min-width: 0;
      padding-top: 1rem;
      overflow: hidden;

      .slick-track {
        display: flex;
      }

      .slick-dots {
        display: flex;
        justify-content: center;
        margin: -1rem 0 0 !important;

        li {
          width: auto;
          margin: 0 !important;
          padding: 0 0.5rem !important;

          button {
            position: relative;
            display: block;
            font-size: 0;
            color: transparent;
            width: 2rem;
            height: 2rem;
            border: 0.3rem solid $colorWhite;
            border-radius: 50%;

            &::after {
              content: "";
              position: absolute;
              top: -0.3rem;
              left: -0.3rem;
              width: 100%;
              height: 100%;
              border: 0.3rem solid $colorWhite;
              background-color: $colorAlto;
              border-radius: 50%;
              pointer-events: none;
              @include transition;
            }
          }

          &.slick-active button::after {
            opacity: 0;
          }
        }
      }
    }
  }
}

.not-front main .section ul.blog-article-list li:first-child,
.not-front main .section .blog-lignes ul li.mea-3-tiers,
.not-front main .section .blog-lignes ul li.mea-2-tiers {
  width: 100%;

  @include min(#{$sm-width + 0.1}) {
    .node-article .content,
    .entity-article-externe {
      flex-direction: row;
      padding-bottom: 0;
      border-radius: 1rem;

      &::before {
        border-radius: 1rem;
      }

      a {
        width: 66.6666%;

        &.lien-sous-categorie {
          width: auto;
        }
      }

      .field-name-field-article-visuel-principal {
        flex: 1 0 auto;
        width: 100%;
        min-height: 39rem;
        height: 100%;
        padding-right: 0.7rem;
        box-sizing: border-box;

        * {
          height: 100%;
        }

        img {
          border-radius: 1rem 0 0 1rem;
          object-fit: cover;
        }

        .field-items {
          border-radius: 1rem 0 0 1rem;
        }
      }

      .wrapper-group-infos {
        flex: 1 1 auto;
        width: 33.3333%;
        padding-left: 1.5rem;
        box-sizing: border-box;
        border-radius: 1rem;

        &::before {
          border-radius: 1rem;
        }

        .lien-sous-categorie {
          margin-bottom: 0;
          order: 10;
          z-index: 2;
        }
      }

      .sous-categorie-article {
        left: 33.3333%;
      }

      h2.article-title,
      h1.article-title,
      .field-name-field-article-accroche,
      .node-auteur,
      .node-auteur-blog,
      .lien-sous-categorie {
        padding-left: 0;
      }

      .lien-sous-categorie {
        margin-bottom: 2rem;
      }
    }

    .entity-article-externe {
      a:empty {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        ~ .field-name-field-article-visuel-principal {
          width: 66.6666%;
        }
      }
    }
  }
}

@include sm {
  .blog-article-list li:first-child .node-article-article-mea-3-tier > .content .field-name-field-article-visuel-principal,
  .blog-article-list li:first-child .entity-article-externe-article-mea-3-tier > a .field-name-field-article-visuel-principal {
    .field-items {
      overflow: visible;
      border-radius: 0;

      &::before {
        left: 120%;
      }
    }

    img {
      max-width: calc(100% + 40px);
      width: calc(100% + 40px);
      height: 39rem;
      margin: 0 -20px;
      border-radius: 0;
    }
  }
}

@include min(#{$sm-width + 0.1}) {
  .not-front main .section ul.blog-article-list li:first-child,
  .not-front main .section .blog-lignes ul li.mea-3-tiers {
    .node-article .content,
    .entity-article-externe {
      .field-name-field-article-visuel-principal {
        height: 39rem;
      }
    }
  }
}

@include min(#{$sm-width + 0.1}) {
  .not-front main .section .blog-lignes ul li.mea-2-tiers {
    width: 66.6666%;

    .node-article .content,
    .entity-article-externe {
      .field-name-field-article-visuel-principal {
        width: 100% !important;
        padding-right: 1.2rem !important;

        .field-items,
        .field-item,
        picture,
        img {
          height: 100%;
        }

        .field-items::before {
          left: 120%;
          width: 65%;
        }
      }

      .node-article > .content:hover .field-name-field-article-visuel-principal .field-items::before {
        left: 60%;
      }

      .wrapper-group-infos {
        width: 50% !important;
        padding-left: 1.2rem !important;

        > *:not(.sous-categorie-article) {
          padding-left: 0;
        }
      }

      .sous-categorie-article {
        left: 25% !important;
        max-width: 24rem;
      }

      &:hover,
      &:focus {
        .field-name-field-article-visuel-principal .field-items::before {
          left: 60%;
        }
      }
    }
  }
}

.not-front main .section ul.blog-article-list li:first-child .node-article,
.not-front main .section .blog-lignes ul li.mea-3-tiers .node-article,
.not-front main .section .blog-lignes ul li.mea-2-tiers .node-article {
  border-radius: 1rem;
}

.not-front main .section .blog-lignes {
  margin-bottom: -6rem;

  @include sm {
    margin-bottom: -3rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin:  0 -1.2rem;

    li {
      margin: 0 0 6rem;
      padding: 0 1.2rem;
      background: none;
      box-sizing: border-box;

      @include sm {
        margin-bottom: 3rem;
      }

      &.mea-1-tier {
        width: 33.3333%;

        @include sm {
          width: 50%;
        }
      }

      &.mea-2-tiers {
        width: 66.6666%;

        @include sm {
          width: 50%;
        }
      }

      &.mea-3-tiers {
        width: 100%;

        @include sm {
          width: 50%;
        }
      }
    }
  }

  > .mobile-wrapper {
    display: none;

    @include sm {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -1.2rem;

      > li {
        width: 50%;
        padding: 0 1.2rem;

        &:first-child {
          width: 100% !important;

          .node-article,
          .entity-article-externe {
            .field-name-field-article-visuel-principal {
              margin: 0 -21px;

              img,
              .field-items {
                border-radius: 0;
              }

              img {
                height: 40rem;
              }
            }
          }
        }
      }
    }
  }

  > .item-list {
    @include sm {
      display: none;

       > ul {
         display: inline-flex;
       }
    }
  }
}
