.field-name-field-para-tc-visuel {
  picture {
    @include fullWidthElement;

    img {
      height:29.69vw;
      object-fit: cover;
      max-width: none;
      width: 100%;
    }

    &.object-fit {
      background-size: cover;
      background-position: center;

      img {
        opacity: 0;
      }
    }
  }

  @include sm {
    height: auto;
  }
}

.field-name-field-para-tc-visuel + .group-title + .paragraphs-items-field-para-tc-paras {
  margin-top: 40px;
}

.node-type-paragraphe main .section {
  background: transparent;

  h1 {
    text-transform: uppercase;
    font-family: $fontMontserrat;
    font-size: 2.6rem;
    font-weight: bold;
    &:after {
      @include seperatorLine($width: 60px);
    }
  }

  .field-group-html-element {
    position: absolute;
    left: 0;
    width: 66.66%;
    background: rgba($colorBlack,0.5);
    padding: 20px;
    color: $colorWhite;
    margin-top: -17%;
    h1 {
      text-transform: uppercase;
      font-family: $fontMontserrat;
      font-size: 2.6rem;
    }
    .field-name-field-para-tc-sous-titre {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .field-name-field-para-tc-accroche {
      font-style: italic;
    }

    @include md {
      margin-top: -25%;
    }

    @include sm {
      position: static;
      width: 100%;
      padding: 49px 21px 45px;
      margin-top: 0;
      box-sizing: border-box;
      background: $colorWhite;
      color: $colorBlack;
      @include fullWidthElement;

      h1 {
        font-size: 4.2rem;
        font-weight: bold;
        margin-bottom: 40px;
        &:after {
          @include seperatorLine;
        }
      }

      .field-name-field-para-tc-accroche {
        font-style: normal;
      }
    }
  }

  h3 {
    &:after {
      width: 60px !important;

      @include sm {
        width: 73px !important;
      }
    }
  }

  ol {
    li {
      padding-left: 0;
      &:before {
        color: inherit;
        font-weight: normal;
      }
      @include sm {
        padding-left: 12px;

        &:before {
          color: $colorPelorous2;
          font-weight: bold;
        }
      }
    }
  }

  .node.no-image {
    .field-group-html-element {
      position: static;
      background: transparent;
      width: auto;
      padding: 0;
      color: $colorTuna;
      margin-top: 20px;

      .field-name-field-para-tc-sous-titre {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .field-name-field-para-tc-accroche {
        font-style: normal;
      }

      @include sm {
        padding: 49px 21px 45px;
        margin-top: 0;
        box-sizing: border-box;
        background-color: $colorWhite;
        @include fullWidthElement;

        h1 {
          font-size: 4.2rem;
          color: $colorBlack;
        }
      }
    }
  }

  .node-ouvrage-search-result {
    width: auto;
    float: none;
    padding: 0;

    &.has-abonnement-mensuel {
      .form-submit {
        // Il faut aller sur la fiche produit pour choisir un abonnement.
        visibility: hidden;
      }
    }

    @include sm {
      > .content {
        margin: 0;

        &:before {
          width: 100%;
          height: 353px;
        }

        .field-name-field-ouvrage-image {
          width: 100%;
          float: none;
          height: auto;
          font-size: 0;
          display: block;
          margin-bottom: 35px;
          padding-right: 0;

          picture {
            display: block;
          }

          img {
            max-width: 100%;
            max-height: 100%;
            height: 316.5px;
            width: auto;
            margin: 0 auto;
          }

          &.no-visuel {
            max-width: 100%;
            min-height: 316.5px;
          }
        }

        .product-title,
        .ouvrage-format,
        .commerce-price {
          width: 100%;
          float: none;
          padding: 0;
        }

        .ouvrage-format {
          margin-top: 28px;
        }
      }
    }
  }

  .field-name-field-para-ouvrages > .field-items > .field-item > .node {
    padding: 0;
  }
}

.node-type-paragraphe {

  //OWS : Fix inline block vertical spacing.
  .field-name-field-para-tc-visuel {
    font-size: 0;
  }

  .block-lerobert-block h2,
  .field-name-field-para-titre {
    @include sectionTitle;
    @include fullWidthElement;
    border-top: 2px solid $colorWhite;
    text-align: center;
    padding: 0;
    margin-bottom: 20px;
  }
  .field-item:first-of-type {
    .field-name-field-para-titre {
      margin-top: 0;
      border-top: 0;
    }
  }
}
.paragraphs-items {
  .field-items {
    > .field-item {
      > .entity{
        &.paragraphs-item-produits,
        &.paragraphs-item-push-promo-3-colonnes,
        &.paragraphs-item-rubriques,
        &.paragraphs-item-push-promo-2-colonnes,
        &.paragraphs-item-rte,
        &.paragraphs-item-iframe {
          margin-bottom: 69px;
        }
      }
    }
  }
}
main .section {
  .field-name-field-para-accroche {
    p {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}
.field-name-field-para-ouvrages {
  @include clearfix;
  > .field-items {
    margin: 0 -5px;

    @include sm {
      margin: 0;
    }

    > .field-item {
      box-sizing: border-box;
      padding: 0 5px;
      width: 25%;
      float: left;
      > .node {
        background: $colorWhite;
        position: relative;
        overflow: hidden;
        z-index: 1;
        padding: 18px;
        margin-bottom: 10px;
        @include diagonalBg($direction: vertical);
        &:before {
          z-index: -1;
        }
        &:hover {
          .produit-hover {
            display: block;
            opacity: 1;
            z-index: 2;
          }
        }
      }
    }

    @include sm {
      margin: 0 -10px;

      > .field-item {
        padding: 0 10px;
        width: 50%;

        > .node {
          padding: 0px;
          margin-bottom: 21px;

          &:hover {
            .produit-hover {
              display: none;
            }
          }
        }
      }
    }
  }
}

.field-name-field-para-promos {
  > .field-items {
    margin: 0 -5px;

    @include sm {
      margin: 0;
    }

    > .field-item {
      box-sizing: border-box;
      padding: 0 5px;
      float: left;

      @include sm {
        padding: 0;
      }

      .field-collection-view {
        padding: 0 0 16px;
        margin: 0;
        border-bottom: 0;
      }
      .field-name-field-para-promo-titre,
      .field-name-field-para-promo-accroche {
        padding: 0 18px;
      }
    }
  }
}
.field-collection-container {
  border: none;
  margin: 0;
}
.field-name-field-para-promo-titre {
  font-family: $fontMontserrat;
  font-weight: bold;
}
.field-name-field-para-promo-bis {
  > .field-items {
    margin: 0 -5px;

    @include sm {
      margin: 0;
    }

    > .field-item {
      box-sizing: border-box;
      padding: 0 5px;
      float: left;

      @include sm {
        padding: 0;
      }

      .field-collection-view {
        padding: 0;
        margin: 0;
        border-bottom: 0;
        background: $colorWhite;
      }
      .field-name-field-promo-visuel {
        float: left;
        width: 188px;

        @include sm {
          width: 278px;

          img {
            width: 100%;
          }
        }
      }
      .field-name-field-para-promo-titre,
      .field-name-field-para-promo-accroche {
        box-sizing: border-box;
        padding: 0 18px;
        float: left;
        width: calc(100% - 188px);

        @include sm {
          padding: 0 20px;
          width: calc(100% - 278px);
        }
      }
      .field-name-field-para-promo-titre {
        margin-top: 24px;
        font-size: 2.2rem;
        text-transform: uppercase;
        &:after {
          @include seperatorLine($margin: 14px 0 16px);
        }

        @include sm {
          font-size: 3.2rem;
          &:after {
            width: 73px;
            height: 3px;
          }
        }
      }
      .field-name-field-para-promo-lien {
        float: right;
        margin-right: 11px;

        @include sm {
          margin-right: 20px;
        }

        > .field-items {
          @include cta-gradient;
          background: url(../images/sm-blue-next-arrow.png) right 13px center no-repeat;
          min-width: 150px;
          max-width: 260px;
          line-height: 30px;
          color: $colorPelorous;
          text-align: center;
          display: inline-block;
          &:hover {
            color: $colorWhite;
            background-image: url(../images/sm-white-next-arrow.png);
          }
          .field-item {
            padding-left: 5px;
            padding-right: 25px;
            padding-top: 5px;
            padding-bottom: 5px;
            position: relative;
          }

          @include sm {
            background: url(../images/lg-blue-next-arrow.png) right 18px center no-repeat;
            min-width: 230px;
            line-height: 60px;
            font-size: 2.6rem;
            padding: 0 47px 0 25px;
            text-align: left;
            box-sizing: border-box;
            margin-top: -20px;
            margin-bottom: 8px;

            &:hover {
              background-image: url(../images/lg-white-next-arrow.png);
            }
          }
        }
      }
    }
  }
}
.paragraphs-item-push-promo-3-colonnes {
  .field-name-field-para-promos {
    > .field-items {
      > .field-item {
        width: 33.33%;

        @include sm {
          width: 100%;
          margin-bottom: 21px;
        }

        .field-name-field-para-promo-visuel {
          @include sm {
            picture {
              display: block;
            }

            img {
              width: 100%;
            }
          }
        }

        .field-collection-view {
          background: $colorWhite;
        }

        .field-name-field-para-promo-titre,
        .field-name-field-para-promo-accroche {
          color: $colorBlack;
        }

        .field-name-field-para-promo-titre {
          margin-top: 16px;
          @include transition;
          &:after {
            @include seperatorLine;

            @include sm {
              width: 57px;
              height: 1px;
            }
          }
        }

        &:hover {
          .field-name-field-para-promo-titre {
            color: $colorPelorous;
          }
        }
      }
    }
  }
}
.paragraphs-item-rubriques {
  .field-name-field-para-promos {
    margin-top: 40px;
    @include clearfix;
    > .field-items {
      > .field-item {
        width: 50%;

        @include sm {
          width: 100%;

          picture {
            display: block;
          }

          img {
            width: 100%;
          }
        }

        .field-name-field-para-promo-titre,
        .field-name-field-para-promo-accroche {
          padding: 0;
          color: $colorTuna;
        }
        .field-name-field-para-promo-titre {
          font-size: 2.2rem;
          text-transform: uppercase;

          @include sm {
            font-size: 3.2rem;
          }

          &:before {
            @include seperatorLine($width: 73px, $height: 3px, $margin: 15px 0 5px);

            @include sm {
              width: 73px;
              margin: 24px 0 10px;
            }
          }
        }
      }
      > .field-item:nth-child(3n+0) {
        clear: left;
      }
    }
  }
}
.paragraphs-item-push-promo-2-colonnes {
  margin-bottom: 18px;
  .field-name-field-para-promo-bis {
    > .field-items {
      display: flex;
      flex-wrap: wrap;

      > .field-item {
        width: 50%;

        @include sm {
          width: 100%;
          padding: 0;
          margin-bottom: 21px;
        }

        .field-name-field-para-promo-titre,
        .field-name-field-para-promo-accroche {
          padding: 0 18px;
        }

        .field-collection-view {
          height: 100%;
        }
      }
    }
  }
}
.paragraphs-item-inscription {
  padding: 20px 0 50px;
  @include fullWidthBackground($colorWhite);

  @include sm {
    padding: 0;
    margin-bottom: 69px;
    @include fullWidthElement;
  }

  .content {
    background: $colorPelorous;
    color: $colorWhite;
    @include clearfix;
    img {
      width: 370px;
      float: left;

      @include sm {
        width: 100%;
        float: none;
      }
    }
    .subscribe {
      box-sizing: border-box;
      padding: 19px;
      float: left;
      width: calc(100% - 370px);
      h3 {
        font-family: $defaultFont;
        text-transform: none;
        font-size: 2.1rem;
        margin-bottom: 0;
      }
      ul {
        margin-bottom: 0;
      }
      li {
        background-image: url(../images/li-checkmark.png);
        padding: 0 0 0 25px;
        background-size: 20px 20px;
        background-position: left top;
        margin-bottom: 6px;

        @include sm {
          background-image: url(../images/li-checkmark-mobile.png);
          background-position: left top 6px;
          padding: 0 0 0 42px;
          background-size: auto;
          line-height: 1.2;
        }
      }
      a {
        @include button($colorWhite, $colorWhite, $colorPelorous, $colorWhite);
        display: inline-block;
        float: right;
        margin-top: -41px;
        min-width: 150px;
        line-height: 30px;
        background-image: url(../images/sm-blue-next-arrow.png);
        &:hover {
          background-color: $colorPelorous;
          background-image: url(../images/sm-white-next-arrow.png);
        }

        @include md {
          margin-top: 0;
        }

        @include sm {
          display: block;
          width: 268px;
          margin: 50px auto 0;
          line-height: 70px;
          height: 70px;
          background-image: url(../images/lg-blue-next-arrow.png);
          background-position: right 20px center;
          float: none;

          &:hover {
            background-color: $colorPelorous;
            background-image: url(../images/lg-white-next-arrow.png);
          }
        }
      }

      @include sm {
        width: 100%;
        float: none;
        padding: 41px 21px 51px;

        h3 {
          font-size: 3.1rem;
        }
      }
    }
  }
}
.paragraphs-item-bandeau-cta {
  padding: 20px 0;
  @include fullWidthBackground($colorPelorous);
  text-align: right;

  @include sm {
    padding: 51px 0;
  }

  .field-name-field-para-titre {
    display: inline-block;
    border: 0;
    padding: 0;
    margin: 0;
    width: auto;
    font-family: $defaultFont;
    font-size: 1.8rem;
    color: $colorWhite;
    position: static;
    text-transform: none;
    &:before {
      display: none;
    }

    @include sm {
      font-size: 2.8rem;
    }
  }
  .field-name-field-para-lien {
    display: inline-block;
    margin-left: 45px;

    @include sm {
      margin-left: 82px;
    }

    a {
      @include button($colorWhite, $colorWhite, $colorPelorous, $colorWhite);
      display: inline-block;
      min-width: 150px;
      line-height: 30px;
      background-image: url(../images/sm-blue-next-arrow.png);
      &:hover {
        background-color: $colorPelorous;
        background-image: url(../images/sm-white-next-arrow.png);
      }

      @include sm {
        width: 268px;
        line-height: 70px;
        height: 70px;
        background-image: url(../images/lg-blue-next-arrow.png);
        background-position: right 20px center;

        &:hover {
          background-color: $colorPelorous;
          background-image: url(../images/lg-white-next-arrow.png);
        }
      }
    }
  }
}
.paragraphs-item-rte {
}
.field-name-field-videos {
  padding: 40px 0 55px;
  text-align: center;
  @include fullWidthBackground($colorTuna);
}
.paragraphs-item-points-cles {
  padding: 30px 0;
  color: $colorWhite;
  font-weight: bold;
  @include fullWidthBackground($colorPelorous);
  .field-name-field-para-pictos {
    > .field-items {
      @include clearfix;
      > .field-item {
        margin-bottom: 0;
        float: left;
        width: 25%;
        text-align: center;

        @include sm {
          width: 50%;
          margin-bottom: 60px;
        }

        .field-collection-view {
          padding: 0;
          margin: 0;
          border: 0;
        }
        .field-name-field-para-promo-visuel {
          &:after {
            content: "";
            display: inline-block;
            width: 44px;
            height: 1px;
            background: $colorJellyBean;
            margin: 15px 0 10px;

            @include sm {
              width: 73px;
              height: 3px;
              margin: 34px 0 20px;
            }
          }
        }
      }
    }
  }
}
.paragraphs-item-abonnement-2-offres {
  @include fullWidthBackground($colorPelorous);
  text-align: center;
  padding-bottom: 40px;
  &:after {
    content: "";
    height: 100%;
    width: 1000%;
    right: 50%;
    top: 0;
    background: $colorPelorous3;
    position: absolute;
    transform: skewX(10deg);
  }
  .field-name-field-para-titre {
    margin-top: 0;
    border-width: 1px;
    color: $colorWhite;
  }
  .field-name-field-para-visuel {
    margin: 30px 0;

    @include sm {
      margin: 57px 0 72px;
    }
  }
  .field-name-field-para-abo-offres {
    > .field-items {
      margin: 0 -5px;
      display: flex;
      @include clearfix;

      @include sm {
        margin: 0;
      }

      > .field-item {
        width: calc(50% - 10px);
        float: left;
        box-sizing: border-box;
        border: 3px solid rgba($colorWhite, 0.3);
        margin: 0 5px;
        min-height: 180px;
        padding: 22px;

        @include sm {
          .entity,
          .content,
          a {
            height: 100%;
          }
          a {
            display: flex;
            flex-direction: column;

            .field-name-field-para-abo-offres-mention {
              order: 3;
            }

            .field-name-field-para-abo-offres-lien {
              order: 4;
              margin-top: auto;
              padding-top: 47px;
            }
          }
        }
      }
    }
  }
  .field-name-field-para-abo-offres-titre {
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 60%;
    margin: 0 auto;

    @include sm {
      font-size: 3rem;
      width: 100%;
    }
  }
  .field-name-field-para-abo-offres-prix {
    display: inline-block;
    padding: 15px;
    font-family: $fontMontserrat;
    font-weight: bold;
    color: $colorWhite;
    font-size: 3.5rem;
    vertical-align: middle;

    @include sm {
      font-size: 4.5rem;
      padding: 0;
      margin: 30px 0 0;
    }
  }
  .field-name-field-para-abo-offres-lien {
    display: inline-block;
    padding: 15px;
    vertical-align: middle;
    > .field-items {
      @include cta-gradient($bg: $colorWhite, $color: $colorPelorous);
      width: 189px;
      line-height: 45px;
      background-image: url(../images/lg-blue-next-arrow.png);
      background-position: right 14px center;
      background-repeat: no-repeat;
      color: $colorPelorous;
      border: 2px solid $colorWhite;
      &:hover {
        background-image: url(../images/lg-white-next-arrow.png);
        color: $colorWhite;
      }
      &:after {
        border: none;
      }
    }
    .field-item {
      position: relative;
    }

    @include sm {
      padding: 0;
      margin-top: 44px;

      > .field-items {
        width: 95%;
        line-height: 70px;
      }
    }
  }
  .field-name-field-para-abo-offres-mention {
    color: $colorBlack;
    font-size: 1.4rem;
    text-align: left;
    margin-left: 30px;

    @include sm {
      font-size: 2.4rem;
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }
  .field-name-field-para-lien {
    .field-item {
      @include button($border: $colorWhite, $color: $colorWhite);
      color: $colorWhite;
      background-image: url(../images/lg-white-next-arrow.png);
      background-position: right 13px center;
      background-repeat: no-repeat;
      background-size: 10px 17px;
      box-sizing: border-box;
      display: inline-block;
      line-height: 42px;
      height: auto;
      margin-top: 37px;
      padding: 0;
      &:hover {
        background-image: url(../images/lg-blue-next-arrow.png);
        background-color: $colorWhite;
        color: $colorPelorous
      }
      a {
        box-sizing: border-box;
        padding: 0 40px 0 15px;
      }

      @include sm {
        background-position: right 26px center;
        background-size: auto;
        line-height: 70px;
        margin-top: 51px;
        width: 100%;
        a {
          box-sizing: border-box;
          padding: 0 40px 0 15px;
        }
      }
    }
  }
  .field-name-field-para-abo-info {
    color: $colorBlack;
    margin-top: 30px;
    p:first-child {
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 5px;
    }
    p:last-child {
      margin-bottom: 0;
      font-size: 1.4rem;
    }

    @include sm {
      font-size: 2.4rem;
    }
  }
}
.paragraphs-item-temoignages {
  @include fullWidthBackground($colorShark);
  color: $colorWhite;

  @include sm {
    margin: 0 -21px;
  }

  .field-name-field-para-titre {
    color: $colorWhite;
    border: 0;
  }
  .node-temoignage {
    .content {
      > div:nth-child(1) {
        display: inline-block;
        vertical-align: middle;
        margin-right: 21px;
      }
      > div:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 120px);
      }

      @include sm {
        > div:nth-child(1) {
          display: inline;
          vertical-align: baseline;
          margin-right: 20px;
          float: left;

          img {
            width: 228px;
          }
        }
        > div:nth-child(2) {
          display: inline;
          vertical-align: baseline;
          width: auto;
        }
      }
    }
  }
  .field-name-field-temoignages {
    @include fullWidthBackground($colorTuna);
    box-sizing: border-box;
    padding: 28px 0 70px;
    li {
      outline: none;
    }

    @include sm {
      padding: 32px 21px 133px;

      li {
        padding-left: 2px;
      }
    }
  }
  .field-name-field-temoignage-logo {
    img {
      border-radius: 50%;
      background: $colorWhite;
    }
  }
  .temoignage-auteur {
    font-weight: bold;
    margin-bottom: 21px;
  }
  .slick-list {
    @include clearfix;
    overflow: hidden;
  }
  .slick-track {
    @include clearfix;
    align-items: center;
    display: flex;
  }
  .slick-slide {
    display: block;
    float: left;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -45px;
    z-index: 1;
    text-indent: -9999px;
    cursor: pointer;
    padding: 0;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 18px;
    height: 31px;
    &:before {
      background: url(../images/blue-slider-arrow.png) center center no-repeat;
      width: 18px;
      height: 31px;
      content: "";
      display: block;
    }
    &.slick-next {
      left: auto;
      right: -45px;
      &:before {
        transform: rotate(180deg);
      }
    }
  }
  .slick-dots {
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    li {
      background-image: none !important;
      display: inline-block;
      padding: 0 !important;
      button {
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        padding: 0;
        color: transparent;
        outline: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: rgba(255,255,255,0.3);
        border: 2px solid transparent;
        margin-right: 19px;
        @include transition;
      }
      &.slick-active, &:hover {
        button {
          background: transparent;
          border-color: $colorRed;
        }
      }
    }
  }

  @include sm {
    .slick-slider {
      position: static !important;
    }

    .slick-arrow {
      background: rgba($colorBlack, 0.3);
      top: auto;
      bottom: 0;
      left: 0;
      height: 101px;
      width: 70px;
      transform: none;

      &:before {
        background-image: url(../images/nav-slider-prev.png);
        transform: rotate(0deg);
        width: 100%;
        height: 100%;
      }

      &.slick-next {
        left: auto;
        right: 0;

        &:before {
          background-image: url(../images/nav-slider-next.png);
          transform: rotate(0deg);
        }
      }

      &:hover {
        background: rgba($colorBlack, 0.8);
        &:before {
          background-image: url(../images/nav-slider-prev.png);
        }
        &.slick-next {
          &:before {
            background-image: url(../images/nav-slider-next.png);
          }
        }
      }
    }

    .slick-dots {
      text-align: center;
      position: absolute !important;
      bottom: 0;
      height: 101px;
      background: rgba($colorBlack, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      right: 0;
      margin-bottom: 0 !important;
      transform: none;

      li {
        display: inline-block;
        background: transparent;
        margin: 0 25px;
        float: none;

        button {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: $colorBlack;
          border: 2px solid transparent;
          margin: 1px;
          outline: 0;
          text-indent: -9999px;
          @include transition;
        }

        &.slick-active {
          button {
            width: 34px;
            height: 34px;
            margin: 0;
            border-color: $colorRed;
            background: transparent;
          }
        }
      }
    }
  }
}
