// display mode full
.node-type-ouvrage {
  main {
    .section {
      background-color: transparent;

      @include max($lg-width) {
        padding: 0 1.5rem;
      }

      .collapsible:not(.group-ouvrage-meme-collection) {
        background: rgba($colorPelorous, 0.1);
        padding: 1.6rem;

        .label-title {
          color: $colorGrisEnseignant;
          text-transform: uppercase;
          font-family: $fontMontserrat;
          font-size: 1.6rem;
          font-weight: 700;

          &::after {
            display: block;
            width: 4.4rem;
            height: 0.1rem;
            margin: 1.6rem 0;
            content: "";
            background: $colorPelorous;
          }
        }

        .field-group-format-toggler  {
          display: inline-block;
          box-sizing: border-box;
          width: 100%;
          font-family: $defaultFont;
          font-size: 1.6rem;
          background-color: $colorWhite;
          padding: 14px;
          line-height: 23px;
          text-transform: none;
          border-bottom: 1px solid $colorEbb;
          color: $colorPelorous;
          font-weight: bold;

          @include sm {
            transition: none;
          }

          a:before {
            content: "";
            display: inline-block;
            background-image: url(../images/lg-blue-next-arrow.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 8px 15px;
            transform: rotate(90deg);
            padding-right: 20px;
            width: 13px;
            height: 23px;
            vertical-align: bottom;
            @include transition;

            @include sm {
              transition: none;
            }
          }
          &:hover {
            color: $colorAstronaut;
            a:before {
              background-image: url(../images/lg-astronaut-next-arrow.png);
            }
          }

          @include sm {
            font-size: 2.6rem;
            padding: 26px 20px 26px 50px;
            line-height: 1;


            a {
              position: relative;
              transition: none;

              &:before {
                display: block;
                position: absolute;
                left: -24px;
                top: 50%;
                margin-top: -8px;
                padding: 0;
              }
            }
          }
        }
        &.collapsed {
          .field-group-format-toggler {
            a:before {
              transform: rotate(0deg);
            }
          }
        }
        h3 {
          margin-bottom: 0;
          &:after {
            display: none;
          }
        }

        .field-group-format-wrapper {
          width: 100%;

          .field-item {
            .ouvrage-format {
              text-transform: none;
              font-weight: normal;
              font-size: 1.6rem;
              margin-bottom: 0px;
              vertical-align: inherit;
              float: left;
              margin-top: 7px;
              .ico {
                vertical-align: middle;
                margin-top: -2px;
              }

              @include sm {
                order: 1;
                font-size: 2.6rem;
                float: none;
                flex: 0 1 100%;
                padding-left: 70px;
                margin-top: 0;
                position: relative;

                .ico {
                  position: absolute;
                  width: 50px;
                  text-align: right;
                  left: 0;
                  top: 5px;
                  margin: 0;

                  img {
                    display: inline-block;
                  }
                }
              }
            }

            .subtitle {
              float: left;
              font-size: 1.6rem;
              padding-top: 7px;

              &:before {
                content: "-";
                display: inline-block;
                padding: 0 5px;
              }
              &:empty {
                display: none;
              }

              @include sm {
                order: 2;
                font-size: 2.6rem;
                float: none;
                flex: 0 1 100%;
                padding-top: 0;
                padding-left: 70px;
                font-weight: 300;

                &:before {
                  display: none;
                }
              }
            }
            .price {
              font-family: $fontMontserrat;
              font-size: 1.6rem;
              font-weight: bold;
              color: $colorAbbey;
              width: 12.5%;
              text-align: center;

              @include sm {
                order: 3;
                padding-left: 70px;
                width: auto;
                font-size: 2.6rem;
                line-height: 60px;
                text-align: left;
              }
            }
            .price + .link {
              margin-left: 12.5%;

              @include sm {
                margin-left: auto;
              }
            }
            .order {
              width: 12.5%;
              text-align: center;
              height: 24px;
              position: relative;
              a {
                background-image: url(../images/button-cart-icon.png);
                background-position: center;
                background-repeat: no-repeat;
                text-indent: -9999px;
                outline: none;
                display: inline-block;
                width: 36px;
                height: 36px;
                position: absolute;
                top: -6px;
                background-color: transparent;
                border-radius: 50%;
                margin-left: -18px;
                @include transition;
              }
              &:hover {
                a {
                  background-color: $colorPelorous;
                  background-image: url(../images/collpasible-cart-hover.png);
                }
              }

              @include sm {
                order: 4;
                width: auto;
                height: 60px;
                width: 145px;
                margin-left: auto;

                a {
                  background-image: url(../images/button-cart-icon-mobile.png);
                  text-indent: -9999px;
                  width: 40px;
                  height: 32px;
                  top: 13px;
                  margin-left: -20px;
                  border-radius: 0;
                }

                &:hover {
                  a {
                    background-color: transparent;
                    background-image: url(../images/button-cart-icon-mobile.png);
                  }
                }
              }
            }
            .link {
              width: 12.5%;
              text-align: center;
              .ouvrage-detail {
                background-image: url(../images/button-details-icon-hover.png);
                background-position: right 7px center;
                background-repeat: no-repeat;
                background-size: 16px;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
                line-height: 29px;
                font-size: 1.4rem;
                border: 2px solid $colorPelorous;
                font-weight: bold;
                padding-right: 24px;
                color: $colorPelorous;
                &:hover {
                  color: $colorWhite;
                  background-image: url(../images/button-details-icon.png);
                  background-color: $colorPelorous;
                }
              }

              @include md {
                width: 16%;
              }

              @include sm {
                order: 5;
                width: auto;
                width: 167px;

                .ouvrage-detail {
                  background-size: 19px;
                  background-position: right 16px center;
                  font-size: 2.6rem;
                  line-height: 56px;
                  height: 60px;
                  width: 167px;
                }
              }
            }
          }
        }
      }
      ul {
        margin-bottom: 0;
      }

      .node-ouvrage-full {
        > .content {
          display: flex;
          flex-direction: column;
          gap: 4rem;
        }
      }
    }
  }
  #content {
    margin: 30px 0;

    @include sm {
      margin: 0;
      padding: 64px 0;
    }
  }

  #group-ouvrage-produit {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include min($sm-width) {
      margin-bottom: 2rem;
    }

    &-left {
      width: calc(25% - 1rem);
      min-width: calc(25% - 1rem);
      height: fit-content;
      padding: 1.2rem 1rem;
      background-color: $colorWhite;

      .ouvrage-visuels {
        position: relative;
        .ouvrage-nouveaute {
          position: absolute;
          top: 0;
          left: 0;
          background-color: $colorRed;
          color: $colorWhite;
          padding: 6px 10px;
          font-size: 1.4rem;
          font-weight: bold;
          text-transform: uppercase;
          z-index: 9999;

          @include sm {
            width: 170px;
            height: 42px;
            padding: 0;
            line-height: 42px;
            font-size: 2.4rem;
            text-align: center;
          }
        }
        .ouvrage-visuel-principal {
          margin-bottom: 10px;
        }
        .field-name-field-ouvrage-image {
          box-sizing: border-box;
          padding: 35px;
          background: $colorWhite;
          margin: 0;
        }

        .ouvrage-visuels-secondaires {
          &.slick-slider {
            padding: 0 2.4rem;
          }

          .slick-track {
            display: flex;
            align-items: center;
          }

          .slick-arrow {
            position: absolute;
            z-index: 1;
            top: 50%;

            &.slick-prev {
              transform: translateY(-50%) rotate(180deg);
            }

            &.slick-next {
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
      }

      @include sm {
        width: 100%;
        padding-right: 0;
        margin-bottom: 60px;
      }
    }

    &-middle, &-right {
      display: flex;
      box-sizing: border-box;

      h3 {
        &:after {
          display: none;
        }
      }
    }

    &-middle {
      flex-direction: column;
      gap: 1.6rem;
      width: 100%;
      padding: 0 2.2rem;

      > .field-label-inline {
        display: flex;
        align-items: center;
        width: fit-content;
        margin: 0;
        padding: 0.8rem;
        background-color: $colorWhite;

        &::before {
          margin-right: 0.8rem;
        }

        .field-label {
          min-width: 9rem;
          text-transform: uppercase;
          font-size: 1.4rem;
          font-weight: 700;
        }

        .field-items {
          a {
            text-decoration: underline;
            color: $colorPelorous;
            font-size: 1.4rem;
            font-weight: 700;
            vertical-align: middle;
          }
        }

        &.field-name-field-collection {
          &::before {
            content: url(../images/book.svg);
            margin-right: 0.8rem;
          }
        }

        &:not(.field-name-field-collection) {
          &::before {
            content: url(../images/author.svg);
            margin-right: 0.8rem;
          }
        }

      }

      .ouvrage-format {
        text-transform: uppercase;
        vertical-align: middle;
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        font-weight: bold;
        margin-bottom: 10px;
        .ico {
          display: inline-block;
          margin-right: 12px;
          height: 25px;
        }

        @include sm {
          font-size: 2rem;
        }
      }

      .ouvrage-separateur,
      .ouvrage-format,
      .field-name-field-ouvrage-accroche {
        margin: 0;
      }

      .btn-divers-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.8rem;

        @include sm {
          justify-content: center;
        }

        a {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          width: fit-content;
          padding: 0.8rem;
          text-decoration: none;
          border: 0.1rem solid $colorPelorous;
          color: $colorPelorous;
          background-color: $colorWhite;
          font-family: $fontMontserrat;
          font-size: 1.2rem;
          font-weight: 600;
          cursor: pointer;
          @include transition;

          &:hover,
          &:focus {
            background-color: $colorPelorous;
            color: $colorWhite;
          }

          &.btn-lire-extrait {
            &::before {
              content:url(../images/read-blue.svg);
            }

            &:hover,
            &:focus {
              &::before {
                content:url(../images/read-white.svg);
              }
            }
          }


          &.btn-extraits-sonores {
            height: 3.4rem;

            &::before {
              content:url(../images/audio-blue.svg);
            }

            &:hover,
            &:focus {
              &::before {
                content:url(../images/audio-white.svg);
              }
            }
          }

          &.btn-videos {
            &::before {
              content:url(../images/video-play-blue.svg);
            }

            &:hover,
            &:focus {
              &::before {
                content:url(../images/video-play-white.svg);
              }
            }
          }

          &.btn-site-eleve {
            &::before {
              content:url(../images/site-eleve-blue.svg);
            }

            &:hover,
            &:focus {
              &::before {
                content:url(../images/site-eleve-white.svg);
              }
            }
          }
        }
      }

      @include min($sm-width) {
        gap: 1.6rem;
      }
    }

    &-right {
      display: flex;
      padding: 0 2.2rem;

      @include sm {
        width: 100%;
      }

      #group-ouvrage-produit-encart {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        width: 100%;
        height: fit-content;
        padding: 1.6rem;
        background-color: $colorWhite;

        .group-prix-wrapper {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          > div {
            @include min($sm-width) {
              width: auto;
            }
          }

          .prescription-titre {
            color: $colorPelorous;
            font-size: 2rem;
            font-weight: 700;
          }
        }

        .group-prix-public {
          display: flex;
          flex-direction: column;
          padding: 0.8rem 1.6rem;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid $colorPelorous;
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), $colorPelorous;
          font-family: $fontMontserrat;

          input[type="radio"] + label {
            color: $colorPelorous;
            font-size: 1.4rem;
            font-weight: 600;
          }

          .commerce-price {
            margin-top: 0.6rem;
            padding-left: 3.6rem;
            font-size: 2.4rem;
            font-weight: 700;
          }
        }

        .group-prix-ens {
          display: flex;
          gap: 0.8rem;
          padding: 0.8rem 1.6rem;
          border: 0.1rem solid $colorEnseignant;
          border-radius: 0.4rem;
          font-family: $fontMontserrat;

          .forfait__content {
            .form-type-radio {
              label {
                color: $colorEnseignant;
                font-size: 1.4rem;
                font-weight: 600;
              }
            }

            .commerce-price {
              margin-top: 0.6rem;
              padding-left: 3.6rem;
              color: $colorGrisEnseignant;
              font-size: 1.4rem;
              font-weight: 700;
            }
          }

          .group-prix__tooltip {
            position: relative;
            height: fit-content;
            cursor: pointer;

            .icon-tooltip {
              display: block;
              width: 1.8rem;
              height: 1.6rem;
              background: url(../images/tooltip.svg) center no-repeat;
            }

            .tooltip__content {
              display: none;
            }

            &:hover,
            &:active,
            &:focus {
              .tooltip__content {
                position: absolute;
                z-index: 1;
                right: 0;
                display: block;
                width: 37rem;
                padding: 0.8rem;
                border: 0.1rem solid $colorGrisEnseignant;
                border-radius: 0.4rem;
                background-color: $colorWhite;
                font-size: 1.4rem;
              }
            }
          }
        }

        #group-choix-abonnement {
          > .choix-wrapper {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            #group-abonnement-mensuel {
              label {
                &::after {
                  left: 0.5rem;
                }
              }
            }

            .group-produit-encart {
              width: 100%;
              font-size: 2.4rem;

              .form-type-radio {
                height: 0;
              }

              .infos-abonnement {
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                padding-left: 3.6rem;

                .commerce-price {
                  color: $colorTuna;
                  display: inline-block;
                  font-family: $fontMontserrat;
                  line-height: 3.2rem;
                  font-weight: 700;
                }

                > div:nth-child(3) {
                  font-family: $fontMontserrat;
                  font-size: 1.2rem;
                  font-weight: 500;
                }
              }
            }

            #group-abonnement-mensuel {
              padding: 0.8rem 1.6rem;
              border: 0.1rem solid $colorGrey20;
              border-radius: 0.4rem;


              .choix-titre {
                color: $colorBostonBlue;
                font-family: $fontMontserrat;
                font-size: 1.4rem;
                font-weight: 600;
              }

              @include min($sm-width) {
                width: auto;
              }
            }

            #group-abonnement-annuel {
              width: 100%;
              border: 0.1rem solid $colorGreenSuccess;
              border-radius: 0.4rem;

              #titre-abonnement-annuel {
                padding: 0.8rem 1.6rem;
                background-color: $colorGreenSuccess;
                font-family: "Montserrat",sans-serif;
                text-align: center;
                color: $colorWhite;
                font-size: 1.2rem;
                font-weight: 700;
              }

              .choix-titre {
                color: $colorGreenSuccess;
                font-family: $fontMontserrat;
                font-size: 1.4rem;
                font-weight: 600;
              }

              .group-produit-encart {
                width: auto;
                padding: 0.8rem 1.6rem;
              }

              @include min($sm-width) {
                width: auto;
              }
            }
          }
        }

        .group-produit-abonnement-mensuel,
        .group-produit-abonnement-annuel {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
        }

        .group-prix,
        .choix-abonnement .group-produit-encart-gauche {
          position: relative;

          .form-type-radio {
            label {
              position: initial;
              padding-left: 3.6rem;
              line-height: initial;
              cursor: default;

              &::before {
                display: none;
              }
            }

            input {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              display: block;
              width: 2rem;
              min-width: 2rem;
              height: 2rem;
              padding: 0;
              border: 0.2rem solid $colorEbb;
              border-radius: 50%;
              cursor: pointer;

              &:checked {
                display: flex;
                align-items: center;
                border: 0.2rem solid $colorPelorous;

                +label {
                  &::before {
                    display: none;
                  }

                  &::after {
                    position: absolute;
                    top: 50%;
                    left: 2.1rem;
                    transform: translateY(-50%);
                    display: block;
                    content: "";
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background-color: $colorPelorous;
                  }
                }
              }
            }
          }
        }
      }

      .disponibilite-wrapper {
        display: flex;
        background-image: url(../images/icon-check-green.svg);
        background-size: 1.6rem 1.2rem;
        background-position-y: center;
        background-repeat: no-repeat;
        padding-left: 2rem;
        font-family: $fontMontserrat;
        font-size: 1.3rem;

        @include min($sm-width) {
          font-size: 1.4rem;
        }
      }

      .btn-partenaire-wrapper {
        a {
          align-items: center;
          border: 1px solid $colorPelorous;
          display: flex;
          gap: 0.4rem;
          justify-content: center;
          margin: 0;
          padding: 0.8rem;

          @include sm {
            font-size: 1.6rem;
          }
        }
      }

      .field-name-field-ean13 {
        display: inline-block;
        width: fit-content;
        padding: 0.4rem;
        color: $colorTuna;
        background-color: $colorEbb;
        font-size: 1.2rem;
        font-weight: 300;
      }

      .commerce-add-to-cart {
        width: 100%;

        > div:nth-child(1) {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .wrapper-quantity-actions {
            display: flex;
            gap: 0.8rem;

            .form-item-quantity {
              display: flex;
              align-items: center;
              max-width: 7rem;

              label {
                display: none;
              }

              input {
                height: 100%;
                padding: 0 1rem;
                text-align: center;
                color: $colorGrey20;
                font-family: $fontMontserrat;
                font-size: 1.6rem;
                font-weight: 600;
              }

              .select2 {
                &-selection {
                  border-color: $colorPelorous;

                  &__rendered {
                    color: $colorGrey20;
                    font-weight: 600;
                  }

                  &__arrow {
                    border-left: $colorPelorous !important;

                    b {
                      border-color: $colorPelorous transparent transparent transparent;
                    }
                  }
                }
              }
            }

            .form-wrapper {
              flex-grow: 1;

              input {
                width: 100%;
                height: 4rem;
                padding: 0.8rem 2.4rem;
                gap: 0.8rem;
                flex: 1 0 0;
                color: $colorWhite;
                font-size: 1.6rem;
                font-weight: 600;
                border: none;
                background-color: $colorPelorous;
                background-image: none;
                opacity: unset;
                pointer-events: initial;

                &:disabled {
                  background-color: $colorGrey20;
                  cursor: auto;
                }

                &:not([disabled]) {
                  &:hover,
                  &:focus {
                    color: $colorPelorous;
                    background-color: $colorWhite;
                    border: 0.1rem solid $colorPelorous;
                  }
                }
              }
            }
          }

          .achat-nombre {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            text-decoration: underline;
            color: $colorPelorous;
            font-family: $fontMontserrat;
            font-size: 1.4rem;
            font-weight: 700;

            &::before {
              content: url(../images/books-stack.svg);
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
      }

      @include min($sm-width) {
        width: 28rem;
        min-width: 28rem;
        padding: 0;
      }
    }

    @include min($sm-width) {
      flex-direction: row;
    }
  }

  .select2-results__option {
    font-size: 1.6rem;
  }

  .group-ancres-contenus {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    &.-sticky {
      .wrapper-boutons-ancres {
        position: fixed;
        z-index: 10;
        top: 0;
        width: 100%;
        max-width: 117.8rem;
      }
    }

    .wrapper-boutons-ancres {
      display: flex;
      flex-direction: column;
      background-color: $colorWildSand;

      > .anchor-wrapper {
          display: inline-flex;

        a {
          display: inline-block;
          width: 100%;
          text-decoration: none;
          padding: 1.6rem 1.8rem;
          text-transform: uppercase;
          text-align: center;
          color: $colorTuna;
          border-bottom: 0.1rem solid $colorGrey20;
          font-family: $fontMontserrat;
          font-size: 1.6rem;
          font-weight: 700;

          &.active {
            padding-bottom: 1.3rem;
            color: $colorPelorous;
            border-bottom: 0.4rem solid $colorPelorous;
          }

          @include min($sm-width) {
            width: initial;
            border-bottom: none;
          }
        }

        @include min($sm-width) {
          width: initial;
        }
      }

      @include min($sm-width) {
        flex-direction: row;
        justify-content: center;
        border-bottom: 0.1rem solid $colorGrey20;
      }
    }
  }

  .wrapper-reserve-enseignants {
    display: flex;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $colorEnseignant;
    padding: 1.8rem;

    @include min($sm-width) {
      align-items: flex-start;
    }

    h2, span {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      color: $colorGrisEnseignant;
      font-size: 1.6rem;

      span {
        color: $colorEnseignant;
      }
    }

    .ouvrage-separateur {
      background-color: $colorEnseignant;
      margin: 1.6rem 0;
    }

    &-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.8rem;

      > div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        border: 1px solid $colorEnseignant;
        background-color: $colorWhite;

        a {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          padding: 0.8rem 1.6rem;
          font-family: 'Montserrat', sans-serif;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.8rem;
          color: $colorEnseignant;

          &:focus,
          &:hover {
            color: $colorWhite;
            background-color: $colorEnseignant;
          }

          &.btn-feuilleter-specimen {
            &::before {
              content: url(../images/read-orange.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/read-white.svg);
              }
            }
          }

          &.btn-demo-version-numerique {
            &::before {
              content: url(../images/demo-orange.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/demo-white.svg);
              }
            }
          }

          &.btn-ressources-gratuites {
            &::before {
              content: url(../images/puzzle-orange.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/puzzle-white.svg);
              }
            }
          }

          &.btn-reseaux-sociaux {
            &::before {
              content: url(../images/social-network-orange.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/social-network-white.svg);
              }
            }
          }

          &.btn-site-enseignant {
            &::before {
              content: url(../images/link.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/link-white.svg);
              }
            }
          }

          &.btn-tutoriels {
            &::before {
              content: url(../images/tuto-orange.svg);
            }

            &:focus,
            &:hover {
              &::before {
                content: url(../images/tuto-white.svg);
              }
            }
          }
        }
      }
    }
  }

  #group-ouvrage-formats-ens {
    display: flex;
    width: 100%;

    @include sm {
      flex-wrap: wrap;
    }

    &.avec-bloc-ens {
      > div:nth-child(1) {
        @include min($sm-width) {
          width: calc(100% - 32rem);
        }
      }

      .node-ouvrage-search-result {
        @include min($sm-width) {
          width: 24rem !important;
        }
      }
    }

    &.sans-bloc-ens {
      > div:nth-child(1) {
        @include min($sm-width) {
          width: 100%;

          .slick-track {
            margin: 0 auto;
          }
        }
      }
    }

    .wrapper-pour-enseignants {
      display: flex;
      padding: 1.6rem;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), $colorEnseignant !important;
      position: relative;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;

      &:only-child {
        width: 100% !important;

        .slick-track {
          margin: 0 auto;
        }

        .node-ouvrage-search-result {
          @include min($sm-width) {
            width: auto !important;
          }
        }
      }

      @include min($sm-width) {
        width: 32rem;
      }

      .node-ouvrage-search-result {
        margin-left: 1rem;

        @include min($sm-width) {
          margin: 0 auto;
        }
      }

      > div:nth-child(1), span {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: $colorGrisEnseignant;
        font-size: 1.6rem;

        span {
          color: $colorEnseignant;
        }
      }

      > div:nth-child(1) {
        position: relative;

        &::after {
          @include seperatorLine($colorEnseignant);

          margin: 1.6rem 0;
        }
      }

      .field-group-pour-enseignants {
        width: 100%;

        .content {
          margin: 0 auto;
        }

        li {
          .exclusivite-enseignant {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding: 0.6rem 1.4rem 0.6rem 0.6rem;
            text-transform: uppercase;
            color: $colorWhite;
            font-size: 1.2rem;
            font-weight: 700;
            background: url(../images/rectangle-ens.svg) no-repeat center;
          }
        }

        .slick-arrow  {
          position: absolute;
          top: 2.4rem;

          &.slick-prev {
            top: 0.85rem;
            right: 4.5rem;
            left: unset;
          }

          &.slick-next {
            right: 0.8rem;
          }

          &.slick-prev:not(.slick-disabled),
          &.slick-next:not(.slick-disabled) {
            font-size: 0;
            background-image: url(../images/arrow-red.svg);
            border-color: $colorEnseignant;
          }
        }
      }
    }

    > div:nth-child(1) {
      position: relative;
      display: flex;
      flex-direction: column;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90)), $colorPelorous;
      padding: 1.6rem;
      box-sizing: border-box;
      width: 100%;

      .label-title {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        color: $colorGrisEnseignant;

        span {
          color: $colorEnseignant;
        }
      }

      .node-ouvrage-search-result {
        margin-left: 1rem;

        .content {
          margin: 0 auto;
        }
      }

      .slick-arrow  {
        position: absolute;
        top: 2.4rem;

        &.slick-prev {
          top: 0.85rem;
          right: 4.5rem;
          left: unset;
        }

        &.slick-next {
          right: 0.8rem;
        }
      }
    }

    .item-list {
      display: flex;
      width: 100%;

      li {
        width: fit-content;
        background-image: none !important;
        padding: 0 !important;

        .node-ouvrage {
          width: auto;
        }
      }
    }
  }

  .field-label {
    font-weight: normal;
    text-transform: none;

    .separator {
      margin-left: 4px;
    }
  }
  .field-label-above {
    .field-label .separator {
      display: none;
    }
  }

  .visuel-slider {
    position: relative;
    overflow: hidden;
    margin-bottom: 0 !important;
    line-height: 0;
    &.ouvrage-visuel-principal {
      margin-bottom: 10px !important;

      li {
        text-align: center;
        transition: opacity ease-in-out 0.4s !important;

        img {
          width: 100%;

          @include sm {
            width: revert-layer;
          }
        }

        &.slick-current {
          opacity: 1 !important;
        }
      }

      li:after {
        content: "";
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include transition;
      }

      &:hover {
        li:after {
          content: "";
          background-color: rgba($colorPelorous, 0.7);
          background-image: url(../images/principal-hover.png);

          @include sm {
            display: none;
          }
        }
      }

      .video-thumbnail,
      .video-thumbnail.slick-current {
        opacity: 0 !important;
      }

      .slick-prev {
        left: 0;
        z-index: 1;
      }

      .slick-next {
        right: 0;
        z-index: 1;
      }
    }

    &.ouvrage-visuels-secondaires {
      position: relative;

      li {
        background-color: $colorWhite;
        position: relative;
        height: auto;
        text-align: center;

        img {
          opacity: 0.8;
          @include transition;
          width: 100%;
          max-height: 100%;
        }
        &.slick-current {
          img {
            opacity: 1;
          }
        }
        &.video-thumbnail {
          a {
            &:after {
              content: "";
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url(../images/video-play.png);
              background-repeat: no-repeat;
              background-size: 45px 45px;
              background-position: center center;
              display: block;
            }
          }
        }
        &:hover {
          background: $colorPelorous;
          img {
            opacity: 0.7;
          }
        }
      }

      .slick-prev {
        position: absolute;
        left: 0;
      }
    }

    li {
      background-image: none !important;
      display: block;
      float: left;
      margin: 0 5px;
      outline: none;
      cursor: pointer;
      padding: 0 !important;
      background-color: $colorWhite;
      img {
        margin: 0 auto;
        background-color: $colorWhite;
      }
    }
    .slick-list {
      @include clearfix;
      margin:0 -5px;
    }
  }

  .ouvrage-title {
    font-family: $fontMontserrat;
    font-size: 2.7rem;
    font-weight: bold;
    color: $colorBlack;

    @include sm {
      font-size: 3.7rem;
      padding: 0;
      position: relative;
      box-sizing: border-box;

      &:after {
        width: 104px;
        height: 2px;
        margin: 20px 0 50px;
      }
    }
  }

  .field-name-field-ouvrage-accroche {
    font-family: $defaultFont;
    font-size: 1.6rem;
    font-weight: 300;
    margin: 10px 0;
    margin-bottom: 18px;
    text-transform: none;

    .produit-anchor {
      font-weight: 700;
    }
  }

  .authors-list {
    margin: 5px 0;
    a {
      color: $colorPelorous;
    }

    @include sm {
      margin-top: 40px;

      div {
        font-size: 2.6rem;
      }
    }
  }

  .ouvrage-separateur {
    @include seperatorLine($width: 57px);
  }

  // To remove for the lot 3
  .authors {
    float: left;
    margin-left: 3px;
  }

  ul.authors-list.field-items {
    margin: 0;
    list-style: none;
    li {
      background-image: none !important;
      display: inline;
      padding: 0 0 0 3px;
    }
  }
  #group-ouvrage-produit-encart {
    .group-produit-encart-droite {
      float: right;
      text-align: center;
      background-color: $colorPelorous;
      box-shadow: 0 0 5px rgba($colorBlack, 0.3);
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include md {
        padding: 15px;
        width: calc(55% - 5px);
      }

      @include sm {
        width: 100%;
        padding: 39px;
        display: flex;
        float: none;
        text-align: center;
      }

      .field-name-field-ouvrage-essai {
        font-family: $fontMontserrat;
        font-size: 1.8rem;
        box-sizing: border-box;
        height: 36px;
        width: 233px;
        margin-bottom: 10px;
        background-color: transparent;
        border: 2px solid $colorWhite;
        color: $colorWhite;

        &:hover {
          background-color: $colorWhite;
          color: $colorPelorous;
        }

        a {
          text-align: center;
          line-height: 32px;
          display: block;
        }

        @include sm {
          font-size: 2.8rem;
          height: 60px;
          width: 350px;
          padding: 0 20px;
          display: inline-block;

          a {
            line-height: 56px;
          }
        }
      }

      .commerce-add-to-cart,
      .offre-prescription-wrapper {
        cursor: pointer;

        .form-item-quantity,
        .form-actions {
          display: inline-block;
        }

        .ajax-progress {
          .throbber {
            float: none;
            margin-left: 4px;
            position: relative;
            top: 4px;
          }

          .message {
            display: none;
          }
        }

        .form-item-quantity {
          @include clearfix;
          label {
            display: none;
          }
          input {
            font-family: $fontMontserrat;
            font-size: 1.8rem;
            box-sizing: border-box;
            width: 65px;
            height: 36px;
            background-color: transparent;
            border: 2px solid $colorWhite;
            color: $colorWhite;
            text-align: center;
            position: relative;

            @include sm {
              font-size: 2.8rem;
              height: 60px;
            }
          }
        }

        .form-submit,
        .btn-offre-prescription,
        .store-link {
          font-family: $fontMontserrat;
          font-size: 1.8rem;
          box-sizing: border-box;
          height: 36px;
          min-width: 160px;
          background-color: $colorWhite;
          background-image: url(../images/button-cart-icon.png);
          background-position-x: right 15px;
          border: 2px solid $colorWhite;
          color: $colorPelorous;
          text-align: left;
          padding-right: 50px;
          margin-left: 4px;

          &:hover {
            color: $colorBlumine;
            background-image: url(../images/cart-hover-blue.png);
            box-shadow: 0 0 9px rgba($colorBlack, 0.35);
          }

          @include sm {
            background-image: url(../images/button-cart-icon-mobile.png);
            font-size: 2.8rem;
            height: 60px;
            padding: 0 70px 0 20px;
          }
        }

        .btn-offre-prescription,
        .store-link {
          display: block;
          width: 160px;
          height: 36px;
          line-height: 32px;
          padding-left: 12px;
          background-position: right 10px center;
          background-repeat: no-repeat;
          transition: all 0.4s ease;
        }

        .btn-offre-prescription {
          width: inherit;
        }

        &.reserve-enseignant {
          cursor: inherit;

          span {
            display: inline-block;
            color: $colorWhite;
          }
        }

        &:after {
          @include seperatorLine($colorSteelBlue, 100%, 2px, 20px 0);
          @include sm {
            display: none;
          }
        }
      }
    }

    .offre-prescription-wrapper {
      a {
        width: 100%;
        height: 4rem;
        padding: 0.8rem 2.4rem;
        gap: 0.8rem;
        flex: 1 0 0;
        color: $colorWhite;
        font-size: 1.6rem;
        font-weight: 600;
        border: none;
        background-color: $colorPelorous;
        background-image: none;
        opacity: unset;
        pointer-events: initial;
      }
    }

    .field-name-field-ouvrage-parution-date {
      margin-top: 10px;
      color: $colorBlack;
      width: 100%;
      text-align: right;
      font-weight: bold;
    }

    .link-forfait-enseignant {
      a {
        color: $colorPelorous;
        font-weight: bold;
      }

      @include md {
        text-align: right;

        a {
          white-space: nowrap;
        }
      }

      @include sm {
        text-align: center;
        margin-bottom: 30px;
      }
    }
    #PopinDiv {
      line-height: 0;

      @include sm {
        display: inline-flex;
      }
    }
    .PopinButton {
      height: auto !important;
      width: auto !important;
      font-size: 1.5rem !important;
      float: none !important;
      background: transparent !important;
      margin-top: 0 !important;
      > span {
        background-image: url(../images/message-icon.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding-left: 27px;
        > span {
          background-image: url(../images/sm-white-next-arrow.png);
          background-position: right center;
          background-repeat: no-repeat;
          padding-right: 25px;
        }
      }
      span {
        font-size: 1.5rem !important;
        margin-left: 0 !important;
        float: none !important;
        margin-top: 0 !important;
        display: inline-block;
        line-height: 1;
        line-height: 16px;
        width: auto !important;
        img,
        br {
          display: none;
        }
      }
      &:hover {
        span {
          color: $colorFirefly !important;
        }
        > span {
          background-image: url(../images/message-icon-hover.png);
          > span {
            background-image: url(../images/sm-firefly-next-arrow.png);
          }
        }
      }

      @include sm {
        display: inline;

        > span {
          background-image: url(../images/message-icon-mobile.png);
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 35px;
          > span {
            background-image: url(../images/lg-white-next-arrow.png);
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 25px;
          }
        }

        span {
          font-size: 2.5rem !important;
          line-height: 1;
        }

        &:before {
          content: "";
          display: inline-block;
          margin: 20px 0;
          height: 3px;
          width: 100%;
          background-color: $colorSteelBlue;
        }

        &:hover {
          span {
            color: $colorWhite !important;
          }
          > span {
            background-image: url(../images/message-icon-mobile.png);
            > span {
              background-image: url(../images/lg-white-next-arrow.png);
            }
          }
        }
      }
    }
  }
  .bandeau-ressources-comp-wrapper {
    margin: 46px 0;
    text-align: center;
    padding: 12px 0;
    @include fullWidthBackground($colorDoveGray);
    a {
      @include button-diagonal;
      border: 2px solid $colorWhite;
      box-sizing: border-box;
      color: $colorWhite;
      font-family: $fontMontserrat;
      font-size: 1.6rem;
      width: 406px;
      height: 45px;
      display: inline-block;
      &:after {
        content: "";
        display: inline-block;
        background-image: url(../images/ressources-comp.png);
        background-position: center;
        background-repeat: no-repeat;
        width: 22px;
        height: 41px;
        vertical-align: middle;
        padding-left: 0px;
        margin-left: 10px;
      }
      &:hover {
        &:after {
          height: 45px;
        }
      }
    }

    @include sm {
      padding: 30px 0;

      a {
        font-size: 2.6rem;
        width: 100%;
        height: 70px;
        line-height: 66px;

        &:after {
          background-image: url(../images/ressources-comp-mobile.png);
          background-position: center top 8px;
          width: 35px;
          height: 66px;
          margin-left: 35px;
        }
      }
    }
  }

  .wrapper-encart-pub {
    margin-bottom: 2rem;

    .btn-encart-pub {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        position: absolute;
        margin: 0;
        text-transform: uppercase;
        font-family: $fontMontserrat;
        font-size: 1.4rem;
        font-weight: 600;
        color: $colorBlack;
      }
    }
  }

  #group-ouvrage-presentation {
    &.add-margin-top {
      padding-top: 25rem;

      @include min($sm-width) {
        padding-top: 10rem;
      }
    }
  }

  #group-caracteristiques,
  #group-ouvrage-videos-apercus,
  #group-ouvrage-extrait-audio,
  #group-ouvrage-meme-collection,
  #group-ouvrage-decouvertes {
    scroll-margin-top: 25rem;

    @include min($sm-width) {
      scroll-margin-top: 8rem;
    }
  }

  #group-ouvrage-presentation,
  #group-caracteristiques,
  #group-ouvrage-videos-apercus,
  #group-ouvrage-extrait-audio,
  #group-ouvrage-complements {
    h2,
    .field-label {
      font-family: $fontMontserrat;
      font-weight: bold;
      font-size: 2.4rem;
      text-transform: uppercase;

      &:after {
        @include seperatorLine;
        margin: 1.6rem 0;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 1.6rem;
      margin-top: 20px;
      text-transform: none;
      &:after {
        display: none;
      }
    }

    @include sm {
      padding: 0 21px;
      margin-bottom: 40px;

      .field-label {
        font-size: 3.4rem;
        margin-top: 70px;
      }
    }
  }

  #group-ouvrage-videos-apercus {
    position: relative;
    padding: 1.6rem;
    background-color: $colorGallery;

    ul.video-items {
      li {
        background: none;
      }
    }

    .slick-slide {
      iframe {
        width: auto;
        height: auto;

        @include min($sm-width) {
          width: 54.5rem;
          height: revert-layer;
        }
      }
    }

    .slick-arrow  {
      position: absolute;
      top: 2.4rem;

      &.slick-prev {
        top: 0.85rem;
        right: 4.5rem;
        left: unset;
      }

      &.slick-next {
        right: 0.8rem;
      }
    }
  }

  #group-ouvrage-extrait-audio {
    margin-top: 40px;
    padding: 2rem 1.6rem;
    background-color: $colorGallery;

    ul.audio-items {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      > li {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding-left: 0;
        background: none;

        .controls {
          order: 1;
        }
      }
    }

    span.control {
      display: none;
    }


  }

  #group-ouvrage-presentation + #group-ouvrage-complements {
    margin-top: 40px;
  }

  #group-ouvrage-presentation,
  #group-caracteristiques {
    ul {
      padding-left: 2.4rem;

      li {
        padding: 0;
        list-style: initial;
        background: none;
      }
    }
  }

  #group-ouvrage-complements,
  #group-ouvrage-temoignages,
  #group-ouvrage-decouvertes {
    h3,
    .label-title {
      @extend #page-title;
      &:after {
        display: none;
      }
    }
    li {
      background: none;
      padding: 0;
      margin-bottom: 0;
    }
  }

  #group-ouvrage-meme-collection {
    position: relative;

    > h3 {
      @extend #page-title;

      padding-bottom: 0;
      font-size: 3.2rem;
    }

    h3 {
      &::after {
        display: none;
      }
    }

    li {
      background: none;
    }

    .meme-collection-list {
      &--subtitle {
        text-align: center;
        font-family: $defaultFont;
        font-size: 1.4rem;

        @include min($sm-width) {
          margin-bottom: 2.4rem;
        }

        a {
          text-transform: initial;
          color: $colorPelorous;
          text-decoration: underline;
        }
      }

      .slick-slider {
        .node-ouvrage-search-result {
          margin-left: 1rem;

          .content {
            margin: 0 auto;
          }
        }

        .slick-arrow  {
          position: absolute;

          &.slick-prev {
            top: 3.4rem;
            right: 4rem;
            left: unset;
          }

          &.slick-next {
            top: 5rem;
            right: 0;
          }
        }
      }
    }
  }

  #group-ouvrage-complements {
    margin-bottom: 40px;
    ul {
      @include clearfix;
    }
    li {
      display: block;
      float: left;
      width: 50%;
      box-sizing: border-box;
      margin-bottom: 13px;
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 47px;
      min-height: 30px;
      background-image: url(../images/doc.png);
      &.audio {
        padding-left: 0;
        background-image: none;

        .play, .pause {
          text-indent: -9999px;
          display: block;
          float: left;
          width: 30px;
          height: 30px;
          background-image: url(../images/btn-lecture.png);
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          outline: none;
          margin-right: 17px;
        }
        .pause {
          display: none;
          background-image: url(../images/extrait-sonore.png);
        }
        .control-label {
          display: block;
          float: left;
        }
        audio {
          display: none;
        }
      }
      &.extlink {
        background-image: url(../images/ressources.png);
      }
      &.pdf {
        background-image: url(../images/pdf-icon.png);
      }
    }
    @include sm {
      padding: 0 21px;

      li {
        width: 100%;
        margin-bottom: 24px;
        padding-left: 92px;
        min-height: 56px;
        padding-top: 12px;
        background-image: url(../images/doc-mobile.png);

        &.audio {
          padding-top: 0;

          .play, .pause {
            width: 57px;
            height: 57px;
            background-image: url(../images/btn-lecture-mobile.png);
            margin-right: 35px;
          }

          .pause {
            background-image: url(../images/extrait-sonore-mobile.png);
          }

          .control-label {
            padding-top: 12px;
          }
        }
        &.extlink {
          background-image: url(../images/ressources-mobile.png);
        }
        &.pdf {
          background-image: url(../images/pdf-icon-mobile.png);
        }
      }
    }
  }

  #group-ouvrage-temoignages {
    @include fullWidthBackground($colorShark);
    color: $colorWhite;
    .label-title {
      color: $colorWhite;
    }
    .node-temoignage {
      .content {
        > div:nth-child(1) {
          display: inline-block;
          vertical-align: middle;
          margin-right: 21px;
        }
        > div:nth-child(2) {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 120px);
        }

        @include sm {
          > div:nth-child(1) {
            display: inline;
            vertical-align: baseline;
            margin-right: 20px;
            float: left;

            img {
              width: 228px;
            }
          }
          > div:nth-child(2) {
            display: inline;
            vertical-align: baseline;
            width: auto;
          }
        }
      }
    }
    .item-list {
      @include fullWidthBackground($colorTuna);
      box-sizing: border-box;
      padding: 28px 98px;
      li {
        outline: none;
      }

      @include sm {
        padding: 32px 21px 133px;

        li {
          padding-left: 2px;
        }
      }
    }
    .field-name-field-temoignage-logo {
      img {
        border-radius: 50%;
        background: $colorWhite;
      }
    }
    .temoignage-auteur {
      font-weight: bold;
      margin-bottom: 21px;
    }
    .slick-list {
      @include clearfix;
      overflow: hidden;
    }
    .slick-track {
      @include clearfix;
      align-items: center;
      display: flex;
    }
    .slick-slide {
      display: block;
      float: left;
    }
    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -45px;
      z-index: 1;
      text-indent: -9999px;
      cursor: pointer;
      padding: 0;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      width: 18px;
      height: 31px;
      &:before {
        background: url(../images/blue-slider-arrow.png) center center no-repeat;
        width: 18px;
        height: 31px;
        content: "";
        display: block;
      }
      &.slick-next {
        left: auto;
        right: -45px;
        &:before {
          transform: rotate(180deg);
        }
      }
    }

    @include sm {
      ul {
        position: static !important;
      }

      .slick-arrow {
        background: rgba($colorBlack, 0.3);
        top: auto;
        bottom: 0;
        left: 0;
        height: 101px;
        width: 70px;
        transform: none;

        &:before {
          background-image: url(../images/nav-slider-prev.png);
          transform: rotate(0deg);
          width: 100%;
          height: 100%;
        }

        &.slick-next {
          left: auto;
          right: 0;

          &:before {
            background-image: url(../images/nav-slider-next.png);
            transform: rotate(0deg);
          }
        }

        &:hover {
          background: rgba($colorBlack, 0.8);
          &:before {
            background-image: url(../images/nav-slider-prev.png);
          }
          &.slick-next {
            &:before {
              background-image: url(../images/nav-slider-next.png);
            }
          }
        }
      }
    }
  }

  #group-ouvrage-decouvertes {
    position: relative;

    .label-title {
      text-align: center;
      margin-bottom: 40px;
      font-size: 3.2rem !important;
    }



    .slick-slider {
      .node-ouvrage-search-result {
        margin-left: 1rem;

        .content {
          margin: 0 auto;
        }
      }

      .slick-arrow  {
        position: absolute;

        &.slick-prev {
          top: 3.4rem;
          right: 4rem;
          left: unset;
        }

        &.slick-next {
          top: 5rem;
          right: 0;
        }
      }
    }
  }

  .notice-abonnement-recurrent {
    clear: both;
    font-size: 13px;

    a {
      color: $colorPelorous;
      text-decoration: underline;
    }
  }

  // Slick slide common
  .slick-slider {
    position: initial;

    li.slick-slide {
      padding: 0;
    }

    .slick-arrow {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      font-size: 0;
      background-size: 1.4rem 1.4rem;
      cursor: pointer;

      &::before {
        display: none;
      }

      &:not(.slick-disabled) {
        border: 0.2rem solid $colorPelorous;
        background: url(../images/arrow-blue.svg) center no-repeat $colorWhite;
      }

      &.slick-disabled {
        border: 0.2rem solid $colorEbb;
        background: url(../images/arrow-grey.svg) center no-repeat $colorWhite;
      }

      &.slick-prev {
        transform: rotate(180deg);
      }
    }

    .slick-dots {
      position: static;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.6rem;

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          position: static;
          font-size: 0;
          width: 2.2rem;
          height: 2.2rem;
          background-color: $colorWhite;
          border: 0.2rem solid $colorEbb;
          border-radius: 50%;
          cursor: pointer;
        }

        &.slick-active {
          button {
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              position: static;
              content: "";
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 50%;
              background-color: $colorPelorous;
            }
          }
        }

        &:not(.slick-active) {
          button::before {
            display: none;
          }
        }
      }
    }
  }
}

// Display mode search result
.entity-bloc-promo {
  box-sizing: border-box;
  padding: 0 5px;
  width: 25%;
  float: left;
  margin-bottom: 10px;

  @include sm {
    width: 100%;
    margin-bottom: 40px;
  }
}

.node-ouvrage-search-result {
  .achat-nombre {
    display: none;
  }

  &.has-abonnement-mensuel {
    .form-submit {
      // Il faut aller sur la fiche produit pour choisir un abonnement.
      visibility: hidden!important;
    }
  }

  > .content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    max-width: 28rem;
    height: 43rem;
    padding: 1.4rem 0.8rem 0.8rem;
    box-sizing: border-box;
    background: $colorWhite;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 11rem;
      content: "";
      background-color: $colorPelorous;
      opacity: 0.2;
    }

    .field-name-field-ouvrage-image {
      align-self: center;
      height: 25rem;

      .field-items {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      img {
        max-height: 25rem;
        width: auto;
      }
    }

    .ouvrage-format {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      padding: 0.8rem 0;
      color: $colorBlack;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .product-title {
      display: flex;
      flex-direction: column;
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 1.4rem;
      font-weight: 700;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .group-prix {
      margin-top: auto;

      .commerce-price {
        color: $colorPelorous;
        font-family: $fontMontserrat;
        font-size: 2rem;
        font-weight: 700;
      }
    }


    &:hover {
      .produit-hover {
        display: flex;
        flex-direction: column;
        gap: 2.6rem;
        opacity: 1;
        z-index: 2;
      }

      .ouvrage-format,
      .product-title,
      .group-prix,
      .commerce-price {
        z-index: 3;
        color: $colorWhite;
      }
    }

    .produit-hover {
      opacity: 0;
      z-index: -1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 8rem 1.8rem 1.8rem;
      background: rgba($colorPelorous, 0.9);
      color: $colorWhite;
      @include transition;

      @include md {
        display: none;
        opacity: 1;
        z-index: 2;
      }

      > .ouvrage-format,
      > .product-title,
      > .group-prix,
      > .commerce-price {
        display: none;
      }

      input[type="submit"],
      .store-link,
      .offre-prescription-wrapper {
        background-color: $colorWhite;
        border-color: $colorWhite;
        color: $colorPelorous;
        background-image: url(../images/button-cart-icon.png);
        background-position: right 55px center;
        width: 100%;
        padding-right: 45px;
        font-size: 1.8rem;
        line-height: 2;
        font-family: $fontMontserrat;
        height: auto;
        min-width: 0;
        @include transition(box-shadow);

        &:hover {
          color: $colorBlumine;
          background-image: url(../images/cart-hover-blue.png);
          box-shadow: 0 0 9px rgba($colorBlack, 0.35);
        }

        @include md {
          background-position: right 15px center;
        }
      }
      .store-link {
        display: block;
        text-align: center;
        box-sizing: border-box;
        background-repeat: no-repeat;

        @include sm {
          padding: 0;
          background-image: url(../images/button-cart-icon-mobile.png);
          background-position: right 20px center;
          margin-top: 10px;
        }
      }

      .offre-prescription-wrapper {
        background-image: none;
        padding-right: 0;
        box-sizing: border-box;
        text-align: center;
        border: 2px solid $colorWhite;

        &:hover {
          background-image: none;
        }
      }

      .ouvrage-link {
        border: 2px solid $colorWhite;
        font-size: 1.8rem;
        line-height: 2;
        font-family: $fontMontserrat;
        height: auto;
        display: block;
        text-align: center;

        &:after {
          content: "";
          display: inline-block;
          background-image: url(../images/button-details-icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 19px;
          height: 19px;
          margin-bottom: -4px;
          margin-left: 15px;
        }
        &:hover {
          background-color: $colorWhite;
          border-color: $colorWhite;
          color: $colorPelorous;
          &:after {
            background-image: url(../images/button-details-icon-hover.png);
          }
        }
      }

      @include sm {
        .product-title,
        .ouvrage-format,
        .ouvrage-price {
          display: none;
        }

        input[type="submit"],
        .offre-prescription-wrapper {
          margin-top: 0;
          background-image: none;
          padding: 0;
        }

        input[type="submit"],
        .ouvrage-link {
          background-image: url(../images/button-cart-icon-mobile.png);
          background-position: right 20px center;
          background-repeat: no-repeat;
        }

        .ouvrage-link {
          background-image: url(../images/button-details-icon-mobile.png);

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

.add-to-cart-overlay {
  background: rgba($colorBlack, 0.5);

  @include sm {
    z-index: -1;
  }

  &.abonnement {
    background: none;
  }
}
.add-cart-message-wrapper {
  border: none;
  border-radius: 0;
  box-shadow: none;
  color: $colorBlack;
  display: flex;
  flex-direction: column;
  left: 50%;
  padding: 0;
  right: auto;
  top: 50%;
  width: 100%;
  max-width: 782px;
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;

  @include sm {
    left: 0;
    top: 0;
    transform: none;
    flex-direction: row;
    height: 118px;
    border-bottom: 2px solid $colorEbb;
    align-items: center;
    padding: 0 21px;
    box-sizing: border-box;
  }

  .add-to-cart-close {
    @extend .close-button;

    @include sm {
      background-size: auto;
      height: 69px;
      right: 21px;
      width: 69px;
      top: 24px;

      &:hover {
        background-image: url(../images/mobile-close.png);
        background-size: auto;
      }
    }
  }
  .added-product-message {
    background: $colorEbb;
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 2rem;
    font-weight: bold;
    padding: 25px 0;
    text-align: center;
    text-transform: uppercase;

    @include sm {
      background: $colorWhite;
      font-family: $defaultFont;
      font-size: 2.4rem;
      font-weight: normal;
      padding: 0;
      text-align: left;
      text-transform: none;
      width: 30%;
    }
  }
  .option-button-wrapper {
    border-top: 1px solid $colorEbb;
    display: inline-flex;
    float: none;
    justify-content: center;
    margin: 0;
    order: 3;
    padding: 18px 0;

    @include sm {
      padding: 0;
      display: block;
      border: 0;
      width: 55%;
      text-align: right;
    }
  }
  .new-item-details {
    width: 75%;
    float: none;
    margin: 20px auto;
    order: 2;
    position: relative;
    div {
      margin-bottom: 0;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      @include clearfix;
    }
    .field-name-field-ouvrage-image {
      flex: 0 0 12.5%;
      margin-right: 20px;
      margin-top: 0;
      min-height: auto;

      &.no-visuel {
        height: 73.3px;
      }

      + .commerce-price {
        display: none;
      }
    }
    .product-title {
      font-family: $fontMontserrat;
      color: $colorBlack;
      font-size: 1.6rem;
      line-height: 2.4rem;
      font-weight: bold;
      box-sizing: border-box;
      flex: 1 0;
      margin: 40px 20px 0 0;
    }
    .ouvrage-format {
      position: absolute;
      top: 0;
      left: calc(12.5% + 20px);
      display: flex;
      align-items: center;
      height: 40px;
      .ico {
        display: inline-block;
        margin-right: 12px;
      }
    }
    .commerce-price {
      font-family: $fontMontserrat;
      font-weight: bold;
      font-size: 2rem;
      color: $colorPelorous;

      &:before {
        display: none;
      }
    }

    @include sm {
      display: none;
    }
  }
  .option-button {
    @include button-diagonal;
    font-size: 1.8rem;
    font-weight: normal;
    line-height: 50px;
    margin: 0;
    padding: 0;
    &.continue {
      width: 246px;
      box-sizing: border-box;

      @include sm {
        display: none;
      }
    }
    &.checkout {
      margin-left: 10px;
      order: 2;
      @include button-diagonal;
      background-color: $colorPelorous;
      width: 189px;
      box-sizing: border-box;
      a {
        display: inline-block;
        background-image: url(../images/lg-white-next-arrow.png);
        background-position: right 10px center;
        background-repeat: no-repeat;
        background-size: auto 18px;
        color: $colorWhite;
        font-weight: normal;
        width: 100%;
        &:hover {
          border-right: 2px solid $colorBostonBlue;
          border-left: 2px solid $colorShakespeare;
        }
      }

      @include sm {
        margin: 0;
        width: auto;

        a {
          font-size: 2.8rem;
          background-image: url(../images/cart-white-mobile.png);
          background-position: right 20px center;
          background-repeat: no-repeat;
          background-size: auto;
          line-height: 60px;
          padding: 0 74px 0 14px;
        }
      }
    }
  }
  .notice {
    background-color: $colorWildSand2;
    padding: 15px 0;
    text-align: center;
  }
}

.field-name-field-ouvrage-comparer,
.field-name-field-ouvrage-comparer-x-y {
  float: right;
  width: calc(50% - 5px);
  margin-top: 20px;
  a {
    @include button-diagonal;
    width: 100%;
    height: 50px;
    display: inline-block;
    &:after {
      content: "";
      display: inline-block;
      background-image: url(../images/comparateur.png);
      background-position: center;
      background-repeat: no-repeat;
      width: 32px;
      height: 46px;
      vertical-align: middle;
      padding-left: 0px;
      margin-left: 20px;
    }
    &:hover {
      &:after {
        background-image: url(../images/comparateur-white.png);
        height: 50px;
      }
    }
  }

  @include md {
    width: auto;

    a {
      padding: 0 20px;
    }
  }

  @include sm {
    display: none;
  }
}

.field-name-field-ouvrage-comparer-x-y {
  float: none;
  width: 450px;
  margin: 42px auto 0;

  @include md {
    width: auto;
    max-width: 500px;
  }
}
.form-button-disabled,
.disabled {
  opacity: 0.4;
  pointer-events: none;
}
.overlay-popup {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($colorBlack, 0.5);
  z-index: 9999;
  align-items: center;
  justify-content: center;
}
.image-popup {
  position: relative;
  padding: 50px 0;
  background-color: $colorWhite;
  @extend %container;

  @include md {
    margin: 0 15px;
  }

  .close-button {
    @extend .close-button;
  }
}
.ouvrage-visuel-popup {
  width: 575px;
  margin: 0 auto;
  @include clearfix;

  li {
    display: block;
    float: left;
    width: 575px !important;
    background-color: $colorWhite;
    img {
      margin: 0 auto;
      width: auto;
      height: auto;
    }
    .image-legend {
      margin-top: 30px;
      text-align: center;
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    z-index: 1;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    padding: 0;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 18px;
    height: 31px;

    &:before {
      background-image: url(../images/blue-slider-arrow.png);
      background-position: center;
      background-repeat: no-repeat;
      width: 18px;
      height: 32px;
      content: "";
      display: block;
    }

    &.slick-next {
      left: auto;
      right: 16px;
      &:before {
        transform: rotate(180deg);
      }
    }
  }

  .apercu-title {
    display: block;
    text-align: center;
    margin-top: 10px;
  }
}

.videos-tab {
  width: 83.33%;
  margin: 0 auto;
  padding: 42px 0;
  display: none;
  @include clearfix;

  @include md {
    width: 100%;
  }

  .current-video {
    box-sizing: border-box;
    padding: 0 5px;
    width: 80%;
    float: left;
    font-size: 0;
    iframe {
      width: 100%;
    }
  }
  .item-list {
    box-sizing: border-box;
    padding: 0 5px;
    width: 20%;
    float: left;
    overflow: hidden;
    ul {
      position: relative;
    }
    li {
      display: block;
      position: relative;
      padding: 0 !important;
      background: none !important;
      .watch-video {
        margin-bottom: 10px;
        display: block;
        background-color: $colorBlack;
        &.current {
          background-color: $colorPelorous;
          .thumb-legend {
            background-color: $colorPelorous;
          }
          img {
            opacity: 0.6;
          }
          &:after {
            display: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          left: 50%;
          top: 28px;
          background-image: url(../images/video-play.png);
          background-repeat: no-repeat;
          background-size: 45px 45px;
          width: 45px;
          height: 45px;
          margin: 0 auto;
          display: block;
          transform: translateX(-50%);
        }
      }
      img {
        opacity: 0.7;
        @include transition;
      }
      &:hover {
        img {
          opacity: 0.9;
        }
        .watch-video {
          &:after {
            background-image: url(../images/video-play-hover.png);
          }
        }
      }
    }
  }
  .legend, .thumb-legend {
    background-color: $colorShark2;
    color: $colorWhite;
    font-size: 1.7rem;
    padding: 26px;
    display: block;
  }
  .thumb-legend {
    font-size: 1.3rem;
    padding: 12px;
    @include transition;
  }
  &.active {
    display: block;
  }
  .slick-arrow {
    position: absolute;
    text-indent: -9999px;
    cursor: pointer;
    padding: 0;
    color: transparent;
    border: none;
    outline: none;
    background: rgba($colorBlack, 0.5);
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 26px;
    @include transition;
    &:before {
      background-image: url(../images/prev-arrow-slider.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 9px 17px;
      transform: rotate(90deg);
      width: 100%;
      height: 17px;
      margin-top: 4px;
      content: "";
      display: block;
    }
    &.slick-next {
      top: auto;
      bottom: 0;
      &:before {
        background-image: url(../images/next-arrow-slider.png);
      }
    }
    &:hover {
      background: rgba($colorWhite, 0.8);
      &:before {
        background-image: url(../images/blue-slider-arrow.png);
        transform: rotate(90deg);
      }
      &.slick-next {
        &:before {
          transform: rotate(-90deg);
        }
      }
    }
  }

  @include sm {
    width: 100%;
    padding: 58px 0 0;
    box-sizing: border-box;

    .current-video {
      padding: 0 21px;
      width: 100%;
      float: none;
      margin-bottom: 23px;

      iframe {
        width: 100%;
      }
    }

    .item-list {
      padding: 0;
      width: 100%;
      float: none;
      height: 101px;
      background: rgba($colorBlack, 0.2);

      ul {
        height: 100%;
        width: 100%;
        display: inline-block;
      }

      li {
        display: inline-block;
        margin: 0;

        .watch-video {
          margin-bottom: 0;
          display: none;
        }
      }

      &.one-slide {
        display: none;
      }
    }

    .slick-arrow {
      background: rgba($colorBlack, 0.3);
      top: 0;
      left: 0;
      height: 100%;
      width: 70px;

      &:before {
        background-image: url(../images/nav-slider-prev.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        transform: rotate(0deg);
        width: 100%;
        height: 100%;
        margin-top: 0;
        content: "";
        display: block;
      }
      &.slick-next {
        top: 0;
        bottom: auto;
        left: auto;
        right: 0;

        &:before {
          background-image: url(../images/nav-slider-next.png);
        }
      }
      &:hover {
        background: rgba($colorBlack, 0.8);
        &:before {
          background-image: url(../images/nav-slider-prev.png);
          transform: rotate(0deg);
        }
        &.slick-next {
          &:before {
            background-image: url(../images/nav-slider-next.png);
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}

.no-visuel {
  background-image: url(../images/image_default_small.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: calc(100% - 1px);
  height: 188px;

  &.ouvrage-visuels {
    background-position: left top;
    background-size: cover;
    padding-top: 100%;
    background-image: url(../images/image_default.jpg);
    height: auto;
  }
}

@include sm {
  .ouvrage-visuels-secondaires {
    display: none;
  }
  .sticky-addtocart {
    display: none;
    position: fixed;
    height: 105px;
    width: 100%;
    background: $colorWhite;
    border-bottom: 2px solid $colorEbb;
    box-sizing: border-box;
    padding: 0 21px;
    z-index: 100;
    top: 0;

    .flex-wrapper {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 90px;
        width: auto;
        margin-right: 20px;
      }

      .commerce-price {
        font-size: 4.2rem;
        font-weight: bold;
        font-family: $fontMontserrat;
        color: $colorPelorous;

        @include xs {
          font-size: 3rem;
        }
      }

      form {
        margin-left: auto;

        .form-item-quantity,
        .achat-nombre {
          display: none;
        }

        .form-submit {
          border: 0;
          color: $colorWhite;
          background-color: $colorPelorous;
          background-image: url(../images/cart-white-mobile.png);
          background-position: right 44px center;
          padding: 0 96px 0 44px;
          min-width: 0;
          height: 60px;
          font-size: 2.8rem;

          @include xs {
            font-size: 2.4rem;
            background-position: right 34px center;
            padding: 0 84px 0 34px;
          }
        }
      }
    }

    .reserve-enseignant {
      display: none;
    }
  }
}
h1 .addthis_toolbox {
  display: none;

  // @include sm {
  //   display: block;
  //   position: absolute;
  //   right: 21px;
  //   top: 21px;

  //   a {
  //     background-color: $colorEbb;
  //     background-image: url(../images/share-mobile.png);
  //     background-repeat: no-repeat;
  //     background-position: center;
  //     width: 104px !important;
  //     height: 104px;
  //     display: block;
  //     font-size: 0;
  //     margin: 0;
  //     padding: 0 !important;
  //   }

  //   span {
  //     display: none;
  //   }
  // }
}


.tools {
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  flex-wrap: wrap;

  .btn-consulter-wrapper {
    a {
      @include button;
      font-family: $fontMontserrat;
      box-sizing: border-box;
      display: inline-block;
      text-align: left;
      padding: 0 50px 0 10px;
      height: 36px;
      line-height: 32px;
      width: auto;
      background-image: url(../images/glasses-icon.png);
      background-position: right 10px center;
      &:hover {
        background-image: url(../images/glasses-icon-hover.png);
        color: $colorWhite;
      }
    }

    @include sm {
      margin: 18px auto 0;
      width: 353px;

      a {
        text-align: center;
        padding: 0 55px 0 0;
        line-height: 66px;
        height: 70px;
        width: 100%;
        font-size: 2.4rem;
        background-image: url(../images/glasses-icon-mobile.png);
        background-position: right 19px center;
      }
    }
  }

  .share {
    margin-bottom: 10px;

    .title {
      display: inline-flex;
      height: 36px;
      align-items: center;
      background-color: $colorEbb;
      padding: 0 36px 0 14px;
      cursor: pointer;
      font-family: $fontMontserrat;
      color: $colorDoveGray;
      background-image: url(../images/share-icon.png);
      background-position: right 14px center;
      background-repeat: no-repeat;
      box-sizing: border-box;
      width: 131px;
      @include transition;

      &.open {
        color: $colorWhite;
        background-color: $colorPelorous;
        background-image: url(../images/share-icon-white.png);
      }
    }

    .addthis_toolbox {
      display: none;
      position: absolute;
      background-color: $colorWhite;
      z-index: 1;
      max-width: 131px;

      a {
        padding: 0;
        margin: 1px 0.5px;
        font-size: 0;
        width: 32px;
        height: 32px;
        background-color: $colorAluminium;
        background-position: center;
        background-repeat: no-repeat;

        &.addthis_button_facebook {
          background-image: url(../images/share-facebook.png);
        }

        &.addthis_button_twitter {
          background-image: url(../images/share-twitter.png);
        }

        &.addthis_button_pinterest {
          background-image: url(../images/share-pinterest.png);
        }

        &.addthis_button_google_plusone_share {
          background-image: url(../images/share-google.png);
        }

        &.button_email {
          width: 100%;
          margin: 0;
          background-image: url(../images/share-mail.png);
          background-position: right 7px center;
          float: left;
          font-size: 1rem;
          font-weight: 600;
          color: $colorWhite;
          line-height: 32px;
          padding-left: 10px;
          box-sizing: border-box;

          &:before {
            content: "Envoyer par mail";
          }
        }

        span {
          display: none;
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }

    @include sm {
      display: none;
    }
  }
}
