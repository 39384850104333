.front {
  #page-title,
  .title {
    display: none;
  }
}
.field-name-field-home-intertitre-ouv,
.field-name-field-home-ouvrage-princip,
.field-name-field-home-ouvrage-petits,
.field-name-field-home-intertitre-cible,
.field-name-field-home-cible,
.field-name-field-home-promos {
  padding: 0;
}
.field-name-field-description-custom,
.field-name-field-description-footer {
  @extend %container;
  box-sizing: border-box;
  margin-top: 40px;
  @include sm {
    padding: 0 20px;
    margin-bottom: 69px;
  }
}

//Slider
.field-name-field-home-slide-promos {
  color: $colorWhite;
  position: relative;
  font-size: 0;
  .field-items {
    .slick-list {
      width: $lg-width;
      max-width: 100%;
      overflow: visible;
      margin: 0 auto;
      position: relative;
      &:before, &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 9999px;
        background: $colorBostonBlue;
        opacity: 0.2;
        z-index: 1;
        right: 100%;
      }
      &:after {
        background: $colorShark;
        opacity: 0.8;
        right: auto;
        left: 100%;
      }
    }
    .slick-slide {
      position: relative;
      overflow: hidden;
      float: left;
      height: 100%;
      min-height: 1px;
      background: $colorBlack;
      @include transition;
      a .field-item {
        color: $colorWhite;
      }
      .field-name-field-slide-promo-visuel {
        position: relative;
        img {
          max-width: 100vw;
        }
        &:before {
          position: absolute;
          width: 440px;
          background: rgba(255,255,255,0.1);
          top: 0;
          left: 0;
          height: 300%;
          content: "";
          display: block;
          @include sm {
            width: 300px;
          }
        }
        &:after {
          position: absolute;
          top: 0;
          left: 440px;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 454px 0 0 167px;
          border-color: transparent transparent transparent rgba(255,255,255,0.1);
          @include transition;
          @include sm {
            left: 300px;
          }
        }
      }
      .field-name-field-slide-promo-thematique {
        position: absolute;
        top: 130px;
        left: 100px;
        text-transform: uppercase;
        background: $colorRed;
        font-weight: bold;
        font-size: 1.4rem;
        padding: 5px;
        line-height: 1.2;
        @include sm {
          top: 0;
          left: 0;
        }
      }
      .field-name-field-slide-promo-titre {
        position: absolute;
        top: 165px;
        left: 100px;
        font-size: 3.2rem;
        font-weight: bold;
        text-transform: uppercase;
        font-family: $fontMontserrat;
        @include sm {
          position: relative;
          font-size: 3.5rem;
          line-height: 4rem;
          margin: 20px;
          width: 50%;
          float: left;
          overflow: hidden;
          height: 8rem;
          top: 0;
          left: 0;
        }
      }
      .field-name-field-slide-promo-accroche {
        position: absolute;
        top: 200px;
        left: 100px;
        font-size: 2.6rem;
        font-weight: 300;
        max-width: 350px;
        @include sm {
          display: none;
        }
      }
      .field-name-field-slide-promo-cta {
        .field-items {
          @include cta-gradient(0, url(../images/slider-link-arrow.png) right 10px center no-repeat, $colorBostonBlue);
          position: absolute;
          top: 290px;
          left: 100px;
          font-family: $fontMontserrat;
          width: 200px;
          border:2px solid $colorWhite;
          text-align: center;
          line-height:40px;
          font-size: 1.6rem;
          .field-item {
            position: relative;
            z-index: 1;
            cursor: pointer;
          }
          @include sm {
            top: auto;
            left: auto;
            right: 20px;
            bottom: 25px;
            font-size: 3.4rem;
            width: 320px;
            line-height: 53px;
          }
        }
      }
      &:hover {
        .field-name-field-slide-promo-visuel {
          &:after {
            border-width: 454px 0 0 0;
          }
        }
      }
    }
  }
  .slick-track {
    @extend .clearfix;
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(((100% - #{$lg-width}) / 2));
    z-index: 1;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    padding: 0;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    width: 60px;
    height: 200px;
    &:before {
      background: url(../images/prev-arrow-slider.png) center center no-repeat;
      width: 60px;
      height: 200px;
      content: "";
      display: block;
    }
    &.slick-next {
      left: auto;
      right: calc(((100% - #{$lg-width}) / 2));
      &:before {
        background: url(../images/next-arrow-slider.png) center center no-repeat;
      }
    }
    @include md {
      left: 25px;
      &.slick-next {
        left: auto;
        right: 25px;
      }
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 40px;
    left: calc(((100% - #{$lg-width}) / 2) + 100px);
    text-align: initial;

    li {
      display: inline-block;
      button {
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        padding: 0;
        color: transparent;
        outline: none;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: rgba(255,255,255,0.3);
        border: 2px solid transparent;
        margin-right: 19px;
        @include transition;

        &::before {
          display: none;
        }
      }

      &.slick-active, &:hover {
        button {
          background: transparent;
          border-color: $colorRed;
        }
      }
    }
  }
}

//Titles
.field-name-field-home-intertitre-ouv .field-item,
.field-name-field-home-intertitre-cible .field-item {
  @include sectionTitle;
  font-size: 4.2rem;

  @include sm {
    font-size: 5.2rem;
  }
}

//Nos meilleures ventes
.field-name-field-home-ouvrage-princip  {
  background: $colorWhite;
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  color: $colorBlack;
  @include clearfix;
  @include sm {
    margin-bottom: 28px;
  }
  > .field-items {
    @include diagonalBg;
    @include sm {
      @include diagonalBg($direction: vertical);
      &:hover {
        &:before {
          right: -50%;
        }
      }
    }
    > .field-item {
      width: 100%;
      padding: 25px;
      @include sm {
        width: auto;
      }
    }
    > * {
      position: relative;
      z-index: 1;
    }
  }
  .field-name-field-home-ouvrage-princip-titre,
  h3,
  .field-name-field-home-ouvrage-princip-cta {
    float: left;
    margin-left: 90px;
    @include sm {
      margin-left: 0;
      width: 100%;
    }
  }
  .field-name-field-home-ouvrage-princip-visu {
    width: 50%;
    float: left;
    text-align: right;
    img {
      display: inline-block;
    }
    @include sm {
      width: 100%;
      text-align: center;
    }
  }
  .field-name-field-home-ouvrage-princip-titre {
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 3.2rem;
    max-width: 350px;
    line-height: 3.6rem;
    min-height: 7.2rem;
    text-transform: uppercase;
    margin-top: 20px;
    &:after {
      @include seperatorLine($width: 57px, $margin: 13px 0 15px);
    }
    @include sm {
      font-size: 4.2rem;
      line-height: 1;
      min-height: auto;
      max-width: 100%;
      &:after {
        @include seperatorLine($width: 104px, $margin: 13px 0 15px, $height: 2px);
      }
    }
  }
  h3 {
    font-size: 1.8rem;
    max-width: 350px;
    .field-name-field-home-ouvrage-princip-acc-1 {
      font-weight: bold;
      margin-bottom: 5px;
    }
    @include sm {
      max-width: 100%;
      font-size: 2.8rem;
      .field-name-field-home-ouvrage-princip-acc-2 {
        display: none;
      }
    }
  }
  .field-name-field-home-ouvrage-princip-cta {
    margin-top: 30px;
    .field-items {
      @include cta-gradient(2px, transparent, $colorBostonBlue);
      background: url(../images/sm-blue-next-arrow.png) right 13px center no-repeat;
      width: 160px;
      line-height: 36px;
      color: $colorPelorous;
      text-align: center;
      &:hover {
        color: $colorWhite;
        background-image: url(../images/sm-white-next-arrow.png);
      }
      .field-item {
        position: relative;
      }
      @include sm {
        width: 248px;
        line-height: 60px;
        font-size: 3.1rem;
        background: url(../images/lg-blue-next-arrow.png) right 20px center no-repeat;
        padding-right: 20px;
        &:hover {
          background-image: url(../images/lg-white-next-arrow.png);
        }
      }
    }
  }
}
.field-name-field-home-ouvrage-petits {
  margin-bottom: 65px;
  @include clearfix;
  @include sm {
    margin-bottom: 60px;
  }
  > .field-items {
    @include clearfix;
    margin: 0 -5px;
    @include sm {
      margin: 0;
    }
    > .field-item {
      box-sizing: border-box;
      @extend .col-sm-4;
      padding: 0 5px;
      color: $colorBlack;
      @include sm {
        width: 100%;
        padding: 0;
        margin-bottom: 35px;
      }
      > .field-collection-item-field-home-ouvrage-petits {
        background: $colorWhite;
        position: relative;
        overflow: hidden;
        @include diagonalBg($direction: vertical);
        z-index: 0;
        @include sm {
          @include diagonalBg($direction: horizontal, $reverse: 1);
          &:before {
            background: $colorSwissCoffee;
          }
        }
        & > .content {
          padding: 25px;
          @include sm {
            padding: 25px 0;
            @include clearfix;
          }
          & > a {
            position: relative;
            line-height: 0;
            z-index: 1;
            @include sm {
              padding: 25px 0;
              @include clearfix;
            }
          }
        }
      }
      .field-name-field-home-ouvrage-petit-visuel {
        margin-bottom: 15px;
        text-align: center;
        img {
          display: inline-block;
        }
        @include sm {
          margin-bottom: 0;
          width: 42%;
          float: left;
          padding: 20px;
          position: relative;
          box-sizing: border-box;
        }
      }
      .field-name-field-home-ouvrage-petit-format {
        margin-bottom: 15px;
        .format {
          display: none;
          font-size: 2.8rem;
          color: $colorPelorous2;
          line-height: 1;
          margin-left: 20px;
          @include sm {
            display: inline-block;
          }
        }
        @include sm {
          box-sizing: border-box;
          width: 58%;
          float: left;
          padding: 0 20px;
          img {
            display: inline-block;
          }
        }
      }
      .field-name-field-home-ouvrage-petit-titre {
        h2 {
          font-size: 2.2rem;
          text-transform: uppercase;
          font-family: $fontMontserrat;
          font-weight: bold;
          line-height: 3rem;
          min-height: 6rem;
        }
        &:after {
          @include seperatorLine;
        }
        @include sm {
          box-sizing: border-box;
          width: 58%;
          float: left;
          padding: 0 20px;
          h2 {
            font-size: 3.2rem;
          }
          &:after {
            @include seperatorLine($width: 89px, $height: 2px, $margin: 20px 0);
          }
        }
      }
      .field-name-field-home-ouvrage-petit-acc {
        font-size: 1.5rem;
        line-height: 2rem;
        min-height: 4rem;
        margin-bottom: 42px;
        @include sm {
          display: none;
        }
      }
      .field-name-field-home-ouvrage-petit-prix {
        font-size: 3.3rem;
        line-height: 1;
        color: $colorPelorous2;
        font-family: $fontMontserrat;
        font-weight: bold;
        min-height: 4rem;
        margin-bottom: 42px;
        display: none;
        @include sm {
          margin-bottom: 50px;
          font-size: 4.3rem;
          box-sizing: border-box;
          display: block;
          width: 58%;
          float: left;
          padding: 0 20px;
        }
      }
      .field-name-field-home-ouvrage-petit-cta {
        text-align: right;
        .field-items {
          @include cta-gradient(2px, transparent, $colorBostonBlue);
          background: url(../images/sm-blue-next-arrow.png) right 13px center no-repeat;
          width: 160px;
          line-height: 36px;
          color: $colorPelorous;
          text-align: center;
          display: inline-block;
          &:hover {
            color: $colorWhite;
            background-image: url(../images/sm-white-next-arrow.png);
          }
          .field-item {
            position: relative;
          }
        }
        @include sm {
          float: right;
          font-size: 3.1rem;
          margin-right: 20px;
          .field-items {
            background: url(../images/lg-blue-next-arrow.png) right 20px center no-repeat;
            width: 260px;
            line-height: 60px;
            padding-right: 20px;
            &:hover {
              background-image: url(../images/lg-white-next-arrow.png);
            }
          }
        }
      }
      &:hover {
        .field-name-field-home-ouvrage-petit-prix {
          display: block;
        }
        .field-name-field-home-ouvrage-petit-acc {
          display: none;
        }
      }
    }
  }
}

//Vous etes segment
.field-name-field-home-intertitre-cible,
.field-name-field-home-cible {
  @include fullWidthBackground($colorWhite);
}
.field-name-field-home-cible {
  padding-bottom: 28px;
  margin-bottom: 50px;
  @include sm {
    padding-bottom: 0;
    margin-bottom: 75px;
  }
  > .field-items {
    margin: 0 -5px;
    @include sm {
      margin: 0 11px;
    }
    @include clearfix;
    > .field-item {
      @extend .col-sm-3;
      padding: 0 5px;
      box-sizing: border-box;
      @include sm {
        width: 50%;
        padding: 0 10px 47px;
        float: left;
      }
      &:hover {
        .field-name-field-home-cible-visuel {
          img {
            transform: scale(1.15);
          }
        }
        .field-name-field-home-cible-titre {
          &:before {
            width: 100%;
          }
        }
        .field-name-field-home-cible-accroche {
          opacity: 1;
        }
      }
    }
  }
  .field-name-field-home-cible-visuel {
    overflow: hidden;
    position: relative;
    font-size: 0;
    img {
      @include transition;
    }
    @include sm {
      picture, img {
        width: 100%;
        height: auto;
      }
    }
  }
  .field-name-field-home-cible-titre {
    font-family: $fontMontserrat;
    font-size: 1.8rem;
    text-transform: uppercase;
    &:before {
      @include seperatorLine($width: 73px, $height: 3px, $margin: 17px 0 13px);
      @include transition;
    }
    @include sm {
      font-size: 2.8rem;
      &:before {
        width: 173px;
        height: 6px;
        margin: 22px 0 21px;
      }
    }
  }
  .field-name-field-home-cible-accroche {
    opacity: 0;
    font-family: $fontMontserrat;
    color: $colorPelorous2;
    @include transition;
    @include sm {
      display: none;
    }
  }
}
.field-name-field-home-promos {
  > .field-items {
    margin:0 -5px;
    @include clearfix;
    @include sm {
      margin: 0;
    }
    > .field-item {
      box-sizing: border-box;
      @extend .col-sm-6;
      padding: 0 5px;
      position: relative;
      @include sm {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 39px;
      }
    }
  }
  .field-name-field-home-promo-visuel {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
    @include sm {
      picture, img {
        width: 100%;
      }
    }
  }
  .field-name-field-home-promo-titre {
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 2.6rem;
    text-transform: uppercase;
    max-width: 350px;
    color: $colorBlack;
    position: absolute;
    bottom: 145px;
    left: 27px;
    @include md {
      max-width: 58%;
      bottom: 120px;
    }
    @include sm {
      bottom: auto;
      left: 40px;
      top: 35px;
      font-size: 3.6rem;
    }
  }
  .field-name-field-home-promo-accroche {
    font-size: 2rem;
    font-weight: 300;
    max-width: 350px;
    position: absolute;
    bottom: 80px;
    left: 27px;
    @include md {
      max-width: 58%;
      bottom: 70px;
    }
    @include sm {
      display: none;
    }
  }
  .field-name-field-home-promo-cta {
    position: absolute;
    bottom: 20px;
    left: 27px;
    .field-items {
      @include cta-gradient(0, transparent, $colorBostonBlue);
      background: $colorWhite url(../images/sm-blue-next-arrow.png) right 13px center no-repeat;
      width: 160px;
      line-height: 36px;
      color: $colorPelorous;
      text-align: center;
      &:hover {
        color: $colorWhite;
        background-image: url(../images/sm-white-next-arrow.png);
      }
      .field-item {
        position: relative;
      }
    }
    @include sm {
      left: 40px;
      bottom: 31px;
      font-size: 3.1rem;
      .field-items {
        background: $colorWhite url(../images/lg-blue-next-arrow.png) right 10px center no-repeat;
        width: 264px;
        line-height: 60px;
        &:hover {
          background-image: url(../images/lg-white-next-arrow.png);
        }
        .field-item {
          position: relative;
        }
      }
    }
  }
}
