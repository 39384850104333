.cta-gradient {
  @include cta-gradient(0);
}

.cta-border-gradient {
  @include cta-gradient();
}

.cta-background-gradient {
  @include cta-gradient(0, $colorWhite);
}
