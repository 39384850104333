.page-recherche {
  #main-wrapper {
    @include sm {
      z-index: 2;
    }
  }

  #sidebar-first {
    float: left;
    width: 25%;

    #selected-filters {
      display: none;
    }

    @include sm {
      display: none;
      float: none;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      max-height: 100%;
      overflow-y: auto;
      background: $colorWhite;
      box-sizing: border-box;
      height: 100vh;

      #selected-filters {
        display: block;
        border-bottom: 2px solid $colorAlto;
        padding: 15px 21px 4px;

        li {
          padding: 0;
          border: 0;
          position: relative;
          display: inline-block;
          background-color: $colorAlto;
          padding: 21px 90px 21px 21px;
          margin-right: 15px;
          margin-bottom: 15px;
          font-size: 2.8rem;
          font-weight: bold;
          min-width: 224px;
          box-sizing: border-box;

          a {
            width: 90px;
            height: 100%;
            background-image: url(../images/mobile-times-white.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto;
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
          }
        }

        &:empty {
          border-bottom: 0;
          padding: 0;
        }
      }
    }

    .region-sidebar-first {
      > div {
        background-color: $colorWhite;
        box-sizing: border-box;
        margin-bottom: 10px;

        @include sm {
          margin-bottom: 0;
          border-bottom: 2px solid $colorWhite;
        }
      }
    }

    ul {
      margin-bottom: 0;
    }

    .subject {
      font-family: $fontMontserrat;
      color: $colorBlack;
      padding: 9px 10px 0;
      text-transform: uppercase;

      &:after {
        @include seperatorLine($margin: 7px 0 0);
      }

      @include sm {
        background-color: $colorPelorous3;
        color: $colorWhite;
        font-weight: bold;
        padding: 0 21px;
        font-size: 3rem;
        line-height: 70px;
        position: relative;

        &:before {
          content: "";
          background-image: url(../images/lg-white-next-arrow.png);
          background-repeat: no-repeat;
          width: 13px;
          height: 23px;
          display: block;
          position: absolute;
          right: 33px;
          top: 50%;
          margin-top: -11.5px;
          @include transition;
        }

        &:after {
          display: none;
        }
        &.open {
          &:before {
            transform: rotate(90deg);
          }
        }
      }
    }

    .content,
    form {
      @include sm {
        display: none;
      }
    }

    #block-lerobert-block-lerobert-search-price-filter {
      @include sm {
        .content {
          display: block;
        }
      }
    }

    li {
      background: none;
      padding: 0 10px;
      font-weight: bold;

      @include sm {
        padding: 6px 21px;
        border-bottom: 1px solid $colorSilver;
        font-family: $fontMontserrat;
      }

      ul.expanded {
        padding: 5px 0;
        li {
          padding: 0 10px 0 0;
        }

        @include sm {
          padding: 0;
        }
      }

      .facetapi-inactive {
        display: inline-block;
        margin-bottom: 0;
        padding-left: 24px;
        line-height: 16px;
        position: relative;
        font-weight: normal;

        @include sm {
          font-size: 2.8rem;
          line-height: 56px;
          padding-left: 77px;
          font-family: $fontMontserrat;
          color: $colorBlack;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 16px;
          height: 16px;
          background-image: url('../images/checkbox-sprite.png');
          background-repeat: no-repeat;

          @include sm {
            width: 56px;
            height: 56px;
            background-image: url('../images/search-mobile-checkbox-sprite.png');
          }
        }

        &:hover {
          &:before {
            background-position: -32px 0px;

            @include sm {
              background-position: -56px 0px;
            }
          }
        }
      }

      .facetapi-active {
        display: inline-block !important;
        text-indent: -999px;
        background-image: url('../images/checkbox-sprite.png');
        background-repeat: no-repeat;
        background-position: -16px 0px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        transition: 0s;
        &:hover {
          background-position: -48px 0px;
        }

        @include sm {
          width: 56px;
          height: 56px;
          background-image: url('../images/search-mobile-checkbox-sprite.png');
          background-position: -56px 0px;
          line-height: 58px;

          &:hover {
            background-position: -56px 0px;
          }
        }
      }
    }

    ul > li {
      margin-bottom: 3px;
      position: relative;

      @include sm {
        margin-bottom: 0;
      }

      &.collapsed > a {
        background-image: url(../images/search-plus-icon.png);
        background-position: right center;
        background-repeat: no-repeat;
        width: 100%;
        line-height: 22px;
        padding-right: 24px;
        box-sizing: border-box;

        &:before {
          margin-top: 3px;
        }

        &:hover {
          background-image: url(../images/search-plus-icon-hover.png);
        }

        @include sm {
          background-image: url(../images/mobile-plus-icon.png);
          line-height: 56px;
          padding-right: 21px;

          &:hover {
            background-image: url(../images/mobile-plus-icon.png);
          }

          &:before {
            margin-top: 0;
          }
        }
      }

      &.expanded {
        padding: 0 34px;

         > a {
          background-image: url(../images/search-minus-icon.png);
          background-position: right center;
          background-repeat: no-repeat;
          width: calc(100% - 44px);
          height: 22px;
          padding-right: 24px;
          box-sizing: border-box;
          position: absolute;

          &:before {
            content: "";
            display: block;
            background-image: url('../images/checkbox-sprite.png');
            background-repeat: no-repeat;
            background-position: -16px 0px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            transition: 0s;
            left: -24px;
            top: 3px;
            position: absolute;
          }

          &:hover {
            background-image: url(../images/search-minus-icon-hover.png);
            background-position: right center;
            &:before {
              background-position: -48px 0px;
            }
          }
        }

        @include sm {
          padding: 16px 21px 0px 98px;
          box-sizing: border-box;

          > a {
           background-image: url(../images/mobile-minus-icon.png);
           width: calc(100% - 120px);
           height: 60px;
           padding-right: 0;
           margin-top: -12px;

           &:before {
             width: 56px;
             height: 56px;
             background-image: url('../images/search-mobile-checkbox-sprite.png');
             background-position: -56px 0px;
             left: -77px;
           }

           &:hover {
             background-image: url(../images/mobile-minus-icon.png);
             background-position: right center;
             &:before {
               background-position: -56px 0px;
             }
           }
         }

         > .item-list {
           margin-top: 18px;

           li {
             border: 0;
             margin-bottom: 5px;
           }
         }
       }
     }
   }

    .block-facetapi:first-of-type,
    #block-lerobert-block-lerobert-separateur-affiner {
      .subject {
        background-color: $colorPelorous3;
        color: $colorWhite;
        font-size: 2rem;
        font-weight: bold;
        line-height: 55px;
        padding-top: 0;

        &:after {
          display: none;
        }

        @include sm {
          font-size: 3rem;
          line-height: 70px;
        }
      }

      .content {
        padding-bottom: 0;
      }
    }

    #block-lerobert-block-lerobert-separateur-affiner {
      @include sm {
        display: none;
      }
    }

    .block-facetapi:first-of-type {
      ul#facetapi-facet-apachesolrsolr-block-im-field-categories {
        li {
          font-family: $fontMontserrat;
          font-size: 1.4rem;
          color: $colorBlack;
          font-weight: normal;

          @include sm {
            font-size: 2.8rem;
            line-height: 69px;
            border-bottom: 1px solid $colorSilver;

            &:last-of-type {
              border-bottom: 0;
            }
          }

          @include transition(color);

          &:hover {
            color: $colorPelorous;

            @include sm {
              color: inherit;
            }
          }

          a {
            display: block;
          }
          .facetapi-inactive,
          .facetapi-active {
            padding-left: 0;
            line-height: inherit;
            text-indent: 0;
            width: auto;
            &:before {
              display: none;
            }
          }
        }

        > li {
          padding: 0;

          &:last-of-type {
            > a,
            > .facetapi-active {
              border-bottom: 0;
            }
          }

          &:only-of-type {
            > a {
              border-bottom: 1px solid $colorSilver;
            }
          }
        }

        > li {
          position: relative;
          line-height: 55px;
          padding: 0 11px 0 10px;
          overflow: hidden;
          font-size: 1.6rem;

          @include sm {
            font-size: 2.8rem;
            line-height: 69px;
            padding: 0 21px 0 80px;

            &.expanded {
              background-image: linear-gradient($colorEbb 70px, $colorWhite 70px);
            }

            > .item-list {
              margin: 0 -21px 0 -80px;
            }
          }

          > a.facetapi-active {
            background-image: url(../images/cart-delete.png);
            background-position: right 11px center;
            background-size: 19px 19px;
            background-repeat: no-repeat;
            padding: 18px 10px;
            position: absolute;
            z-index: 1;
            height: 55px;
            box-sizing: border-box;
            width: 100%;
            left: 0;
            text-indent: -999px;

            &:hover {
              background-image: url(../images/cart-delete-hover.png);
            }

            @include sm {
              height: 70px;
              font-weight: bold;
              background-image: url(../images/mobile-minus-icon.png);
              background-position: right 21px center;
              background-size: auto;

              &:hover {
                background-image: url(../images/mobile-minus-icon.png);
              }
            }
          }

          .item-list > ul {
            li {
              background-image: url(../images/sm-blue-next-arrow.png);
              background-position: left 5px center;
              background-repeat: no-repeat;
              padding: 5px 0 5px 17px;

              a.facetapi-active {
                background-image: url(../images/cart-delete.png);
                background-position: center;
                background-size: 19px 19px;
                background-repeat: no-repeat;
                float: right;
                overflow: hidden;
                width: 19px;
                height: 19px;
                text-indent: -999px;

                &:hover {
                  background-image: url(../images/cart-delete-hover.png);
                }
              }

              @include sm {
                background-image: none;
                padding: 0;
                padding: 0 21px 0 80px;

                a.facetapi-active {
                  background-image: none;
                }
              }
            }
          }

          & + li {
            border-top: 1px solid $colorSilver;
          }
        }
        > li.expanded {
          > a {
            border-bottom: 1px solid $colorSilver;
            margin-top: 0;
          }

          &:hover {
            color: $colorBlack;
          }
        }
      }
    }

    .block-facetapi {
      .content {
        padding-bottom: 20px;
      }

      .facetapi-limit-link {
        @include button-diagonal;
        background-color: $colorPelorous2;
        border: 0;
        display: block;
        margin: 10px auto 0;
        color: $colorWhite;
        font-family: $defaultFont;
        font-size: 1.8rem;
        width: 75%;
        height: 35px;
        line-height: 35px;

        @include sm {
          height: 70px;
          line-height: 70px;
          width: 50%;
          background-color: $colorTuna;
          font-family: $fontMontserrat;
          font-size: 2.8rem;
          margin-top: 20px;

          &:hover {
            color: $colorWhite;
          }
        }
      }
    }
  }

  #lerobert-search-price-between-form {
    padding: 13px 10px 11px;

    > div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include sm {
        display: block;
      }
    }

    .form-item {
      display: inline-block;
      font-family: $fontMontserrat;
      font-size: 1.4rem;
      color: $colorBlack;

      @include sm {
        display: none;
      }
    }

    .form-wrapper {
      width: 100%;
    }

    .form-item-min-price,
    .form-item-max-price {
      width: 80px;
      position: relative;

      &:after {
        color: $colorPelorous;
        font-size: 2rem;
        font-weight: bold;
        position: absolute;
        bottom: -2px;
        right: 0;
        content: "€";
      }

      input[type="number"] {
        font-family: $fontMontserrat;
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        &.error {
          border: 2px solid $colorSunsetOrange;
        }
      }
    }

    input:not([type="submit"]) {
      color: $colorPelorous;
      font-size: 2rem;
      font-weight: bold;
      height: 22px;
      margin-top: -5px;
      border: 0;
      padding: 0 21px 0 0;
      text-align: right;
      border-bottom: 1px solid $colorPelorous;
      &:focus {
        outline: none;
        border-bottom: 1px solid $colorBlack;
      }
    }

    .form-actions {
      @include cta-gradient(0, $colorPelorous, $colorWhite);
      width: 100%;
      height: 35px;
      margin-top: 18px;

      @include sm {
        display: block;
        margin: 60px auto 0;
        background: transparent;
        text-align: center;
        height: 70px;
        width: 420px;
      }
    }

    input[type="submit"] {
      background-image: url(../images/white-check.png);
      background-size: 19px 14px;
      background-position: right 75px center;
      padding: 0 19px 0 0;
      border: 0;
      color: $colorWhite;
      font-family: $defaultFont;
      font-size: 1.8rem;
      width: 100%;
      height: 35px;
      position: relative;
      z-index: 1;
      &:hover {
        background-image: url(../images/white-check.png);
        background-color: transparent;
      }

      @include md {
        background-position: right 10px center;
        padding: 0;
      }

      @include sm {
        width: 420px;
        background-image: url(../images/check-white-mobile.png);
        background-size: auto;
        background-position: right 30px center;
        padding: 0;
        background-color: $colorTuna;
        font-size: 2.8rem;
        font-family: $fontMontserrat;
        height: 70px;

        &:hover {
          background-image: url(../images/check-white-mobile.png);
          background-color: transparent;
        }
      }
    }
  }

  .facetapi-facetapi-checkbox-links {
    padding: 8px 0 0 0;

    @include sm {
      padding: 0;
    }
  }

  h1.title {
    margin-bottom: 0;
    padding-bottom: 39px;

    &:after {
      margin-bottom: 0;
    }
  }

  &.page-page_hub h1.title {
    padding-bottom: 0;
  }

  .region-main-prefix {
    background-color: $colorWhite;
    font-weight: 300;
    line-height: 2.6rem;
    padding-bottom: 39px;
    @include fullWidthBackground($colorWhite);

    .field-type-text-long {
      width: 75%;
    }
    .field-type-image {
      margin-top: 25px;
    }

    @include sm {
      padding: 25px 21px 45px;
      box-sizing: border-box;
      font-size: 2.8rem;
      line-height: 1.2;

      .field-type-text-long {
        width: 100%;
      }

      .field-type-image {
        display: none;
      }
    }
  }

  #block-lerobert-block-lerobert-description-page-hub {
    border-bottom: 1px solid $colorSwissCoffee;
    padding: 28px 0;

    @include sm {
      margin: 0 21px;
    }

    h2, h3, h4, h5, h6 {
      font: inherit;
      font-weight: bold;
      margin-bottom: 18px;
      text-transform: inherit;

      &:after {
        display: none;
      }
    }
  }

  #main-content .region-content {
    float: right;
    width: 75%;
    box-sizing: border-box;
    padding-left: 10px;

    @include sm {
      float: none;
      width: 100%;
      padding-left: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      padding-top: 57px;
      height: 1px;
      // border-bottom: 1px solid $colorSwissCoffee;

      @include sm {
        display: none;
      }
    }

    .results {
      display: grid;
      grid-template-columns: repeat(auto-fill, 28rem);
      gap: 1rem;
      margin-top: 2.6rem;
      margin-bottom: 3rem;
      @include clearfix;

      &::before {
        display: none;
      }

      .node-auteur-search-result {
        grid-column: 1/-1;
      }

      @include sm {
        padding-bottom: 65px;
      }

      .field-name-field-promo-visuel {
        font-size: 0;
        img {
          height: 420.5px;
          object-fit: fill;
        }

        @include sm {
          text-align: center;
        }
      }
    }
    .content {
      > .item-list {
        float: left;
        width: 66.66%;
        height: 33px;

        @include md {
          width: auto;
        }

        @include sm {
          width: 100%;
          float: none;
          box-sizing: border-box;
          padding: 0 21px;
          height: auto;
          margin: 0 0 75px;
        }
      }
    }
  }

  .node-ouvrage-search-result,
  .entity-bloc-promo {
    width: 100%;

    > .content {
      @include min($breakpoint-md-width) {
        min-height: 422px;
      }
    }
  }

  #block-current-search-standard {
    display: none;

    @include sm {
      display: block;

      a {
        display: none;
      }
    }
  }

  .search-top {
    margin-top: 39px;
    @include clearfix;
    color: $colorBlack;
    font-family: $fontMontserrat;

    .count {
      font-size: 2.5rem;
      text-transform: uppercase;
      float: left;

      &:after {
        @include seperatorLine($width: 57px);
      }

      @include sm {
        float: right;
        width: 50%;
        padding-left: 21px;
        box-sizing: border-box;
        font-size: 3.5rem;

        &:after {
          width: 57px;
          height: 2px;
        }
      }
    }
    #mobile-text-filters {
      display: none;

      @include sm {
        display: block;
        float: left;
        width: 50%;
        padding-left: 21px;
        box-sizing: border-box;
      }
    }
  }

  .pager-bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.2rem 0 2.2rem 8rem;
    border-top: 0.1rem solid $colorSwissCoffee;
    border-bottom: 0.1rem solid $colorSwissCoffee;

    @include sm {
      border-bottom: none;
    }

    .item-list {
      order: -1;
    }
  }

  .search-bottom {
    float: right;
    width: 33.33%;
    height: 33px;

    @include md {
      width: auto;
    }

    @include sm {
      display: none;
    }
  }

  .node-ouvrage-search-result {
    &.has-abonnement-mensuel {
      .form-submit {
        // Il faut aller sur la fiche produit pour choisir un abonnement.
        visibility: hidden;
      }
    }
    > .content .field-name-field-ouvrage-image.no-visuel {
      min-height: 188px;
    }
  }

  #mobile-filters,
  .pop-header {
    display: none;
  }

  @include sm {
    #mobile-filters {
      display: block;
      border-top: 2px solid $colorAlto;
      border-bottom: 2px solid $colorAlto;
      font-size: 0;
      background-color: $colorWhite;

      .filters {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
        color: $colorPelorous;
        font-family: $fontMontserrat;
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        line-height: 68px;
        cursor: pointer;

        &.affiner {
          border-right: 1px solid $colorAlto;
        }

        &.triepar {
          border-left: 1px solid $colorAlto;
        }
      }
    }

    .pop-header {
      padding: 0 21px;
      display: flex;
      align-items: center;
      height: 97px;
      border-bottom: 2px solid $colorAlto;
    }

    .pop-title {
      text-transform: uppercase;
      color: $colorPelorous;
      font-size: 3rem;
      font-family: $fontMontserrat;
      font-weight: bold;
    }

    .pop-fermer {
      display: block;
      width: 167px;
      color: $colorBlack;
      border: 2px solid $colorBlack;
      line-height: 66px;
      font-family: $fontMontserrat;
      text-align: center;
      font-size: 2.4rem;
      margin-left: auto;
    }

    #pop-trierpar {
      background: $colorWhite;
      position: relative;
      z-index: 99;
      height: 100vh;
      display: none;

      #trierpar-options {
        a {
          border-bottom: 2px solid $colorAlto;
          display: block;
          line-height: 68px;
          font-family: $fontMontserrat;
          font-size: 3rem;
          padding: 0 21px;

          &.selected {
            background-color: $colorEbb;
            font-weight: bold;
          }
        }
      }
    }
  }

  &.no-sidebars {
    #main-content {
      .region-content {
        float: none;
        width: 100%;
        padding-left: 0;
        margin-top: 40px;

        &:after {
          display: none;
        }

        @include sm {
          padding: 0 21px;
          margin-bottom: 40px;
        }
      }
    }
  }

  .no-result {
    padding-top: 20px;
  }
}

#lerobert-search-order-form,
#lerobert-search-limit-form {
  float: right;

  label {
    font-size: 1.4rem;
    color: $colorBlack;
    font-family: $fontMontserrat;
  }

  .form-actions {
    display: none;
  }

  @include sm {
    display: none;
  }
}

#lerobert-search-order-form {
  select {
    width: 188px;
  }

  label {
    margin-right: 6px;
  }
}

#lerobert-search-limit-form {
  select {
    width: 99px;
  }

  label {
    margin-right: 18px;
  }
}

ul.pager {
  margin-bottom: 0 !important;
  font-size: 0;

  @include md {
    padding-left: 0;
  }

  li {
    background-image: none !important;
    padding-left: 0 !important;
    display: inline-block;
    font-size: 1.4rem;
    margin: 0 5px;
    font-family: $fontMontserrat;

    @include sm {
      font-size: 2.4rem;
      margin: 0 26px;
      vertical-align: middle;
    }

    &.pager-ellipsis,
    &.pager-first,
    &.pager-last {
        display: none;
    }

    &.pager-previous,
    &.pager-next {
      color: $colorPelorous;
      &:hover {
        color: $colorShark3;
      }

      @include sm {
        color:transparent;
        font-size: 0;

        &:hover {
          color: transparent;
        }
      }
    }

    &.pager-previous {
      margin-right: 18px;
      a {
        &:before {
          content: "";
          display: inline-block;
          background-image: url(../images/sm-blue-next-arrow.png);
          background-position: center;
          background-repeat: no-repeat;
          width: 5px;
          height: 10px;
          margin-right: 10px;
          transform: rotate(180deg);
        }
        &:hover {
          &:before {
            background-image: url(../images/sm-dark-next-arrow.png);
          }
        }

        @include sm {
          margin-right: 30px;

          &:before {
            background-image: url(../images/pager-prev-mobile.png);
            width: 26px;
            height: 53px;
            margin-right: 0;
            transform: none;
          }
          &:hover {
            &:before {
              background-image: url(../images/pager-prev-mobile.png);
            }
          }
        }
      }
    }

    &.pager-next {
      margin-left: 18px;
      a {
        &:after {
          content: "";
          display: inline-block;
          background-image: url(../images/sm-blue-next-arrow.png);
          background-position: center;
          background-repeat: no-repeat;
          width: 5px;
          height: 10px;
          margin-left: 10px;
        }
        &:hover {
          &:after {
            background-image: url(../images/sm-dark-next-arrow.png);
          }
        }

        @include sm {
          margin-left: 30px;

          &:after {
            background-image: url(../images/pager-next-mobile.png);
            width: 26px;
            height: 53px;
            margin-left: 0;
          }
          &:hover {
            &:after {
              background-image: url(../images/pager-next-mobile.png);
            }
          }
        }
      }
    }

    &.pager-item a,
    &.pager-current {
      background-color: $colorWhite;
      color: $colorBlack;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      @include transition;

      @include sm {
        width: 70px;
        height: 70px;
      }
    }

    &.pager-item:hover {
      a {
        background-color: $colorAlto;
      }
    }

    &.pager-current {
        background-color: $colorPelorous;
        color: $colorWhite;
    }
  }

  @include sm {
    margin-top: 0;
    padding: 31px 0 39px;
    text-align: center;
    border-bottom: 1px solid $colorSwissCoffee;
    border-top: 1px solid $colorSwissCoffee;
  }
}
.facetapi-checkbox {
  @include sm {
    margin-right: 21px;
  }
}

body.no-scroll {
  position: fixed;
  height: 100%;
  width: 100%;
}

.slider-container {
  width: 600px;
  margin: 150px auto 210px;
  display: none;

  @include sm {
    display: block;
  }

  .back-bar {
    height: 14px;
    position: relative;
    background-color: #e5e5e5;
    border-radius: 7px;

    .selected-bar {
      position: absolute;
      height: 100%;
      background-color: #5CC5BD;
    }

    .pointer {
      position: absolute;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 5px solid $colorWhite;
      background-color: $colorPelorous;
      cursor: col-resize;
      opacity: 1;
      z-index: 2;
      margin-top: -22px;

      &.last-active {
        z-index: 3;
      }
    }

    .pointer-label {
      position: absolute;
      top: -110px;
      font-size: 3.4rem;
      white-space: nowrap;
      height: 65px;
      font-family: $fontMontserrat;
      font-weight: bold;
      width: 98px;
      background-color: $colorTuna;
      color: $colorWhite;
      text-align: center;
      line-height: 65px;
      margin-left: 5px;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        bottom: -13px;
        margin-left: -15px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid $colorTuna;
      }

      &.low {
        top: auto;
        bottom: -110px;

        &:before {
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid $colorTuna;
          border-top: 0;
          top: -13px;
          bottom: auto;
        }
      }
    }

    .focused {
      z-index: 10;
    }
  }

  .clickable-dummy {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .scale {
    top: 2px;
    position: relative;

    span {
      position: absolute;
      height: 5px;
      border-left: 1px solid #999;
      font-size: 0;
    }

    ins {
      font-size: 9px;
      text-decoration: none;
      position: absolute;
      left: 0;
      top: 5px;
      color: #999;
      line-height: 1;
    }
  }

  &.slider-readonly {
    .clickable-dummy,
    .pointer {
      cursor: auto;
    }
  }
}

#search-block-form #autocomplete {
  li {
    white-space: normal;
  }
}
