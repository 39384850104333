header {
  background-color: $colorWhite;

  .logo,
  #block-lerobert-block-lerobert-header-logo {
    img {
      width: 240px;
    }
  }
}

.header-top,
.header-bottom {
  border-bottom: 1px solid $colorWildSand2;

  @include sm {
    border: 0;
  }
}
// Header Top
.region-header {
  display: flex;
  align-items: center;
  height: 140px;

  @include sm {
    height: auto;
    flex-wrap: wrap;
    padding-top: 30px;
  }
}

#block-lerobert-block-lerobert-menu-button-mobile {
  display: none;
}

#block-lerobert-block-lerobert-search-defintion {
  position: relative;
  margin-left: 4.5rem;
  margin-bottom: -1rem;
  border: 0.2rem solid #E5F4FC;
  z-index: 11;

  @include sm {
    order: 10;
    width: 100%;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 0;
    padding: 0;
    z-index: 2;
  }

  &::before {
    content: "";
    position: absolute;
    top: -0.2rem;
    left: -0.2rem;
    width: calc(100% + 0.4rem);
    height: 0.2rem;
    background-image: linear-gradient(to right, #4B9FC7 0%, #E5F4FC 90%);
  }

  .nouveau {
    position: absolute;
    left: -0.2rem;
    top: -2.2rem;
    padding: 0.6rem 1rem 0.4rem;
    background-color: $colorBlueMenu;
    border-radius: 0.5rem 0.5rem 0 0;
    color: $colorWhite;
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;

    @include sm {
      top: -0.2rem;
      left: -0.2rem;
      z-index: 1;
      border-radius: 0 0 0.8rem 0;
      font-size: 1.4rem;
      padding-top: 0.4rem;
    }
  }

  form {
    position: relative;
    width: 435px;
    box-sizing: border-box;

    .mobile-search-form-title {
      display: none;
    }
  }

  .form-item {
    position: relative;
    overflow: hidden;
    padding-right: 7rem;
    background-color: $colorWhite;

    @include sm {
      padding-right: 11rem;
    }

    &::before {
      content: "";
      position: absolute;
      top: -0.2rem;
      right: -1.2rem;
      width: 7.2rem;
      height: calc(100% + 0.4rem);
      background-color: #E5F4FC;
      transform: skewX(20deg);

      @include sm {
        width: 11rem;
      }
    }
  }

  .form-text {
    background-color: $colorWhite;
    width: 100%;
    height: 44px;
    padding: 0 0 0 20px;
    font-size: 1.6rem;
    line-height: 44px;
    border: 0;

    @include sm {
      height: 70px;
      padding: 5px 20px 0;
      font-size: 2.4rem;
      color: #4B9FC7;

    }
  }

  .form-submit {
    overflow: hidden;
    width: 7.2rem;
    min-width: auto;
    height: calc(100% + 0.4rem);
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    text-indent: -9999px;
    background-image: url('../images/icon-search.svg');
    background-repeat: no-repeat;
    background-size: 24px 30px;
    background-position: right 2rem center;
    border: none;
    transition: none;

    @include sm {
      width: 11rem;
      padding: 0;
      background-size: 40px auto;
      background-position: right 3rem center;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-size: 24px 30px;
      background-position: right 2rem center;

      @include sm {
        background-size: 40px auto;
        background-position: right 3rem center;
      }
    }
  }

  .search-results {
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    width: 100%;
    line-height: 1.4;
    background: $colorWhite;
    box-shadow: 0 2px 2px 0 rgba($colorBlack, 0.14), 0 3px 1px -2px rgba($colorBlack, 0.2), 0 1px 5px 0 rgba($colorBlack, 0.12);
    max-height: calc(100vh - 180px);
    font-family: "Montserrat", sans-serif;
    overflow-y: auto;
    z-index: -1;

    a {
      li {
        display: flex;
        margin: 0;
        color: #212121;
        outline: 0;
        padding: 8px;
        transition: background .2s;
        border-bottom: 1px solid #e3e3e3;

        > span {
          &:first-of-type {
            flex: 1;
          }

          &:last-of-type {
            color: #fff;
            width: 60px;
            border-radius: 5px;
            border: 1px solid rgba(0,0,0,0.2);
            font-size: .9em !important;
            line-height: 22px !important;
            text-align: center;
            font: 400 18px Calibri, "Lucida Grande", Helvetica, sans-serif;

            @include sm {
              width: 80px;
              line-height: 30px !important;
            }
          }

          &.def {
            background-color: #227DAB;
          }

          &.conj {
            background-color: #c7393e;
          }

          &.gui {
            background-color: #518c5e;
          }
        }
      }

      &:hover,
      &:focus {
        li {
          background: rgba($colorBlack, .05);
        }
      }
    }

    .empty-results {
      text-align: center;
      padding: 8px;
      font: 400 18px Calibri, "Lucida Grande", Helvetica, sans-serif;

      b {
        display: block;
      }
    }
  }
}

#block-lerobert-block-lerobert-header-liens-utiles {
  margin-left: auto;

  .liens-utiles {
    li {
      display: inline-block;
      margin-left: 4px;

      &:after {
        content: '|';
        margin-left: 8px;
        position: relative;
        top: -1px;
        font-size: 1.1rem;
      }

      &:last-child {
        &:after {
          content: '';
        }
      }

      @include md {
        display: block;
        margin-left: 0;
        text-align: center;
        width: 100%;

        &:after {
          content: '';
        }
      }
    }

    a {
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 1.1rem;
      text-transform: uppercase;
    }
  }
}

#block-lerobert-block-lerobert-cta-jaune {
  width: 230px;
  margin: 10px auto 0;

  @include sm {
    width: 100%;
    margin: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: $colorWildSand;

    img {
      width: 450px;
      margin: 0 auto;
    }
  }
}

#block-lerobert-block-lerobert-panier {
  margin-left: auto;
  margin-top: 2.5rem;

  .content {
    position: relative;
  }

  .cart {
    display: block;
    width: 46px;
    height: 46px;
    position: relative;
    z-index: 3;
    text-indent: -9999px;
    background-image: url('../images/icon-cart.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .count {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0;
    right: -4px;
    color: $colorWhite;
    font-family: $fontWorkSans;
    font-size: 1.2rem;
    text-align: center;
    line-height: 22px;
    background-color: $colorCrimson;
    border-radius: 50%;
    z-index: 4;
  }
}

.menu {
  .field-name-field-navigation-lien {
    position: absolute;
    right:  25px;
    bottom: 10px;
  }
  >li {
    &.minipanels {
      .row.region-content {
        padding: 41px 0 32px 0;
      }
      .pane-content {
        >ul {
          >li {
            >ul {
              >li {
                a {
                  padding: 6px 0 6px 0;

                  @include min($sm-width) {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .row {
    margin-left: auto;
    margin-right: auto;
  }
  .lr-mini-panels {
    >.region-content {
      background-color: $colorPelorous;
    }
  }
}
.panel-pane {
  display: inline-block;
}
.menu.row {
  padding-top: 20px;

  @include min($sm-width) {
    margin: 0 -15px;
    display: flex;
  }

  >.panel-separator {
    display: inline-block;
    @include  sm {
      display: none;
    }
  }

  .panel-pane {
    @include min($sm-width) {
      width: 100%;
      max-width: 33.33%;
      padding: 0 15px;
      margin-bottom: 40px;
    }
  }
}

.menu-minipanel-test-mini-panel-lf {
  .panel-pane:last-child ul {
    max-width: none;
  }
}

.panel-pane {
  vertical-align: top;

  &.pane-1 {
    ul{
      @include md {
        max-width: 100%;
      }
    }
  }

  &:not(.pane-1){
    ul {

      @include md {
        max-width: 170px;

        li {
          width: auto !important;
          padding-right: 10px !important;
        }
      }

      @include sm {
        li {
          padding-right: 0 !important;
        }
      }
    }
  }

  &.pane-3 {
    @include min($sm-width) {
      border-left: 0.1rem solid $colorBlueMenu;
    }
  }

  .pane-content {
    a {
      &:hover {
        text-decoration: none;
      }
    }
    >ul{
      >li {
        margin: 12px 0 12px 0;
        display: block;
        font: {
          weight: 800;
        }
        text-transform: uppercase;
        color: $colorBlueMenu;

        @include sm {
          margin: 0;
        }

        > ul:first-of-type {
          position: relative;
          text-transform: none;
          margin-top: 11px;

          &::before {
            position: absolute;
            background: none repeat scroll 0 0 $colorBostonBlue;
            top: -7px;
            content: "";
            display: block;
            height: 1px;
            width: 50px;

            @include sm {
              display: none;
            }
          }
        }

        > a {
          display: block;
          margin-bottom: 11px;
          color: inherit;

          &::after {
            background: none repeat scroll 0 0 $colorBlueMenu;
            bottom: -3px;
            content: "";
            display: block;
            height: 1px;
            position: relative;
            width: 50px;
          }

          + ul:first-of-type {
            margin-top: 0;

            &::before {
              display: none;
            }
          }
        }
        >ul {
          max-height: 290px;

          @include min($sm-width) {
            max-height: 100%;
          }


          >li {
            vertical-align: top;
            display: inline-block;

            @include min($sm-width) {
              display: flex;
              width: 100%;
            }

            a {
              display: block;
              color: $colorSteelGray;
              font: {
                weight: 400;
                size: 1.45rem;
              };

              @include min($sm-width) {
                font-size: 1.6rem;
                line-height: 2;
                padding: 0;
              }

              &+a {
                margin-left: 5px;
              }
            }

            &:last-child {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

#block-lerobert-block-lerobert-mon-compte {
  margin-left: 18px;
  margin-top: 2.6rem;
}

.account {
  position: relative;
  display: block;
  width: 46px;
  height: 46px;
  text-indent: -9999px;
  background-image: url('../images/icon-user.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .logged-in & {
    background-image: url('../images/icon-user-logged.svg');
  }
}

// Header Bottom
#header {
  .header-bottom {
    section {
      max-width: 100%;
      padding: 0;
      .region-header-bottom {
        @extend %container;
        padding: 0;
      }
      .region-header-bottom,
      #block-menu-menu-univers,
      ul.menu {
        position: static;

        @include min($sm-width) {
          .minipanels:hover {
            >a {
              color: $colorPelorous;

              &::after {
                transform: rotate(-180deg);
              }
            }
          }
        }

        .minipanels:hover .menu-minipanel-panel {
          display: block;
        }
      }
      ul.menu-minipanel-panel {
        position: absolute;
        max-height: none;
        display: none;

        &.open {
          display: block;
        }

        li.first.odd.last {
          @extend %container;
          padding: 0;
        }
      }
      .lien {
        position: absolute;
        right: 0;
        a {
          display: block;
          border: 1px solid $colorWhite;
          padding: 5px 10px;
          background: url(../images/sm-white-next-arrow.png) right 20px center no-repeat;
          min-width: 330px;
          line-height: 22px;
          font-size: 1.4rem;
          text-align: center;
          color: $colorWhite;
          &:hover {
            background: $colorWhite url(../images/sm-blue-next-arrow.png) right 20px center no-repeat;
            color: $colorPelorous;
          }
        }
      }
      .region-content {
        @include fullWidthBackground($colorPelorous);
        &:before {
          display: block;
        }
        .top {
          position: relative;
          padding:0 4px;
        }
        .titre {
          color: $colorWhite;
          font-size: 2rem;
        }
        .panel-separator {
          height: 1px;
          width: 100px;
          background-color: white;
          margin: 9px 0 16px;
        }
        .lien {
          top: 0;
          right: 5px;
        }
      }
      .col:nth-child(2) {
        .lien {
          position: static;
          margin-top: 30px;
          display: block;
          text-align: right;
          a {
            display: inline-block;
          }
        }
      }
    }
  }
}
#block-menu-menu-univers {
  .content > .menu {
    display: flex;
    position: relative;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: calc((100vw - 1182px) / 2);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $colorGallery;
    }

    > li {
      a {
        &:hover {
          color: $colorBlueMenu !important;
        }
      }

      &.leaf:not(.minipanels) {
        > a {
          text-transform: uppercase;
          font-size: 1.4rem;
          padding: 0 30px;

          @include md {
            padding: 0 10px;
          }

          @include sm {
            box-sizing: border-box;
            display: block;
            width: 100%;
            font-size: 3.2rem;
            padding: 16px 20px;
          }
        }
        &:last-child {
          a {
            padding-right: 0;
          }
        }
      }
      &.minipanels {
        .region-content {
          .col {
            max-width: 33.3333%;
            width: 100%;
            padding: 0 5px;
            box-sizing: border-box;
            overflow: hidden;

            .pane-fieldable-panels-pane {
              position: relative;
              height: auto;
              min-height: 175px;

              >.pane-content {
                position: relative;
                height: auto;
                min-height: 175px;

                > a {
                  display: block;
                  height: auto;
                  min-height: 175px;
                }

                @include md {
                  padding-bottom: 40px;
                }
              }
              .pane-title {
                margin-top: 24px;
                margin-bottom: 8px;
                font-size: 1.7rem;
                text-transform: uppercase;
              }
              .field-name-field-navigation-sous-titre {
                margin-top: 8px;
              }
              .field-name-field-navigation-lien {
                margin: {
                  top: 22px;
                }
                text-align: right;
                .field-items {
                  margin: {
                    left: auto;
                  };
                  width: 140px;
                  line-height: 26px;
                  border: 2px solid $colorBostonBlue;
                  color: $colorBostonBlue;
                  text-align: center;
                  font-weight: normal;
                  font-size: 1.4rem;
                  .field-item {
                    display: inline-block;
                  }
                  &:after {
                    display: inline-block;
                    width: 5px;
                    height: 10px;
                    margin-left: 10px;
                    content: "";
                    background: url(../images/sm-blue-next-arrow.png) left center no-repeat;
                  }
                  &:hover {
                    background-color: $colorBostonBlue;
                    color: $colorWhite;
                    &:after {
                      background: url(../images/sm-white-next-arrow.png) left center no-repeat;
                    }
                  }
                }
              }
              .pane-title,
              .field-name-field-navigation-sous-titre{
                float: right;
                max-width: 245px;
                width: calc(100% - 130px);
                padding-right: 22px;
              }
              .field-name-field-navigation-sous-titre {
                font-weight: normal;
                font-size: 1.5rem;
                font-family: $defaultFont;
              }
              .field-name-field-navigation-visuel {
                position: absolute;
                left: 15px;
                width: 82px;
                top: 20px;
              }
              .field-name-field-navigation-format {
                position: absolute;
                bottom: 15px;
                left: 14px;
                width: 60px;
              }

              >.pane-content,
              >.pane-title {
                position: relative;
                z-index: 5;
              }
              &:after {
                content: '';
                z-index: 4;
                position: absolute;
                left:0;
                bottom: 0;
                border-bottom: 175px solid $colorEbb;
                border-right: 43px solid transparent;
                height: 0;
                width: 55px;

                @include md {
                  border-bottom: 215px solid $colorEbb;
                }
              }
              max-width: 385px;
              margin: auto;
              display: block;
              width: 100%;
              background-color: $colorWhite;
              color: $colorSteelGray;
            }
          }
        }
      }

      &:nth-child(1),
      &:nth-child(2) {
        font-family: $fontMontserrat;
        font-weight: 700;

        .titre {
          text-transform: uppercase;
          font: {
            size: 2rem;
          }
        }

        > a {
          position: relative;

          &:before {
            content: " ";
            position: absolute;
            display: block;
            height: 100%;
            top: 0px;
            left: 16px;
            z-index: -1;
            border-left: 2px solid #fff;
            transform-origin: bottom left;
            -ms-transform: skew(20deg, 0deg);
            -webkit-transform: skew(20deg, 0deg);
            transform: skew(20deg, 0deg);
          }

          &:after {
            content: '';
            display: block;
            width: 12px;
            height: 6px;
            position: absolute;
            top: 15px;
            right: 15px;
            background-image: url('../images/arrow-down.png');
            background-repeat: no-repeat;
            background-size: 12px 6px;
          }
        }

        > ul {
          width: 100%;
        }
        .col {
          &:nth-child(1) {
            width: 55%;
            font-size: 0;

            @include md {
              width: 57%;
            }

            .panel-pane {
              //float: left;
              padding: 28px 0 10px 0;
            }
            .pane-content {
              &:after {
                position: absolute;
                display: block;
                top: 5px;
                content: '';
                width: 100px;
                height: 2px;
                color: red;
              }
              >ul {
                >li {
                  position: relative;

                  >a {
                    font: {
                      weight: 800;
                    }
                    text-transform: uppercase;
                    color: $colorBostonBlue;
                    font-size: 1.6rem;

                    @include sm {
                      font-size: 2.6rem;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(2){
            width: 45%;
            @include fullWidthBackground($colorPelorous);
            background-color: $colorPelorous;
            overflow: hidden;

            @include md {
              width: 43%;
            }
            .row {
              padding: 30px 0;
              max-width: 390px;
              display: block;
              width: 100%;
              margin: auto;
              color: $colorWhite;
              .pane-content{
                > a {
                  padding: 0;
                }
              }
              >.panel-separator:nth-child(2) {
                height: 1px;
                width: 100px;
                background-color: white;
                margin: 9px 0 18px 0;
              }
            }
            .menu {
              >.panel-pane{
                max-width: 330px;
                &.lien {
                  margin-left: auto;
                  text-align: center;
                  border: 1px solid $colorWhite;
                  a {
                    font: {
                      size: 1.5rem;
                      //weight: 800;
                    };
                    color: white;
                    line-height: 16px;
                  }
                  .pane_content {
                    color: $colorWhite;
                  }
                }
              }
            }
            &:before {
              display: block;
              left: auto;
              right: -10000%;
            }
          }
          float: left;
          >div {
            display: inline-block;
            vertical-align: top;
          }

        }

        &.minipanels {
          > a {
            &:before {
              border-left: 3px solid $colorWhite;
            }
          }
        }
      }

      &.minipanels {
        padding: 0;
        background-color: $colorGallery;
        z-index: 1;

        > a {
          display: block;
          padding: 10px 48px 10px 0;

          @include min($sm-width) {
            padding-left: 48px;
          }

          &:before {
            background-color: $colorGallery;
            content: " ";
            position: absolute;
            display: block;
            height: 100%;
            top: 0;
            left: 16px;
            z-index: -1;
            border-left: 2px solid $colorWhite;
            transform-origin: bottom left;
            -ms-transform: skew(20deg, 0deg);
            -webkit-transform: skew(20deg, 0deg);
            transform: skew(20deg, 0deg);
          }

          &:after {
            right: 20px;
          }
        }

        > ul {
          background-color: $colorGallery;
        }
      }

      &:nth-child(2) {
        padding: 0 14px 0 0;
        z-index: 0;
        background: linear-gradient(-110deg, $colorWhite 5%, $colorWildSand 5%, $colorWildSand 94%, $colorWhite 94%, $colorGallery 94%);

        &:hover {
          z-index: 1;
        }

        > a {
          display: block;
          padding: 10px 48px 10px 40px;

          &:before {
            background-color: $colorGallery;
            content: " ";
            position: absolute;
            display: block;
            height: 100%;
            top: 0;
            left: 16px;
            z-index: -1;
            border-left: 2px solid #fff;
            transform-origin: bottom left;
            -ms-transform: skew(20deg, 0deg);
            -webkit-transform: skew(20deg, 0deg);
            transform: skew(20deg, 0deg);
          }
        }

        > ul {
          background-color: $colorWildSand;
        }
      }

      &:nth-child(3) {
        margin-left: auto;
      }

      &:last-child {
        a {
          padding-right: 0;
        }
      }
    }


    .menuparent {
      font: {
        size: 1.4rem;
        weight: 800;
      }
      text-transform: uppercase;

      &.active {
        color: $colorBostonBlue;

        @include sm {
          color: $colorWhite;
        }
      }
    }
  }
}

.front {
  #block-menu-menu-univers .content > .menu .menuparent.active {
    color: inherit;
    &:hover {
      color: $colorBostonBlue;
    }
  }
}

#breadcrumb {
  background: $colorWhite;
  padding:8px 0;
  font-weight: bold;
  font-size: 1.4rem;
  a {
    font-weight: normal;
  }
  .section {
    padding: 0;
  }
}

@include sm {
  header {
    position: relative;

    .logo,
    #block-lerobert-block-lerobert-header-logo {
      margin: 0 20px 0 auto;
      img {
        width: 320px;
      }
    }
    #block-lerobert-block-lerobert-header-liens-utiles {
      display: none;
    }
    #block-lerobert-block-lerobert-menu-button-mobile {
      position: relative;
      display: block;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      color: $colorBlack;
      cursor: pointer;
      margin-right: 30px;
      margin-left: 20px;
      margin-top: 9px;
      font-size: 2rem;
      height: 55px;
      box-sizing: border-box;
      line-height: 1;
      padding-top: 35px;
      width: 64px;
      text-align: center;
      background-image: url("../images/icon-burger.svg");
      background-position: 7px 0;
      background-repeat: no-repeat;
      background-size: 50px auto;

      &.open {
        border-color: transparent;
        z-index: 4;
        width: 100%;
        text-align: left;
      }
    }
    .header-bottom {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 69px;
        height: 69px;
        background-image: url(../images/mobile-close.png);
        background-repeat: no-repeat;
        background-size: 69px 69px;
        background-position: center;
        top: 25px;
        right: 20px;
      }

      &.open {
        display: block;
        width: 100%;
        z-index: 3;
        background: $colorGallery;
        padding-top: 119px;
      }

      #block-menu-menu-univers {
        .content {
          ul.menu {
            display: block;

            a {
              transition: color 0.4s ease, background 0.4s ease;
            }

            .menuparent {
              position: relative;
              text-transform: uppercase;

              &.active {
                &:hover {
                  color: $colorWhite;
                }
              }
            }

            > li {
              display: block;
              width: 100%;
              box-sizing: border-box;
              padding: 0;
              background: $colorDoveGray;
              color: $colorWhite;
              margin: 2px 0;

              > a {
                text-transform: uppercase;
                box-sizing: border-box;
                display: block;
                width: 100%;
                font-size: 3.2rem;
                padding: 16px 20px;

                &:before {
                  display: none;
                }
              }

              &.minipanels {
                background: $colorPelorous;

                > a {
                  padding-left: 10px !important;
                  padding-right: 30px !important;
                  font-weight: bold;
                  font-family: $fontMontserrat;

                  &:before {
                    left: 1px !important;
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 13px;
                    height: 23px;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 10px;
                    background-image: url("../images/lg-white-next-arrow.png");
                    background-size: 13px 23px;
                    @include transition;
                  }

                  &.open {
                    &:after {
                      transform: translateY(-50%) rotate(90deg);
                    }
                  }
                }
                ul, .panel-pane {
                  max-height: none;
                  max-width: none;
                  width: 100%;
                }
                > ul {
                  background: $colorGallery;
                  display: none;

                  &.open {
                    display: block;
                  }
                }
              }
              .pane-content {
                > ul {
                  max-width: none;
                  > li {
                    width: 100%;
                    display: block;
                    border-top: 2px solid $colorGallery;

                    > a {
                      display: block;
                      box-sizing: border-box;
                      margin: 0;
                      width: 100%;
                      color: $colorPelorous !important;
                      background: $colorWhite;
                      padding: 19.5px 20px;
                      font-size: 2.6rem;
                      font-family: $fontMontserrat;
                      font-weight: bold;
                      text-transform: uppercase;
                    }
                    ul {
                      margin: 0;
                      padding: 0;
                    }

                    li {
                      width: 100%;
                      display: block;
                      padding: 0;
                      margin: 0;
                      border-top: 2px solid $colorAlto;
                      background-color: $colorEbb;

                      @include sm {
                        display: flex;
                        justify-content: flex-start;
                      }

                      a {
                        box-sizing: border-box;
                        padding: 19.5px 40px;
                        font-size: 2.6rem;
                        font-family: $defaultFont;
                        font-weight: normal;
                        text-transform: none;
                        color: $colorTuna;

                        @include sm {
                          width: auto;
                        }

                        &+a {
                          @include sm {
                            margin-left: -28px;
                          }
                        }
                      }
                    }
                  }
                }
              }
              .panel-pane {
                > .pane-content > ul > li.has-children {
                  background-position: right 20px top 19px;
                  background-image: url("../images/plus-icon.png");
                  background-size: 30px 30px;
                  background-repeat: no-repeat;
                  background-color: $colorWhite;
                  padding: 19.5px 20px;
                  box-sizing: border-box;
                  &.open {
                    background-image: url("../images/minus-icon.png");
                  }
                  a {
                    width: calc(100% - 70px);
                    padding: 0;
                  }
                  > ul {
                    height: auto;
                    display: none;
                    margin: 20px -19.5px -20px;
                    width: calc(100% + 39px);

                    &.open {
                      display: block;
                    }

                    a {
                      padding: 19.5px 20px;
                      width: auto;
                    }

                    .menu-lien a {
                      display: inline-block;
                      width: auto;
                      padding-left: 0.4em;
                      padding-right: 0;

                      &:first-child {
                        padding-left: 19.5px;
                      }
                    }
                  }
                }
              }
              a {
                &:after {
                  display: none;
                }
              }
              .row.menu,
              .panel-pane {
                margin: 0 !important;
                padding: 0 !important;
                width: 100%;
              }
              .col {
                width: 100% !important;
                float: none;
              }
            }
          }
          ul.menu-minipanel-panel {
            position: static;
            max-height: none;
            display: none;

            &.open {
              display: block;
            }

            .row.region-content,
            .col:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
    #block-search-form {
      margin-left: auto;
      @include sm {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
      }
      form {
        width: auto;
        padding-right: 0;
        border-bottom: 0;
        background: $colorWhite;
        label {
          display: block;
          position: static !important;
          width: 63px;
          height: 63px;
          text-indent: -9999px;
          background-image: url("../images/loupe.png");
          background-repeat: no-repeat;
          background-size: 63px 63px;
          background-position: left 0 top 0;
        }
        input {
          display: none;
        }
      }
      &.open {
        margin: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        display: block;
        width: 100%;
        z-index: 3;
        background: $colorGallery;
        padding-top: 119px;
        form {
          padding: 110px 20px 35px;
          @include clearfix;
        }
        label {
          display: block;
          position: absolute !important;
          clip: unset;
          width: 100%;
          height: auto;
          text-indent: 0;
          background-image: none;
          margin-top: -191px;
          font-size: 2.6rem;
          text-transform: uppercase;
          font-family: $fontMontserrat;
          color: $colorBlack;
          padding: 8px 0;
          cursor: pointer;
        }
        .mobile-search-form-title {
          display: block;
          position: absolute;
          top: 35px;
          left: 20px;
          height: auto;
          font-family: $fontMontserrat;
          font-weight: bold;
          text-transform: uppercase;
          color: $colorBlack;
          font-size: 3rem;

          &:after {
            @include seperatorLine($color: $colorRed);
            $width: 86px;
            $margin: 5px 0 17px;
          }
        }
        input {
          display: inline-block;
        }
        .form-type-textfield,
        .form-actions {
          display: block;
          float: left;
        }
        .form-type-textfield {
          width: 70%;
        }
        .form-actions {
          box-sizing: border-box;
          width: 30%;
          padding-left: 20px;
        }
        .form-text {
          box-sizing: border-box;
          height: 60px;
          border: 2px solid $colorEbb;
          padding: 0 20px;
          font-size: 3.2rem;
          line-height: 1;
        }
        .form-submit {
          width: 100%;
          height: auto;
          position: static;
          background-color: $colorPelorous;
          background-image: url(../images/mobile-search.png);
          background-size: 40px 40px;
          background-position: center;
          height: 60px;
        }
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 69px;
          height: 69px;
          background-image: url(../images/mobile-close.png);
          background-repeat: no-repeat;
          background-size: 69px 69px;
          background-position: center;
          top: 25px;
          right: 20px;
        }
      }
    }
    #block-lerobert-block-lerobert-panier {
      margin-left: 36px;
      margin-top: 10px;

      .cart {
        width: 60px;
        height: 60px;
        z-index: 1;
      }
      .count {
        width: 30px;
        height: 30px;
        font-size: 1.8rem;
        line-height: 30px;
        z-index: 1;
      }
    }

    #block-lerobert-block-lerobert-mon-compte {
      margin-top: 12px;
      margin-left: 30px;
      margin-right: 20px;
    }
    .account {
      width: 60px;
      height: 60px;
    }
  }
  #breadcrumb {
    display: none;
  }
}


// New breakpoint for 1024 resolution because the first 2 elements pass in two lines.
@media (min-width: 768px) and (max-width: 1024px) {
  #block-menu-menu-univers {
    .content {
      .menu {
        li {

          &.minipanels {
            a {
              padding-left: 10px !important;
              padding-right: 30px !important;

              &:before {
                left: 1px !important;
              }

              &:after {
                right: 10px;
              }
            }
          }

          &:nth-child(2) {
            padding: 0 14px;
          }

          .pane-content {
            li {
              padding: 0;

              a {
                padding: 0 !important;

                &:after {
                  right: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

#autocomplete {
  width: 130% !important;
  position: absolute;
  left: 0 !important;
  margin-top: 0;
  border: 0;
  border-top: 2px solid $colorEbb;
  border-bottom: 4px solid rgba($colorBlack,.2);
  box-shadow: 5px 2px 0px -2px rgba($colorBlack,.2),
  -5px 2px 0px -2px rgba($colorBlack,.2);
  background-clip: padding-box;
  background-color: $colorWhite;
  padding: 17px 20px;
  box-sizing: border-box;

  @include sm {
    width: 100% !important;
    border: 0;
    box-shadow: 0;
    padding: 0;
    padding-top: 35px;
  }

  ul, li {
    padding: 0;
    margin: 0;
  }

  .autocomplete-suggestions-list {
    margin-top: 12px !important;
  }

  li {
    line-height: 0;
    border-top: 1px solid $colorAlto;
    padding: 12px 0;
    white-space: normal;

    div {
      display: block;
      line-height: 1.1;
    }

    @include sm {
      background-color: $colorEbb;
      padding: 30px 21px 26px 55px;
    }
  }

  .autocomplete-title {
    font-size: 1.5rem;
    color: $colorGray;
    line-height: 1.1;

    @include sm {
      display: none;
    }
  }

  .field-name-title-field {
    font-size: 1.5rem;
    font-weight: bold;
    color: $colorPelorous;
    height: auto;
    margin-bottom: 3px;

    @include sm {
      font-size: 2.6rem;
    }
  }

  .field-name-field-format {
    font-size: 1.4rem;

    @include sm {
      margin-top: 14px;
      font-size: 2.4rem;
    }
  }

  .field-name-field-ouvrage-soustitre {
    font-size: 1.2rem;
    color: $colorTuna;
    margin-top: 3px;
    line-height: 1.1;

    @include sm {
      font-size: 2.4rem;
      margin-top: 12px;
    }
  }

  .field-name-field-ean13,
  .share {
    display: none;
  }
}

#header .header-bottom section .region-header-bottom {
  @include min($sm-width) {
    display: flex;
  }
}

#block-lerobert-block-lerobert-search-site {
  .content {
    height: 100%;

    form {
      height: 100%;

      div,
      input {
        height: 100%;
      }
    }
  }
}

#block-search-site-form {
  @include sm {
    border-top: 1px solid $colorWildSand2;
    background-color: $colorBlueSearch;
  }

  >div {
    display: flex;

    @include sm {
      align-items: stretch;
    }

    .form-item-search {
      position: relative;
      height: 100%;
      background-color: $colorWhite;

      @include sm {
        width: 100%;
      }

      input {
        height: 100%;
        border: none;
        padding-left: 25px;
        padding-right: 15px;
        max-width: 285px;
        text-overflow: ellipsis;
        font-family: 'Nunito';
        font-size: 1.2rem;

        @include sm {
          max-width: calc(100vw - 80px);
          padding: 16px 20px;
          font-size: 3rem;
        }
      }

      label {
        position: static !important;
        font-size: 0;

        &:before {
          content: '';
          display: block;
          width: 30px;
          height: calc(100% + 1px);
          position: absolute;
          left: -18px;
          top: 0;
          transform: skewX(20deg);
          background-color: $colorGallery;

          @include sm {
            display: none;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 30px;
          height: calc(100% + 1px);
          position: absolute;
          right: -65px;
          top: 0;
          transform: skewX(20deg);
          background-color: $colorWhite;
          z-index: 2;

          @include sm {
            display: none;
          }
        }
      }

      &:before {
        content: '';
        display: block;
        width: 30px;
        height: calc(100% + 1px);
        position: absolute;
        right: -23px;
        top: 0;
        transform: skewX(20deg);
        background-color: $colorBlueSearch;
      }

      &:after {
        content: '';
        display: block;
        width: 30px;
        height: calc(100% + 1px);
        position: absolute;
        right: -64px;
        top: 0;
        transform: skewX(20deg);
        background-color: $colorBlueSearch;
        z-index: 2;
        border-right: 1px solid $colorWildSand2;
      }
    }

    .form-submit {
      font-size: 0;
      width: 55px;
      background-color: $colorBlueSearch;
      background-image: url('../images/loupe-menu.svg');
      background-size: 20px;
      background-position: center center;
      background-repeat: no-repeat;
      min-width: 0;
      padding: 0;
      border: none;
      position: relative;
      z-index: 3;

      @include sm {
        height: unset !important;
      }
    }
  }
}
