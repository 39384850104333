////////////////////////
// Mixins Partials
//
// All of your mixins should go in this file.
////////////////////////

//////////////////////////////
// Responsive
//////////////////////////////

@mixin max($maxwidth) {
  @media only screen and (max-width: $maxwidth) {
    @content;
  }
}
@mixin min($minwidth) {
  @media only screen and (min-width: $minwidth) {
    @content;
  }
}

@mixin mid($minwidth, $maxwidth) {
  @media (min-width: $minwidth) and (max-width: $maxwidth) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md-width}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-sm-width}) {
    @content;
  }
}

@mixin xs {
  @media (max-width: #{$breakpoint-xs-width}) {
    @content;
  }
}
@mixin ie {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

//////////////////////////////
// Other
//////////////////////////////

@mixin clearfix {
  &:before, &:after {
    display: block;
    content: "";
  }
  &:after {
    clear: both;
  }
}

@mixin fullWidthBackground($background-color) {
  position: relative;

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';
    width: 10000%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1000%;
    background-color: $background-color;
    z-index: 0;
  }
}

//Transition
@mixin transition($element: all, $duration: 0.4s) {
  transition: $element $duration ease;
}

// Mixin for Photoshop letter spacing.
@mixin letter-spacing($ps: 0) {
 letter-spacing: fn-letter-spacing($ps);
}

// CTA button with hover gradient
@mixin cta-gradient($bw: 2px, $bg: transparent, $color: $colorBostonBlue) {
  overflow: hidden;
  display: inline-block;
  position: relative;
  background: $bg;
  font-family: $fontMontserrat;

  @if $bw != 0 {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: $bw solid $color;
    }
  }

  a {
    display: inline-block;
    padding: 10px 20px 8px;
    position: relative;
    color: $color;
    font-size: 1.6rem;
    z-index: 2;
  }

  &:before {
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    right: 120%;
    left: -50px;
    background-color: $colorPelorous2;
    transition: all 200ms ease;
    transform: skewX(30deg);
  }

  &:hover {
    background-color: $colorBostonBlue;

    &:before {
      right: 50%;
    }

    &:after {
      border: 0;
    }

    a {
      color: $colorWhite;
      text-decoration: none;
    }
  }

  &:active,
  &:focus {
    &:before {
      right: -50px;
    }
  }
}
@mixin button-diagonal($color: $colorPelorous, $hoverbg: $colorBostonBlue) {
  align-items: center;
  background: transparent;
  background-position: -250px;
  border: 2px solid $color;
  box-sizing: border-box;
  color: $color;
  display: inline-flex;
  font-family: $fontMontserrat;
  justify-content: center;
  text-align: center;
  transition: background 0.4s ease, color 0.4s ease;
  &:hover {
    background: linear-gradient(60deg, $colorPelorous2 50%, transparent 50%);
    background-position: 0;
    border: 0;
    background-color: $hoverbg;
    background-repeat: no-repeat;
    color: $colorWhite;
  }
  &:focus,
  &:active {
    border: 0;
    background-color: $colorBostonBlue;
    background-repeat: no-repeat;
    color: $colorWhite;
  }
}

@mixin button($background: transparent, $border: $colorPelorous, $color: $colorPelorous, $hovercolor: $colorWhite) {
  border: 2px solid $border;
  color: $color;
  font-family: $fontMontserrat;
  background-color: $background;
  background-image: url(../images/sm-blue-next-arrow.png);
  background-position: right 10px center;
  background-repeat: no-repeat;
  cursor: pointer;
  min-width: 180px;
  max-width: 100%;
  height: 30px;
  text-align: center;
  @include transition;
  &:hover {
    background-color: $color;
    background-image: url(../images/sm-white-next-arrow.png);
    color: $hovercolor;
  }
}

// Icons for CTAs
@mixin cta-icon($size: small, $color: blue) {
  @if $size == small {

  }
}

// Background skew on hover
@mixin bg-skew($direction: vertical, $bg: $colorSwissCoffee, $bg-hover: $colorPelorous2) {
  overflow: hidden;
  position: relative;
  background-color: $colorWhite;

  @if $direction == vertical {
    padding-top: 230px;
  }
  @if $direction == horizontal {
    padding-left: 58%;
  }

  .skew {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $bg;
    transition: all 200ms ease;

    @if $direction == vertical {
      height: 150px;
    }
    @if $direction == horizontal {

    }
  }

  &:hover {
    .skew {
      background-color: $bg-hover;

      @if $direction == vertical {
        height: 220px;
        transform: skewY(25deg) translateY(-47px);
      }
      @if $direction == horizontal {
        transform: skewX(0) translateX(0);
      }
    }
  }
}

//Section titles
@mixin sectionTitle($color: dark, $size: large) {
  display: block;
  margin: 0 auto;
  padding-bottom: 28px;
  font-family: $fontMontserrat;
  font-size: 3.2rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @if $size == small {
    font-size: 2rem;
  }

  @include sm {
    font-size: 4.2rem;
    padding: 0 21px 28px 21px;
    box-sizing: border-box;
  }

  &:before {
    content: '';
    display: block;
    width: 188px;
    height: 8px;
    margin: 0 auto 26px;
    background-color: $colorRed;

    @if $size == small {
      width: 90px;
      margin: 0 auto 12px;
    }
  }

  @if $color == dark {
    color: $colorBlack;
  }
  @if $color == light {
    color: $colorWhite;
  }
}
//Seperator
@mixin seperatorLine($color: $colorPelorous, $width: 44px, $height: 1px, $margin: 10px 0) {
  margin: $margin;
  display: block;
  width: $width;
  height: $height;
  @include sm {
    margin: 10px 0 20px 0;
    width: 104px;
    height: 2px;
  }
  background: $color;
  content: "";
}
//Diagonal background
@mixin diagonalBg($direction: horizontal, $color: $colorEbb, $hovercolor: $colorPelorous, $transition: 1, $reverse: 0) {
  &:before {
    position: absolute;
    content: "";
    background: $color;
    @if $direction == horizontal {
      transform: skewX(25deg);
      right: 50%;
      height: 100%;
      width: 100%;
      @if $reverse == 1 {
        transform: skewX(0deg);
        right: 58%;
        top: 0;
      }
    }
    @if $direction == vertical {
      transform: rotate(0deg);
      right: -50%;
      top: -30%;
      width: 200%;
      height: 60%;
    }
    @if $transition == 1 {
      transition: all ease 0.2s;
    }
  }
  &:hover {
    &:before {
      background: $hovercolor;
      @if $direction == horizontal {
        transform: skewX(0deg);
        right: 58%;
        @if $reverse == 1 {
          top: 0;
        }
      }
      @if $direction == vertical {
        transform: rotate(10deg);
        top: -24%;
      }
    }
  }
}

@mixin fullWidthElement {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

@mixin eyeShowPassword {
  height: 100%;
  width: 20px;
  position: absolute;
  background: url(/sites/all/themes/lerobert/assets/images/eye_icon.svg) no-repeat center;
  top: 13px;
  background-size: 100%;
  right: 15px;

  &:hover {
    cursor: pointer;
  }

  @include sm {
    width: 50px;
    top: 25px;
  }
}

// ==========================================================================
// Plyr mixins
// https://github.com/selz/plyr
// ==========================================================================

// <input type="range"> styling
@mixin plyr-range-track() {
    height: $plyr-range-track-height;
    background: transparent;
    border: 0;
    border-radius: ($plyr-range-track-height / 2);
    user-select: none;
}
@mixin plyr-range-thumb() {
    position: relative;
    height: $plyr-range-thumb-height;
    width: $plyr-range-thumb-width;
    background: $plyr-range-thumb-bg;
    border: $plyr-range-thumb-border;
    border-radius: 100%;
    transition: background .2s ease, border .2s ease, transform .2s ease;
    box-shadow: $plyr-range-thumb-shadow;
    box-sizing: border-box;

    @include sm {
      height: 20px;
      width: 20px;
    }
}
@mixin plyr-range-thumb-active() {
    background: $plyr-range-thumb-active-bg;
    border-color: $plyr-range-thumb-active-border-color;
    transform: scale($plyr-range-thumb-active-scale);
}

// Mixin for background images
@mixin backgroundImage($image: '', $size: '', $position: '', $repeat: '') {
	@if ($image != '') {
		background-image: url("../images/#{$image}");
	}

	@if ($position != '') {
		background-position: $position;
	}

	@if ($size != '') {
		background-size: $size;
	}

	@if ($repeat != '') {
		background-repeat: $repeat;
	}
}
