////////////////////////
// Extendables Partials
//
// All of your extendable classes, ids, and placeholder extendables
//   should go in this file.
////////////////////////

.clearfix {
  &:after {
    content: " ";
    display: block;
    clear: both;
  }
}
