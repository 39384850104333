#ws_global {
  width: 100%;

  #form_account_errors_display {
    padding: 0;
    background: none;
    border: none;
    width: 100%;
    table {
      border-collapse: collapse;
      tr {
        box-sizing: border-box;
        padding: 22px 10px 10px 81px;
        margin: 0;
        min-height: 66px;
        border: 0;
        color: $colorWhite;
        font-size: 1.7rem;
        font-family: $fontMontserrat;
        font-weight: normal;
        background-repeat: no-repeat;
        background-position: left 10px center;
        position: relative;

        td {
          color: $colorWhite;
          padding-left: 60px;
          @include fullWidthBackground($colorSunsetOrange);
          background-color: $colorSunsetOrange;

          background-image: url(../images/icon-error.png);
          background-repeat: no-repeat;
          background-position: left center;

          &:before {
            z-index: -1;
          }
        }

        @include sm {
          padding: 0;
          font-size: 2.7rem;
          font-weight: bold;
          background-position: left 0px center;

          td {
            padding-left: 80px;
            background-image: url(../images/icon-error-mobile.png);
          }
        }
      }
    }
  }

  #form_account {

    .label_error {
      color: $colorSunsetOrange;
      img {
        display: none;
      }
      &+select,
      &+input {
        border: 2px solid $colorSunsetOrange;
      }
    }
    h2 {
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 2rem;
      text-transform: uppercase;
      padding: 0;
      border: 0;
      margin-bottom: 20px;

      &:after {
        @include seperatorLine($colorRed);
      }

      @include sm {
        font-size: 3rem;
      }
    }
    p {
      color: $colorBlack;
      font-size: 1.5rem;
      margin-bottom: 20px;

      @include sm {
        font-size: 2.5rem;
      }
    }

    h2 + p {
      font-weight: normal;
      font-size: 1.6rem;

      @include sm {
        font-size: 2.6rem;
      }
    }

    p + .form-radio {
      margin-top: -15px;
    }

    .form-radio {
      @include sm {
        @include clearfix;
      }
    }

    label {
      color: $colorBlack;
      font-weight: bold;
      font-size: 1.6rem;
      display: block;
      margin-bottom: 5px;
      width: 100%;
      text-align: left;
      img {
        display: inline-block;
      }
      &[for*="child_sex"] {
        display: none;
      }

      @include sm {
        font-size: 2.6rem;
        margin-bottom: 10px;
        margin-top: 15px;
      }
    }
    input, select {
      border: 1px solid $colorEbb;
      width: 100%;
      max-width: 450px;
      font-size: 1em;

      @include sm {
        border-width: 2px;
        max-width: 100%;
        font-size: 2.6rem;
      }

      &.submit_button {
        border: 2px solid $colorPelorous;
        background-image: url(../images/valider.png);
        background-color: $colorPelorous;
        background-position: center;
        background-size: inherit;
        width: 176px;
        padding: 0;
        height: 40px;
        box-sizing: content-box;
        &:hover {
          background-color: $colorPelorous;
          box-shadow: none;
          color: $colorWhite;
        }

        @include sm {
          background-image: url(../images/valider-mobile.png);
          width: 282px;
          height: 70px;
          display: block;
          margin: 50px auto 0;
        }
      }

      &[type="checkbox"] + label {
        margin-bottom: 15px;
      }

      &[name*="child_firstname"] {
        width: 158px;
        height: 30px;

        @include sm {
          width: 274px;
          height: 70px;
        }
      }

      &[type="radio"] {
        padding: 0;
        width: 22px;
      }

      &[type="radio"] + label {
        width: auto;
        margin-right: 20px;
        &:before {
          background-image: url('../images/radio-sprite-white.png');
        }

        @include sm {
          &:before {
            background-image: url('../images/radio-sprite-white-mobile.png');
          }
        }
      }
    }

    select {
      cursor: pointer;
      background-image: url(../images/select-background.png);
      background-position: right center;
      background-repeat: no-repeat;
      -moz-appearance: none;

      @include sm {
        background-image: url(../images/select-background-mobile.png);
      }
    }

    .select2-container--default {
      .select2-selection--single {
        border: 1px solid $colorEbb;
        height: 30px;

        .select2-selection__rendered {
          line-height: 30px;
          padding: 0 30px 0 14px;
          font-family: $defaultFont;
          font-size: 1.6rem;
        }

        .select2-selection__arrow {
          height: 28px;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 30px;
          border-left: 1px solid $colorEbb;

          b {
            border-color: $colorDoveGray transparent transparent transparent;
            border-width: 6px 6px 0 6px;
            margin-left: -6px;
            margin-top: -3px;
          }
        }
      }

      @include sm {
        .select2-selection--single {
          border-width: 2px;
          height: 70px;

          .select2-selection__rendered {
            line-height: 70px;
            padding: 0 70px 0 20px;
            font-size: 2.6rem;
          }

          .select2-selection__arrow {
            height: 66px;
            top: 2px;
            right: 2px;
            width: 70px;
            border-left: 2px solid $colorEbb;

            b {
              border-width: 14px 14px 0 14px;
              margin-left: -14px;
              margin-top: -7px;
            }
          }
        }
      }
    }
    .select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow {
          b {
            border-color: $colorBlack transparent transparent transparent;
          }
        }
      }
    }

    .form-checkbox {
      @include clearfix;
      label {
        font-weight: normal;
      }
    }
    #div_options_list_form {
      input[type="checkbox"] + label {
        margin-bottom: 0;
        margin-top: -20px;
      }
      label {
        font-weight: normal;

        @include sm {
          line-height: 1.2;
          padding-top: 10px;
          box-sizing: border-box;
        }
      }
    }

    .submit {
      @include sm {
        padding-right: 0;
      }
    }
  }
}

.cgv-footer {
  @include sm {
    margin: 40px 0;
  }
}

div.tip {
  div.tip-title {
    @include sm {
      font-size: 2.3rem;
    }
  }

  div.tip-text {
    @include sm {
      font-size: 2.1rem;
    }
  }
}
#div_profil_choice{
  .field {
    padding: 0;
    margin-top: 30px;
    li {
      input[type="radio"] + label {
        width: auto;
        font-weight: normal;
        &:before {
          background-image: url('../images/radio-sprite-white.png');
        }
      }
    }
  }
  .submit_button {
    background-color: transparent;
    background-image: url(../images/lg-blue-next-arrow.png);
    background-size: 7px 13px;
    background-position: right 10px center;
    border: 2px solid $colorPelorous;
    box-sizing: border-box;
    color: $colorPelorous;
    font-size: 1.6rem;
    font-weight: bold;
    height: 44px;
    margin-top: 30px;
    width: 100%;
    &:hover {
      background-color: $colorPelorous;
      background-image: url(../images/lg-white-next-arrow.png);
      color: $colorWhite;
    }
  }
}

#global_registration_etu_form,
#global_registration_ent_form,
#global_registration_par_form,
#global_registration_ens_form {
  width: 100%;
  .submit {
    width: 50%;
    margin: 0 auto;
    padding-right: 0;
    text-align: center;
    input {
      background-color: $colorPelorous;
      background-image: none;
      background-position: right 20px center;
      border: 0;
      color: $colorWhite;
      font-size: 1.8rem;
      font-family: $fontMontserrat;
      height: auto;
      height: 50px;
      padding: 0 20px;
      max-width: 210px !important;
      &:hover {
        border-color: $colorBostonBlue;
        background-color: $colorBostonBlue;
        box-shadow: 0 0 10px rgba($colorBlack, 0.2);
      }
    }
  }
}
#div_global_registration_etu,
#div_global_registration_ent,
#div_global_registration_par,
#div_global_registration_ens {
  width: 50%;
  margin: 0 auto;

  @include sm {
    width: 100%;
  }
}
.logged-in {
  #div_global_registration_etu,
  #div_global_registration_ent,
  #div_global_registration_par,
  #div_global_registration_ens {
    width: auto;
    margin: 0 auto;
    padding: 0 5px;

    @include sm {
      box-sizing: border-box;
    }
  }
}

#group_personal_infos {
  padding-right: 0;
}

#group_personal_infos,
#group_institution_infos {
  width: 100%;
  margin: 0;
  float: none;
}

#div_personal_information,
#div_login,
#div_profil_etu_subform,
#div_profil_ent_subform,
#div_focus_subform,
#div_profil_par_subform,
#div_institution_ens,
#div_password,
#div_profil_ens_subform {
  width: 100%;
  border: 0;
  margin-bottom: 20px;
  background-color: $colorWhite;
  padding: 20px;
  box-sizing: border-box;
  float: inherit;
  p {
    font-weight: bold;
  }

  @include sm {
    padding: 22px 18px;
    box-sizing: border-box;
  }
}

#div_password,
#div_login {

  li {
    padding-bottom: 5px;
    position: relative;
    max-width: 450px;
    @include sm {
      max-width: 100% !important;
    }

    a.show_pwd {
      @include eyeShowPassword;
    }
  }
}

#div_institution_ens {
  .field_choice_institution {
    width: 100%;
    max-height: 224px;
    input[type="radio"] {
      width: 22px;
      height: 22px;
      display: inline-block;
      background-image: url('../images/radio-sprite-white.png');
      background-position: 0 center;
      background-repeat: no-repeat;
      border: none !important;

      &:hover {
        background-position: -52px center;
      }

      &:checked {
        background-position: -110px center;
      }

      &:active {
        border: none;
        outline: none;
      }
    }
  }
}

#div_password {
  width: calc(50% - 10px);
}

.logged-in {
  #div_password {
    width: 100%;
  }
}

#div_profil_etu_subform,
#div_profil_par_subform {
  #div_button_add_child {
    text-align: left;
  }
  .fieldset{
    .input_text_date {
      height: 31px;

      @include sm {
        height: 70px;
      }
    }
  }
  button.calendar {
    background-image: url(../images/calendar.png);
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    cursor: pointer;
    height: 31px;
    width: 29px;
    margin-left: 12px;

    @include sm {
      background-image: url(../images/calendar-mobile.png);
      height: 69px;
      width: 63px;
      margin-left: 28px;
    }
  }

  .adult_birthday_inputs,
  .child_birthday_inputs {
    input {
      padding: 0;

      @include sm {
        border-width: 2px 0 2px 0 !important;
        text-align: center;
      }
    }
  }

  #adult_month_1,
  input[name*="child_month"] {
    width: 26px !important;

    @include sm {
      width: 70px !important;
    }
  }
  #adult_day_1,
  input[name*="child_day"] {
    width: 36px !important;
    padding-left: 10px;

    @include sm {
      padding-left: 20px;
      width: 70px !important;
      border-width: 2px 0 2px 2px !important;
    }
  }
  #adult_year_1,
  input[name*="child_year"] {
    width: 50px !important;
    border-width: 1px 1px 1px 0 !important;
    padding-right: 10px;

    @include sm {
      padding-right: 20px;
      width: 120px !important;
      border-width: 2px 2px 2px 0 !important;
    }
  }
  .fieldset {
    .disable {
      background-color: $colorWhite;
      color: #737373;
      padding: 0;
    }
  }
}

#div_profil_etu_subform {
  input[type="radio"] + label {
    font-weight: normal !important;
  }
}

#div_profil_par_subform {
  .submit_button {
    display: inline-block;
    box-sizing: content-box;
    background-image: url(../images/ajouterunenfant.png);
    background-position: center;
    width: 225px;
    line-height: 40px;
    height: 40px;
    border: 2px solid $colorPelorous;
    color: $colorPelorous;
    font-family: $fontMontserrat;

    @include sm {
      background-image: url(../images/ajouterunenfant-mobile.png);
      border: 0;
      background-position: center;
      width: 354px;
      line-height: 70px;
      height: 70px;
    }
  }
  #children_list {
    > div {
      height: auto !important;
    }
  }
  .children_box {
    border: 0;
    padding: 12px 17px 19px 29px;
    color: $colorBlack;
    font-size: 1.6rem;
    ul.field {
      margin-top: 34px;
      li:first-child {
        padding-bottom: 20px;
      }

      @include sm {
        margin-top: 14px;
      }
    }

    input[type="radio"] {
      position: absolute;
      display: none;
      border: none !important;
      z-index: 100;

      &:hover,
      &:active,
      &:checked {
        border: none;
        outline: none;
      }
    }

    .children_sex_label {
      font-size: 1.6rem;
      color: $colorBlack;
      &:before {
        background-image: url('../images/radio-sprite-white.png');
      }

      @include sm {
        font-size: 2.6rem;
        padding: 0;
        background: $colorWhite;
        display: inline-block;
        width: 220px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        color: $colorBlack;

        &:before {
          display: none;
        }
      }
    }
  }

  input[type="radio"]:checked+.children_sex_label {
    &:before {
      background-position: -110px center;
    }

    @include sm {
      background: $colorPelorous;
      color: $colorWhite;
      box-shadow: 0 0 7px rgba($colorBlack, 0.47);
    }
  }

  a.delete_child {
    width: 28px;
    height: 27px;
    margin-right: 0;
    background-image: url(../images/mobile-close.png);
    background-size: 28px 27px;
    &:hover {
      background: none;
      background-size: 28px 27px;
      background-image: url(../images/mobile-close-hover.png);
    }

    @include sm {
      width: 69px;
      height: 69px;
      background-size: auto;
      margin-top: 14px;

      &:hover {
        background-size: 69px 69px;
      }
    }
  }
}
#div_global_registration_ens {
  .submit {
    text-align: center;
    width: auto;
    input#institution_ens_first_step_submit {
      background-image: url(../images/rechercher.png);
      background-color: $colorWhite;
      background-position: center;
      border: 2px solid $colorPelorous;
      color: $colorPelorous;
      font-family: $fontMontserrat;
      height: 44px;
      padding: 0 20px;
      width: auto;
      &:hover {
        background-color: $colorWhite;
        box-shadow: none;
        color: $colorWhite;
      }

      @include sm {
        background-image: url(../images/rechercher-mobile.png);
        width: 282px;
        height: 70px;
        display: block;
        margin: 50px auto 0;
        border: 0;
      }
    }
  }
}
div.calendar {
  background: $colorWhite;
  border: 1px solid $colorAlto;
  margin-left: -28px;
  padding: 0 10px;
  height: auto;
  width: 304px;
  overflow: hidden;
  div {
    width: 304px;
    height: auto;
    padding: 0;
    overflow: visible;
  }
  table {
    width: 304px;
    overflow: visible;
    tbody {
      tr,
      tr:nth-child(2n) {
        background-color: $colorWhite;
      }
      tr:last-child {
        td {
          padding-bottom: 20px;
        }
      }
    }
    td,
    th,
    td.invalid {
      color: $colorGrayChateau;
      font-family: $defaultFont;
      font-size: 1.3rem;
      height: 33.5px;
      vertical-align: middle;
      &.valid {
        color: $colorDoveGray;
        font-weight: normal;
      }
      &.active,
      &.valid:hover {
        position: relative;
        color: $colorCuriousBlue;

        &:after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid $colorCuriousBlue;
          border-radius: 50%;
          position: absolute;
          top: 5px;
          left: 11px;
        }
      }
      &.today {
        color: $colorDoveGray;
      }
    }
    th {
      background-color: $colorWhite;
      color: $colorPelorous;
      font-weight: bold;
      padding-top: 10px;
    }
  }
  caption {
    @include fullWidthBackground($colorPelorous);
    background-color: $colorPelorous;
    padding: 10px 0;
    color: $colorWhite;
    font-family: $defaultFont;
    font-size: 1.5rem;
    line-height: 1.2;
    overflow: visible;
    span.month,
    span.year {
      width: 100%;
      padding-top: 0px;
    }
    a {
      background-size: 6px 9px;
      height: 9px;
      width: 6px;
      top: 0;
      &:hover {
        background-position: 0 0;
      }
    }
    a.next {
      background-image: url(../images/sm-white-next-arrow.png);
      top: 4px;
      right: 18px;
    }
    a.prev {
      background-image: url(../images/sm-white-next-arrow.png);
      transform: rotate(-180deg);
      top: 4px;
      left: 18px;
    }
  }
}
.form-account-select {
  .select2-results__option {
    font-family: $defaultFont;
    font-size: 1.6rem;
    padding: 6px 6px 6px 15px;
    cursor: pointer;

    @include sm {
      font-size: 2.6rem;
    }
  }
}

#div_registration_confirmation {
  padding: 20px;
  background-color: #ffffff;
  border: none;
  color: $colorBlack;
  margin: 20px auto;
  width: 50%;

  .fieldset {
    h2 {
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 2rem;
      text-transform: uppercase;
      padding: 0;
      border: 0;
      margin-bottom: 20px;

      &:after {
        @include seperatorLine($colorRed);
      }
    }
  }

  p,
  .confirmation_email,
  .login_use {
    line-height: 22px;
    font-size: 1.6rem;
  }

  .login_infos {
    background-color: transparent;
    border: none;
  }

  .confirmation_email {
    img {
      display: none;
    }
  }
}

#form_account_success_display {
  margin: 0;
  border: 0;

  font-family: $fontMontserrat;
  font-weight: normal;
  background-repeat: no-repeat;
  background-position: left 10px center;
  position: relative;
  width: 100%;
  padding: 0 15px 0 15px;
  background-color: $colorFountainBlue;
  box-sizing: border-box;

  td {
    background-color: $colorFountainBlue;
    background-image: url(../images/icon-ok.png);
    background-repeat: no-repeat;
    color: $colorWhite;
    font-size: 1.7rem;
    padding: 20px 0 20px 60px;
    background-position: left center;
  }

  tr {
    border-top: 0;
  }
}

.page-user-register {
  #form_account {
    display: inherit;
  }
}
