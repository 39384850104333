.page-user,
.page-mes-commandes {
  #block-lerobert-block-lerobert-user-sidebar {
    width: 25%;
    float: left;
    margin-bottom: 150px;

    @include sm {
      display: none;
    }
  }

  #block-lerobert-block-lerobert-user-sidebar + #block-system-main {
    box-sizing: border-box;
    width: 75%;
    float: left;
    padding-left: 10px;

    @include sm {
      width: 100%;
      float: none;
      padding: 0;
      margin-bottom: 40px;
    }
  }

  #main-content .region-content {
    @include clearfix;
  }

  main#main {
    .account {
      height: auto;
      width: auto;
      border-radius: 0;
      text-indent: 0;
      text-align: left;
      background-color: $colorShark3;
      padding: 20px 30px;
      background-image: none;
    }

    .avatar {
      align-items: center;
      background-color: $colorBittersweet;
      border-radius: 50%;
      color: $colorWhite;
      display: inline-flex;
      font-size: 3.8rem;
      font-weight: 300;
      justify-content: center;
      height: 112px;
      text-transform: uppercase;
      width: 112px;
    }

    .name {
      font-family: $fontMontserrat;
      font-size: 2.2rem;
      margin: 18px 0;
      color: $colorWhite;

      &:after {
        margin: 10px 0 20px;
        display: block;
        width: 44px;
        height: 1px;
        background: $colorRed;
        content: "";

      }
    }

    .logout {
      @include button-diagonal($colorWhite);
      background-color: transparent;
      border: 2px solid $colorWhite;
      display: flex;
      font-size: 1.8rem;
      height: 44px;
      margin-bottom: 28px;
    }

    #block-lerobert-block-lerobert-user-sidebar {
      .list {
        background-image: url(../images/lg-blue-next-arrow.png);
        background-position: 30px center;
        background-size: 7px 14px;
        background-repeat: no-repeat;
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
        color: #ffffff;
        padding-top: 6px;
        padding-bottom: 6px;
        margin-left: -30px;
        margin-right: -30px;
        padding-left: 46px;
        padding-right: 30px;

        &:last-child {
          border-bottom: 0;
        }

        &:hover,
        &.active {
          background-color: $colorTuna;
          color: $colorPelorous;
        }
      }
    }

    .mes-ressources {
      > .item-list > ul > li {
        background-image: none !important;
        background-color: $colorWhite;
        padding: 38px 29px 29px !important;
        margin-bottom: 46px;
        min-height: 89px;
        position: relative;

        @include sm {
          padding: 76px 21px 50px !important;
          margin-bottom: 39px;
        }
      }

      .ressource-visuel {
        position: absolute;
        left: 29px;
        top: 38px;

        @include sm {
          left: 21px;
          top: 71px;
        }
      }

      .ressource-title {
        color: $colorBlack;
        font-size: 1.9rem;
        font-weight: bold;
        font-family: $fontMontserrat;
        width: 30%;
        margin-left: 120px;

        .ico-link {
          display: inline-block;
          background-image: url(../images/link-icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 15px;
          height: 15px;
          margin-left: 12px;
        }

        @include sm {
          font-size: 2.9rem;
          width: 50%;

          .ico-link {
            background-image: url(../images/link-icon-mobile.png);
            width: 53px;
            height: 53px;
            margin-left: 0;
            position: absolute;
            right: 21px;
            top: 76px;
          }
        }
      }
      .ressource-flag {
        color: $colorPelorous;
        font-size: 1.4rem;
        font-family: $fontMontserrat;
        position: relative;
        margin-left: 120px;
        padding-top: 18px;
        display: inline-block;
        margin-bottom: 27px;

        @include sm {
          font-size: 2.4rem;
        }

        &:before {
          @include seperatorLine;
          position: absolute;
          left: 0;
          top: 0;

          @include sm {
            width: 71px;
            margin: 5px 0;
          }
        }

        a {
          span {
            background-repeat: no-repeat;
            background-position: center;
            display: inline-block;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            margin-bottom: -2px;

            @include sm {
              width: 28px;
              height: 25px;
              margin-right: 15px;
            }

            &.ico-flag-add {
              background-image: url(../images/heart-icon-remove.png);
              width: 18px;

              @include sm {
                background-image: url(../images/heart-icon-remove-mobile.png);
                width: 28px;
              }
            }

            &.ico-flag-remove {
              background-image: url(../images/heart-icon.png);

              @include sm {
                background-image: url(../images/heart-icon-mobile.png);
              }
            }
          }
        }
      }

      .ressource-abonnement {
        position: absolute;
        right: 0;
        top: 0;
        background: $colorPelorous;
        padding: 4px 27px;
        color: $colorWhite;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1;
        text-transform: uppercase;

        @include sm {
          padding: 0 23px;
          font-size: 2.4rem;
          line-height: 40px;
        }
      }

      .abonnement-details {

        @include sm {
          margin-top: 20px;
                    @include clearfix;
        }

        fieldset {
          position: static;

          &.collapsed {
            @include sm {
              height: auto;
            }
          }
        }

        legend {
          position: absolute;
          right: 29px;
          top: 51px;
          border: 2px solid $colorPelorous;
          color: $colorPelorous;
          height: 44px;
          line-height: 44px;
          width: 217px;
          text-align: center;
          font-family: $fontMontserrat;

          @include sm {
            position: static;
            height: auto;
            line-height: 1;
            width: auto;
            text-align: left;
          }

          span {
            height: 100%;
          }

          a {
            display: block;
            height: 100%;
            width: 100%;
            position: relative;
            z-index: 1;
            padding-right: 13px;
            box-sizing: border-box;

            @include sm {
              height: 70px;
              line-height: 70px;
              padding: 0 23px 0 33px;
              box-sizing: border-box;

              &:after {
                content: "";
                display: inline-block;
                background-image: url(../images/lg-blue-next-arrow.png);
                background-repeat: no-repeat;
                background-size: 13px 23px;
                width: 13px;
                height: 23px;
                margin-left: 40px;
                transform: rotate(-90deg);
                @include transition;
              }
            }
          }

          &:after {
            content: "";
            position: absolute;
            right: 11px;
            top: 14.5px;
            background-image: url(../images/lg-blue-next-arrow.png);
            background-repeat: no-repeat;
            background-size: 7px 13px;
            width: 7px;
            height: 13px;
            transform: rotate(-90deg);
            @include transition;

            @include sm {
              display: none;
            }
          }
        }

        .fieldset-wrapper {
          margin-top: 30px;
          color: $colorBlack;

          @include sm {
            margin-top: 60px;
          }

          .info-abonnement {
            border: 1px solid $colorAlto;
            border-width: 1px 0;
            padding: 17px 0;
            margin-bottom: 28px;

            @include sm {
              border-width: 2px 0;
              padding: 21px 0;
              margin-bottom: 49px;
            }
          }

          .bottom-abonnement {
            @include clearfix;

            .info-cb {
              float: left;
              width: 50%;

              @include sm {
                float: none;
                width: 100%;
              }

              .infos-2-cb .exp-cb:before {
                content: "\A";
                white-space: pre;
              }
            }

            .label-info-cb {
              display: flex;
              align-items: center;
              margin-bottom: 18px;
            }

            .links-abonnement {
              float: left;
              width: 50%;
              box-sizing: border-box;
              padding-right: 45px;
              text-align: right;

              @include sm {
                float: none;
                padding-right: 0;
                width: 100%;
                text-align: left;
              }

              ul {
                margin-bottom: 0;
              }

              li {
                padding: 0 !important;
                background-image: none !important;

                &:first-of-type {
                  margin: 24px 0;
                }
              }

              a {
                color: $colorPelorous;
                display: inline-flex;
                align-items: center;

                &:after {
                  content: "";
                  background-image: url(../images/sm-blue-next-arrow.png);
                  background-position: center;
                  background-repeat: no-repeat;
                  display: inline-block;
                  width: 5px;
                  height: 10px;
                  margin-left: 9px;

                  @include sm {
                    background-image: url(../images/lg-blue-next-arrow.png);
                    width: 13px;
                    height: 23px;
                    margin-left: 13px;
                  }
                }
              }
            }

            .ico-cb {
              background-image: url(../images/cc-icon.png);
              background-repeat: no-repeat;
              background-position: center;
              width: 41px;
              height: 30px;
              margin-right: 10px;
              display: inline-block;

              @include sm {
                background-image: url(../images/cc-icon-mobile.png);
                width: 68px;
                height: 50px;
                margin-right: 15px;
              }
            }

            .value {
              display: inline-block;
              color: $colorPelorous;
              margin-right: 20px;
            }
          }
        }

        .collapsed {
          legend::after {
            transform: rotate(90deg);
          }

          @include sm {
            legend {
              a {
                &:after {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }
      }
    }
  }
  #form_account {
    margin: 0 !important;

    #div_lost_password {
      width: 50%;
      background-color: $colorWhite;
      border: 0;
      margin: 0;
      padding: 20px;

      @include sm {
        width: 100%;
        margin-bottom: 40px;
        padding: 22px 18px;
        box-sizing: border-box;
      }

      div.form-item-mail {
        text-align: left;
      }

      h2 {
        color: $colorBlack;
        font-family: $fontMontserrat;
        font-size: 2rem;
        text-transform: uppercase;
        padding: 0;
        border: 0;
        margin-bottom: 20px;

        &:after {
          @include seperatorLine($colorRed);
        }

        @include sm {
          font-size: 3rem;
          margin-bottom: 0;

          &:after {
            width: 86px;
          }
        }
      }

      p {
        color: $colorBlack;
        font-size: 1.5rem;
        margin-bottom: 20px;

        @include sm {
          font-size: 2.5rem;
        }
      }

      h2 + p {
        font-weight: normal;
        font-size: 1.6rem;

        @include sm {
          font-size: 2.6rem;
        }
      }

      label {
        color: $colorBlack;
        font-weight: bold;
        font-size: 1.6rem;
        display: block;
        margin-bottom: 5px;

        @include sm {
          font-size: 2.6rem;
          margin-bottom: 25px;
        }
      }

      input {
        border: 1px solid $colorEbb;
        width: 100%;
        max-width: 450px;
        font-size: 1em;

        @include sm {
          max-width: 100%;
          font-size: 2.6rem;
          border-width: 2px;
        }

        &.submit_button {
          border: 2px solid $colorPelorous;
          background-image: url(../images/valider.png);
          background-color: $colorPelorous;
          background-position: center;
          background-size: inherit;
          width: 176px;
          padding: 0;
          height: 40px;
          box-sizing: content-box;
          margin: 20px 0 0 0;

          @include sm {
            background-image: url(../images/valider-mobile.png);
            width: 282px;
            height: 70px;
            display: block;
            margin: 50px auto 0;
          }

          &:hover {
            background-color: $colorPelorous;
            box-shadow: none;
            color: $colorWhite;
          }
        }
      }
    }
  }

  #form_account_errors_display {
    td {
      background-position: left 10px center !important;

      &:before {
        display: none !important;
      }
    }
  }

  &.logged-in #div_global_registration_etu,
  &.logged-in #div_global_registration_ent,
  &.logged-in #div_global_registration_par,
  &.logged-in #div_global_registration_ens {
    padding: 0 !important;
  }

  .default-address,
  .handle-address {
    > .title {
      color: $colorBlack;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $fontMontserrat;
      font-size: 2.4rem;
      line-height: 40px;
      margin: 0 0 25px;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        background-color: $colorSilver;
        display: inline-block;
        margin: 28px 0 0 15px;
        position: absolute;
        width: 100%;
        height: 1px;
      }

      @include sm {
        font-size: 3.4rem;
        line-height: 50px;
        text-transform: uppercase;
      }
    }

    label,
    .list .title {
      color: $colorBlack;
      font-family: $fontMontserrat;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      &:after {
        @include seperatorLine($colorRed);
      }

      @include sm {
        font-size: 3rem;
      }
    }
  }
}

.mention-abonnement {
  float: left;
  padding: 20px 0;

  a {
    color: $colorPelorous;
  }
}

.update-cb-alert {
  background-color: #fc4e52;
  padding: 13px 12px 17px;
  color: $colorWhite;
  line-height: 1.8rem;
  margin-top: 29px;

  @include sm {
    line-height: 2.5rem;
  }

  a {
    text-decoration: underline;
  }

  .ico-warning {
    background-image: url(../images/icon-error.png);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    float: left;
    width: 39px;
    height: 39px;
    margin-right: 9px;
  }
}

.modal-default {
  @include sm {
    width: 100vw !important;
    height: 100vh !important;
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
  }

  .ctools-modal-content {
    padding: 0 !important;
    position: relative;
    overflow: hidden;
    border: 0 !important;

    @include sm {
      width: 100% !important;
      height: 100% !important;
      margin: 0;
      overflow-y: auto;
    }

    .form-item label {
      width: 11em;

      &[for="edit-num-control"] {
        width: auto;
        margin-right: 10px;
      }

      @include sm {
        line-height: 50px;

        &[for="edit-num-control"],
        &[for="edit-month"] {
          line-height: 70px;
        }
      }
    }
  }

  .modal-header {
    box-sizing: border-box;
    background-color: $colorEbb !important;
    font-family: $fontMontserrat;
    padding: 25px 70px !important;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0px;

    @include sm {
      padding: 25px 85px !important;
    }

    .modal-title {
      font-size: 2rem !important;
      color: $colorBlack !important;
      white-space: normal !important;

      @include sm {
        font-size: 3rem !important;
      }
    }
  }

  .modal-content {
    padding: 20px !important;
    height: auto !important;
    width: auto !important;
  }

  .resiliation-abonnement-title {
    display: none;
  }

  .resiliation-abonnement-text {
    margin-bottom: 20px;

    a {
      color: $colorPelorous;

      &:focus {
        outline: 0;
      }
    }
  }

  .resiliation-abonnement-actions {
    text-align: center;

    input {
      padding-right: 24px;
      min-width: 0;
      margin: 0 5px;

      @include sm {
        box-sizing: border-box;
        padding-right: 48px;
      }
    }
  }

  .fieldset-legend {
    font-weight: bold;
    text-transform: uppercase;
  }

  .form-item > label {
    font-weight: bold;
    width: 11em;
    line-height: 30px;
  }

  .form-item-type-card {
    display: inline-block;
    margin-right: 20px;
  }

  input[type="radio"] + label {
    font-weight: normal;
    width: auto;
    line-height: 21px;
    margin-right: 20px;

    &:before {
      background-image: url(../images/radio-sprite-white.png);

      @include sm {
        background-image: url(../images/radio-sprite-white-mobile.png);
      }
    }
  }

  .info-cb {
    margin-bottom: 30px;

    .fieldset-legend {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &:before {
        content: "";
        background-image: url(../images/cc-icon.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 41px;
        height: 30px;
        margin-right: 10px;
        display: inline-block;

        @include sm {
          background-image: url(../images/cc-icon-mobile.png);
          width: 68px;
          height: 50px;
          margin-right: 15px;
        }
      }
    }

    .value {
      display: inline-block;
      color: $colorPelorous;
      margin-right: 20px;
    }
  }

  #edit-old-cb-info {
    .fieldset-wrapper {
      padding-left: 20px;

      @include sm {
        padding-left: 0;
      }
    }
  }

  .new-cb-info {
    @include clearfix;

    @include sm {
      min-width: 0;
    }

    .form-item {
      margin: 5px 0;

      label {
        font-weight: normal;
        text-align: right;
        margin-right: 10px;

        @include sm {
          width: auto;
          margin-bottom: 10px;
          text-align: left;
        }
      }

      &.form-item-type-card {
        width: 100%;

        .form-radios {
          clear: none;
          float: left;
        }

        .form-item {
          width: auto;
          margin: 0;

          label.option {
            &[for="edit-type-card-visa"],
            &[for="edit-type-card-eu"],
            &[for="edit-type-card-cb"] {
              width: 58px;
              height: 30px;
              position: relative;
              overflow: hidden;
              &:before {
                top: 4px;

                @include sm {
                  top: 0;
                }
              }
              &:after {
                content: "";
                background-image: url(../images/visa.png);
                background-repeat: no-repeat;
                background-position: left top;
                width: 100%;
                height: 30px;
                display: block;
                position: absolute;
                left: 28px;
                background-color: #fff;
                background-size: auto 30px;
                top: 0;
              }

              @include sm {
                width: 144px;
                height: 50px;

                &:after {
                  background-image: url(../images/visa-mobile.png);
                  background-size: auto 50px;
                  width: 100%;
                  height: 50px;
                  left: 80px;
                }
              }
            }
            &[for="edit-type-card-eu"] {
              &:after {
                background-image: url(../images/mastercard.png);

                @include sm {
                  background-image: url(../images/mastercard-mobile.png);
                }
              }
            }

            &[for="edit-type-card-cb"] {
              &:after {
                background-image: url(../images/cb.png);

                @include sm {
                  background-image: url(../images/cb-mobile.png);
                }
              }
            }
          }
        }
      }
    }

    > legend {
      margin-bottom: 10px;

      .fieldset-legend {
        &:before {
          content: "";
          background-image: url(../images/cc-icon.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 41px;
          height: 30px;
          margin-right: 10px;
          display: inline-block;

          @include sm {
            background-image: url(../images/cc-icon-mobile.png);
            width: 68px;
            height: 50px;
            margin-right: 15px;
          }
        }
      }
    }

    select {
      min-width: 100px;

      @include sm {
        min-width: 150px;
      }
    }

    .form-item-num-card {
      @include sm {
        margin-bottom: 15px;
      }
    }

    .form-item-month,
    .form-item-year {
      float: left;
      margin-right: 10px;
    }

    .form-item-num-control {
      float: left;

      @include sm {
        input {
          width: 150px;
        }
      }
    }

    .select2-container--default .select2-selection--single {
      height: 30px;
      border-color: $colorPelorous;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: 30px;
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        height: 26px;
        width: 25px;
        border-left: 2px solid $colorPelorous;

        b {
          border-color: $colorPelorous transparent transparent transparent;
        }
    }
  }

  .modifier-cb-actions {
    text-align: center;
    margin-top: 40px;
  }

  .close {
    background-image: url(../images/mobile-close.png);
    background-position: center center;
    background-size: 28px 27px;
    cursor: pointer;
    float: none;
    height: 27px;
    margin-right: 0;
    margin-top: 0;
    position: absolute !important;
    right: 16px;
    width: 28px;
    top: 22px;
    z-index: 1;
    text-indent: -9999px;
    outline: 0;

    &:hover {
      background: none;
      background-size: 28px 27px;
      background-image: url(../images/mobile-close-hover.png);
    }

    @include sm {
      background-size: auto;
      width: 69px;
      height: 68px;

      &:hover {
        background-size: auto;
        background-image: url(../images/mobile-close.png);
      }
    }

    img {
      display: none;
    }
  }
}

.backdrop-default,
#cboxOverlay {
  background: $colorBlack !important;
  opacity: .5 !important;
}

#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxMiddleLeft,
#cboxMiddleRight,
#cboxBottomLeft,
#cboxBottomCenter,
#cboxBottomRight,
#cboxLoadingOverlay,
#cboxLoadingGraphic {
  @include sm {
    display: none !important;
  }
}

#colorbox {
  overflow: auto;

  @include sm {
    width: 100vw !important;
    height: 100vh !important;
    left: 0 !important;
    top: 0 !important;
    position: fixed !important;
  }
}

#cboxWrapper,
#cboxLoadedContent {
  @include sm {
    width: 100vw !important;
    height: 100% !important;
  }
}

#cboxContent {
  @include sm {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100vw !important;
    height: 100vh !important;
  }
}

#cboxLoadedContent {
  margin-bottom: 0;
  margin-top: 27px;
  box-sizing: border-box;
  overflow-x: hidden !important;

  #page {
    width: 500px;
    min-height: 150px;
    display: flex;
    align-items: center;

    @include sm {
      width: 100%;
    }
  }

  @include sm {
    padding: 0 21px 150px;
    box-sizing: border-box;
    height: auto !important;
    margin-top: 89px;
  }
}

#cboxWrapper {
  border-radius: 0;

  #cboxClose {
    background-image: url(../images/mobile-close.png);
    background-position: center center;
    background-size: 28px 27px;
    cursor: pointer;
    float: none;
    height: 27px;
    margin-right: 0;
    margin-top: 0;
    position: absolute !important;
    right: 0;
    width: 28px;
    top: 0;
    z-index: 1;
    text-indent: -9999px;
    outline: 0;

    &:hover {
      background: none;
      background-size: 28px 27px;
      background-image: url(../images/mobile-close-hover.png);
    }

    @include sm {
      background-size: auto;
      height: 68px;
      width: 69px;
      top: 21px;
      right: 21px;

      &:hover {
        background-size: auto;
        background-image: url(../images/mobile-close.png);
      }
    }
  }

  p {
    text-align: center;
  }

  #edit-sub-title {
    font-size: 2rem;
    font-weight: bold;
  }

  label {
    font-weight: bold;
    margin: 10px 0 2px;
    display: block;
  }

  input, select {
    width: 500px;
    max-width: 100%;

    @include sm {
      width: 100%;
    }

    //@include ie {
    //  width: 484px !important;
    //  max-width: 100%;
    //}
  }

  .form-item-field-adresse-adresse-und-0-civility {
    select {
      width: 100px;

      @include sm {
        margin: 0;
      }
    }
  }

  select {
    &.country {
      width: 500px;
      max-width: 100%;
    }

    @include sm {
      width: 100% !important;
      min-width: 727px;
    }

    //@include ie {
    //  min-width: 0;
    //}
  }

  input {
    &.last-name,
    &.first-name {
      width: 190px;

      @include sm {
        width: 100%;
      }

      @include ie {
        width: 245px !important;
      }
    }

    &.postal-code {
      width: 105px;

      @include sm {
        width: 100% !important;
      }
    }
  }

  .form-item {
    margin-right: 0;

    @include sm {
      margin-right: 0;
      width: 100%;
    }

    &.form-item-field-adresse-adresse-und-0-postal-code,
    &.form-item-field-adresse-adresse-und-0-civility,
    &.form-item-field-adresse-adresse-und-0-last-name {
      margin-right: 10px;

      @include sm {
        margin-right: 0;
      }
    }

    &.form-item-field-adresse-adresse-und-0-locality {
      width: 385px;

      @include sm {
        width: 100%;
      }

      @include ie {
        width: 384px;
      }
    }

    .select2-container {
      @include sm {
        width: 100% !important;
      }

      @include ie {
        width: 500px !important;
      }
    }
  }

  .form-actions {
    margin: 20px 0 0;
    text-align: center;
    width: 500px;
    display: flex;
    justify-content: center;

    @include sm {
      width: 100%;
      flex-wrap: wrap;
    }
    //
    //@include ie {
    //  overflow-x: hidden;
    //}

    a#edit-cancel {
      @include button($background: $colorPelorous, $color: $colorWhite);
      margin-left: 10px;
      display: inline-block;
      height: 26px;
      line-height: 36px;
      box-sizing: border-box;

      &:hover {
        color: $colorPelorous;
      }

      @include sm {
        margin-left: 0;
        margin-top: 10px;
        line-height: 66px;
      }

      //@include ie {
      //  margin-left: 10px;
      //  margin-top: 0;
      //  line-height: 24px;
      //  height: 26px !important;
      //}
    }

    input, a#edit-cancel {
      width: 80%;
      max-width: 200px;
      height: 40px;

      @include sm {
        height: 70px;
        width: 100%;
        max-width: 100%;
        background-image: url(../images/lg-blue-next-arrow.png);
      }

      //@include ie {
      //  width: 80%;
      //  max-width: 200px;
      //  background-image: url(../images/sm-blue-next-arrow.png);
      //}
    }
  }

  #eck-entity-form-edit-adresse-adresse,
  #eck-entity-form-add-adresse-adresse {
    input[type="submit"] {
      background-color: $colorPelorous;
      color: $colorWhite;

      &:hover {
        background-color: $colorWhite;
        color: $colorPelorous;
      }
    }
  }

  .select2-container--default {
    .select2-selection--single {
      border-color: $colorPelorous;
      height: 30px;

      .select2-selection__rendered {
        line-height: 30px;
        padding: 0 30px 0 14px;
        font-family: $defaultFont;
        font-size: 1.4rem;
      }

      .select2-selection__arrow {
        height: 28px;
        position: absolute;
        top: 1px;
        right: 1px;
        width: 30px;
        border-color: $colorPelorous;

        b {
          border-color: $colorDoveGray transparent transparent transparent;
          border-width: 6px 6px 0 6px;
          margin-left: -6px;
          margin-top: -3px;
        }
      }
    }

    @include sm {
      .select2-selection--single {
        height: 70px;

        .select2-selection__rendered {
          line-height: 70px;
          padding: 0 70px 0 14px;
          font-size: 2.4rem;
        }

        .select2-selection__arrow {
          height: 68px;
          width: 70px;

          b {
            border-width: 12px 12px 0 12px;
            margin-left: -12px;
            margin-top: -6px;
          }
        }
      }
    }
  }
  .select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border-color: $colorBlack transparent transparent transparent;
        }
      }
    }
  }
}

.page-user {
  table {
    border-collapse: collapse;
    color: $colorBlack;

    th {
      padding: 9px 23px;
      font-size: 1.4rem;
      background-color: $colorEbb;
      text-align: left;
      text-transform: uppercase;
      color: $colorBlack;

      @include sm {
        display: inline-block;
        font-size: 2.4rem;
        padding: 30px 18px;
        width: 50%;
        box-sizing: border-box;

        &.views-field-order-number {
          text-align: right;
        }

        &.views-field-commerce-order-total,
        &.views-field-suivi,
        &.views-field-details {
          display: none;
        }
      }
    }

    tr {
      background-color: $colorWhite;
      border-top: 3px solid $colorWildSand;

      &:nth-child(2n) {
        background-color: $colorWhite;
      }

      @include sm {
        border-top: 8px solid $colorWildSand;
        @include clearfix;
      }
    }

    thead {
      tr {
        border-top: 0;
      }
    }

    td {
      text-align: left;

      @include sm {
        padding: 14px 20px;
      }

      &.views-field-created {
        font-size: 1.4rem;

        @include sm {
          font-size: 2.4rem;
          width: 50%;
          border-bottom: 2px solid $colorWildSand;
          line-height: 2.6rem;
        }
      }

      &.views-field-order-number {
        @include sm {
          width: 50%;
          clear: none;
          border-bottom: 2px solid $colorWildSand;
          line-height: 2.6rem;
          text-align: right;
        }
      }

      &.views-field-commerce-order-total {
        color: $colorPelorous;
        font-family: $fontMontserrat;
        font-weight: bold;

        @include sm {
          width: 35%;
          float: right;
          text-align: right;
        }
      }

      &.views-field-suivi {
        a {
          color: $colorPelorous;
        }

        @include sm {
          width: 65%;
        }
      }

      &.views-field-details {
        text-align: right;

        a {
          display: inline-block;
          text-align: center;
          color: $colorPelorous;
          border: 2px solid $colorPelorous;
          font-weight: 600;
          padding: 8px 0;
          min-width: 105px;
          line-height: 1;
          @include transition;

          &:after {
            content: "";
            display: inline-block;
            background-image: url(../images/button-details-icon-hover.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px;
            width: 16px;
            height: 16px;
            margin-left: 9px;
          }

          &:hover {
            background-color: $colorPelorous;
            color: $colorWhite;

            &:after {
              background-image: url(../images/button-details-icon.png);
            }
          }
        }

        @include sm {
          text-align: center;
          padding-top: 0;

          a {
            min-width: 300px;
            padding: 20px 0;
            background-image: url(../images/button-details-icon-hover-mobile.png);
            background-position: right 15px center;
            background-repeat: no-repeat;

            &:after {
              display: none;
            }

            &:hover {
              background-image: url(../images/button-details-icon-mobile.png);
            }
          }
        }
      }

      @include sm {
        box-sizing: border-box;

        b.tablesaw-cell-label {
          display: none;
        }
      }
    }

    img {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .commerce-order-commerce-order {
    > .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        flex: 1 1 100%;
      }

      .field-type-commerce-customer-profile-reference,
      .address-billing,
      .payment-method,
      .shipping-info,
      .address-email,
      .address-shipping {
        background-color: $colorWhite;
        box-sizing: border-box;
        padding: 26px;
        flex: 0 1 calc(50% - 5px);
        margin: 10px 0 30px;

        @include sm {
          flex: 0 1 100%;
          padding: 22px 18px;
          margin: 20px 0 0;
        }

        .field-label,
        h4 {
          float: left;
          font-family: $fontMontserrat;
          font-size: 2rem;
          font-weight: bold;
          width: 100%;
          text-transform: uppercase;
          margin-bottom: 0;

          &:after {
            @include seperatorLine($colorRed);
          }

          @include sm {
            font-size: 3rem;
            &:after {
              width: 86px;
            }
          }
        }
      }

      .field-name-commerce-customer-shipping {
        @include sm {
          margin-bottom: 40px;
        }
      }

      .address-billing,
      .payment-method,
      .address-shipping,
      .address-email {
        margin-bottom: 0;
      }

      .shipping-info {
        flex: 0 1 100%;
      }

      table {
        margin-bottom: 0;
      }

      tr {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        @include sm {
          display: flex;
          border: 0;

          &:first-child {
            border-top: 3px solid $colorWildSand
          }

          td.views-field-line-item-title {
            flex: 1 0 60%;

            .tablesaw-cell-content {
              max-width: 100%;
            }
          }

          td.views-field-quantity {
            flex: 1 0 10%;
          }

          td.views-field-commerce-total {
            flex: 1 0 30%;
          }

          td.component-title {
            flex: 1 0 70%;
            text-align: right;
            padding-top: 8px;
            padding-bottom: 8px;
          }

          td.component-total {
            flex: 1 0 30%;
            text-align: right;
            padding-top: 8px;
            padding-bottom: 8px;
          }

          &.component-type-commerce-price-formatted-amount {
            font-weight: bold;
            font-size: 2.9rem;
            font-family: $fontMontserrat;

            .component-total {
              color: $colorPelorous;
            }
          }
        }

        th {
          display: block;
          flex: 1 1 100%;
        }
      }

      .views-field-commerce-unit-price,
      th.views-field-quantity {
        display: none;
      }

      .views-field-commerce-total {
        margin-left: auto;
        text-align: right;
        min-width: 10%;
      }

      td.views-field-quantity {
        min-width: 5%;
        &:before {
          content: "x";
        }
      }

      .field-name-commerce-order-total .commerce-price-formatted-components {
        width: 100%;

        tr {
          border-top: 0;

          td {
            padding-top: 8px;
            padding-bottom: 8px;
          }

          &:first-of-type {
            padding-top: 35px;
            border-top: 3px solid $colorWildSand;
          }

          &:last-of-type {
            padding-bottom: 35px;
            background-color: $colorWhite;
            font-family: $fontMontserrat;
            font-size: 1.9rem;

            .component-total {
              color: $colorPelorous;
            }
          }
        }

        .component-title {
          text-align: right;
          width: 90%;
        }

        .component-total {
          width: 10%;
        }
      }
    }
  }

  .order-details {
    .spacer,
    .padding,
    .spacer-large {
      display: none !important;
    }

    table {

      @include sm {
        td.product-line {
          flex: 1 0 60%;
        }

        td.product-quantity {
          flex: 1 0 10%;
        }

        td.product-total {
          flex: 1 0 30%;
          text-align: right;
        }
      }

      tfoot {
        td {
          display: block;
          width: 100%;
        }

        table {
          tr {
            border-top: 0;

            @include sm {
              display: flex;
              border-top: 0 !important;
            }

            td {
              text-align: right;
              width: 75%;
              padding: 8px 0;

              @include sm {
                width: 65%;
              }

              &.blue,
              &.ttc {
                width: 25%;

                @include sm {
                  width: 35%;
                }
              }

              strong,
              &.ttc {
                background-color: $colorWhite;
                font-family: $fontMontserrat;
                font-size: 1.9rem;

                @include sm {
                  font-size: 2.9rem;
                }
              }

              &.ttc {
                color: $colorPelorous;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    th {
      &.product-total {
        text-align: right;
      }
    }
  }

  .order-bottom {
    margin-bottom: 40px;
    text-align: center;

    @include sm {
      margin-top: 20px;
    }

    a {
      display: inline-block;
      line-height: 30px;
      margin-right: 20px;
      width: 220px;

      @include sm {
        width: 100%;
        line-height: 70px;
        height: 70px !important;
        margin: 10px 0;
        background-image: none !important;
      }
    }

    a.mes-commandes {
      @include button($colorWhite);
      background-image: url(../images/blue-slider-arrow.png);
      background-position: left 10px center;
      background-size: auto 16px;

      &:hover {
        background-image: url(../images/white-slider-arrow.png);
      }
    }

    a.lien-suivi {
      @include button($colorPelorous, $colorPelorous, $colorWhite, $colorPelorous);
      background-image: url(../images/lg-white-next-arrow.png);
      background-position: right 10px center;
      background-size: auto 16px;

      &:hover {
        background-image: url(../images/lg-blue-next-arrow.png);
      }

      &.disabled {
        background-image: none;
      }
    }
  }

  .select2-container {
    z-index: 9999;
  }
}

#lerobert-adresse-liste-adresses-form {
  input#edit-submit {
    margin: 20px auto;
    font-size: 1.6rem;
    background-color: $colorPelorous;
    color: $colorWhite;

    &:hover {
      color: $colorPelorous;
      background-color: $colorWhite;
    }

    @include sm {
      width: 100%;
      font-size: 2.6rem;
      height: 70px;
      background-image: url(../images/lg-blue-next-arrow.png);
      margin-bottom: 40px;
    }
  }

  select {
    width: 100%;
    max-width: 300px;

    @include sm {
      max-width: 100%;
      font-size: 2.6rem;
    }
  }
}

.default-address {
  #lerobert-adresse-liste-adresses-form > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;

    .form-item {
      background-color: $colorWhite;
      box-sizing: border-box;
      flex-basis: calc(50% - 62px);
      flex: 0 0 calc(50% - 10px);
      padding: 26px;
      margin: 0 5px;

      @include sm {
        padding: 30px 18px;
        flex: 0 0 100%;
        margin: 10px 0;
      }
    }

    @include sm {
      margin: 0;
    }

    select {
      border: 1px solid $colorEbb;
    }

    .select2-container--default {
      .select2-selection--single {
        border: 1px solid $colorEbb;
        height: 30px;

        .select2-selection__rendered {
          line-height: 30px;
          padding: 0 30px 0 14px;
          font-family: $defaultFont;
          font-size: 1.6rem;
        }

        .select2-selection__arrow {
          height: 28px;
          position: absolute;
          top: 1px;
          right: 1px;
          width: 30px;
          border-left: 1px solid $colorEbb;

          b {
            border-color: $colorDoveGray transparent transparent transparent;
            border-width: 6px 6px 0 6px;
            margin-left: -6px;
            margin-top: -3px;
          }
        }
      }

      @include sm {
        .select2-selection--single {
          border-width: 2px;
          height: 70px;

          .select2-selection__rendered {
            line-height: 70px;
            padding: 0 70px 0 20px;
            font-size: 2.6rem;
          }

          .select2-selection__arrow {
            height: 66px;
            top: 2px;
            right: 2px;
            width: 70px;
            border-left: 2px solid $colorEbb;

            b {
              border-width: 14px 14px 0 14px;
              margin-left: -14px;
              margin-top: -7px;
            }
          }
        }
      }
    }
    .select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow {
          b {
            border-color: $colorBlack transparent transparent transparent;
          }
        }
      }
    }
  }
}

.handle-address {
  margin-bottom: 40px;

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -5px;

    > div {
      background-color: $colorWhite;
      box-sizing: border-box;
      flex-basis: calc(50% - 62px);
      flex: 0 0 calc(50% - 10px);
      padding: 26px;
      margin: 0 5px 10px;
      min-height: 213px;

      a {
        @include button;
        min-width: auto;
        width: calc(50% - 10px);
        line-height: 26px;
        height: 26px;
        margin: 10px 0 0;
        display: inline-block;

        &:nth-child(1n) {
          margin-right: 0;
          margin-left: 5px;
        }

        &:nth-child(2n) {
          margin-left: 0;
          margin-right: 5px;
        }

        &.edit {
          background-color: $colorPelorous;
          color: $colorWhite;

          &:hover {
            color: $colorPelorous;
            background-color: $colorWhite;
          }
        }

        @include sm {
          width: 100%;
          line-height: 66px;
          height: 66px;
          margin: 10px 0;
          background-image: url(../images/lg-blue-next-arrow.png);

          &:nth-child(1n) {
            margin-left: 0;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }

      &:not(.entity-adresse) {
        display: flex;
        background-color: $colorEbb;
        justify-content: center;
        align-items: center;

        a {
          width: 80%;
          margin: 0;
          background-color: $colorPelorous;
          color: $colorWhite;
          height: 40px;
          line-height: 40px;

          @include sm {
            width: 100%;
            height: 66px;
            line-height: 66px;
          }
        }
      }

      @include sm {
        padding: 30px 18px;
        flex: 0 0 100%;
        margin: 10px 0;
        min-height: 0;
      }
    }

    @include sm {
      margin: 0;
    }
  }
}

.page-user-adresses {
  input[type='submit'] {
    background-image: none !important;
    font-size: 1.6rem;
    line-height: 40px;
    height: 40px;
    box-sizing: content-box;
    @include sm {
      font-size: 3rem;
    }
  }

  .handle-address .list > div a {
    background-image: none;
    height: 40px;
    line-height: 40px;

    &[data-popin-creation-adresse="1"] {
      background-image: url(../images/plus-icon-white.png);
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: right 10px center;

      &:hover {
        color: $colorPelorous;
        background-color: $colorWhite;
        background-image: url(../images/plus-icon-blue.png);
      }

      @include sm {
        background-size: auto;
        background-position: right 20px center;
      }


    }
  }

  #eck-entity-delete-form {
    input,
    a {
      font-size: 1.6rem;
      background-image: none !important;

      @include sm {
        font-size: 3rem;
      }
    }
  }
}

.page-user .calendar {
  table {
    th {
      padding: 10px 0 0;
      text-align: center;
      display: table-cell;
    }

    tr {
      border-top: 0;
    }

    td {
      text-align: center;
      display: table-cell;

      @include sm {
        padding: 8px 14px;
      }
    }
  }
}

.page-mes-commandes {
  #main-content {
    padding-bottom: 60px;

    @include sm {
      padding-bottom: 40px;
    }
  }

  #mes-commandes {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 0;

    @include sm {
      display: none;
    }

    th {
      background-color: transparent;
      font-size: 1.4rem;
      color: $colorBlack;
      text-transform: uppercase;
      text-align: left;

      a {
        float: none;
        color: $colorBlack;
      }

      .aide-state {
        position: relative;
        top: -2px;
        vertical-align: middle;
      }

      &:last-child {
        font-size: 0;
        color: transparent;
      }
    }

    tr {
      background-color: transparent;

      td {
        background: $colorWhite;
        border: 0;
        border-bottom: 5px solid $colorWildSand;
        font-size: 1.4rem;

        &.order-details {
          text-align: right;
        }
      }
    }
  }

  .link-order {
    display: inline-block;
    margin: 0 30px 0 0;
    padding: 6px 30px;
    border: 2px solid $colorPelorous;
    background-color: transparent;
    color: $colorPelorous;
    line-height: 29px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0;
    text-transform: none;

    &::after {
      content: "";
      position: relative;
      top: 1px;
      display: inline-block;
      width: 6px;
      height: 11px;
      margin: 0 0 0 12px;
      @include backgroundImage('lg-blue-next-arrow.png', 100% 100%, center, no-repeat);
    }

    &:hover,
    &:focus {
      background-color: $colorPelorous!important;
      color: $colorWhite!important;

      &::after {
        @include backgroundImage('next-arrow-slider.png', 100% 100%, center, no-repeat);
      }
    }
  }

  .commandes-mobile {
    > li {
      margin-bottom: 10px;
      padding: 20px;
      border-bottom: 0;
      background-image: none;
      background-color: $colorWhite;
    }

    .state-label.bold {
      font-family: $fontMontserrat;
      color: $colorBlack;
      font-size: 2.6rem;
    }
  }

  .btn.mes-commandes {
    display: inline-block;
    padding: 6px 12px;
    border: 2px solid $colorPelorous;
    background-color: transparent;
    color: $colorPelorous;
    line-height: 29px;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 0;
    text-transform: none;

    &::before {
      content: "";
      position: relative;
      top: 1px;
      display: inline-block;
      width: 6px !important;
      height: 11px;
      margin: 0 12px 0 0;
      @include backgroundImage('lg-blue-next-arrow.png', 100% 100%, center, no-repeat);
      transform: rotate(180deg);
      padding: 0!important;
    }

    &:hover,
    &:focus {
      background-color: $colorPelorous!important;
      color: $colorWhite!important;

      &::before {
        @include backgroundImage('next-arrow-slider.png', 100% 100%, center, no-repeat);
      }
    }
  }

  .wrapper-entete,
  .wrapper-details,
  .wrapper-informations,
  .wrapper-payment {
    * {
      box-sizing: border-box;
    }
  }

  .wrapper-entete {
    display: flex;
    background: none;
    font-size: 1.4rem;
    padding: 15px 30px;
    box-sizing: border-box;

    @include sm {
      font-size: 2rem;
    }
  }

  .wrapper-details {
    .line-product {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      background-color: $colorWhite;
      margin-bottom: 5px;
      border-bottom: 0;
      padding: 15px 30px;

      .product {
        font-family: $fontMontserrat;
        font-weight: bold;
        color: $colorBlack;
        font-size: 1.8rem;

        @include sm {
          font-size: 2.6rem;
        }
      }

      .product-price {
        color: $colorPelorous;
        font-weight: bold;
        font-family: $fontMontserrat;
        font-size: 1.8rem;

        @include sm {
          font-size: 2.6rem;
        }
      }
    }

    .fdp,
    .remise,
    .total,
    .sub-total {
      padding: 0;
    }

    .wrapper-label-sub {
      width: auto;
      margin-left: auto;
      margin-right: 10px;
      background: none;
    }

    .wrapper-price {
      width: auto;
    }

    .wrapper-sub-total {
      margin: 0;
      padding: 30px 30px 0;
      background-color: $colorWhite;
      box-sizing: border-box;
      font-family: $fontMontserrat;
      font-size: 1.5rem;

      @include sm {
        font-size: 2.2rem;
      }
    }

    .wrapper-total {
      margin-bottom: 5px;
      padding: 0 30px 30px;
      background-color: $colorWhite;
      box-sizing: border-box;
      font-family: $fontMontserrat;
      font-weight: bold;

      .total {
        align-items: flex-end;
      }

      .wrapper-label-sub {
        font-size: 1.7rem;
        color: $colorBlack;

        @include sm {
          font-size: 2.4rem;
        }
      }

      .wrapper-price {
        font-size: 2rem;
        color: $colorPelorous;

        @include sm {
          font-size: 3rem;
        }
      }
    }
  }

  .wrapper-informations,
  .wrapper-payment {
    margin-bottom: 5px;
    padding: 30px;
    background-color: $colorWhite;
    box-sizing: border-box;
  }
}
