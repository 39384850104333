////////////////////////
// HTML elements
//
// Override here the prime rules.
//
////////////////////////

html {
  font-size: 62.5%;
  overflow-x: hidden;

  @include sm {
    -webkit-text-size-adjust: none;
  }
}

body {
  color: $colorTuna;
  font-family: $defaultFont;
  font-size: 1.6em;
  line-height: 1.2;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: $colorShark;

  @include sm {
    font-size: 2.6rem;
  }

  &.popin-news-open::before {
    content: "";
    background-color: rgba($colorSteelGray, 0.6);
    position: fixed;
    z-index: 13;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

// IE list style fix
// http://stackoverflow.com/a/23441387/317559
li {
  list-style: none;
  list-style-image: none;
}

a {
  text-decoration: none;
  @include transition;
}

em {
  font-style: italic;
}
b, strong {
  font-weight: 700;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

sub,
sup {
  vertical-align: baseline;
  position: relative;
  font-size: 0.7692em;
  line-height: 1;
}

sup {
  bottom: 1.4ex;
}

sub {
  top: .5ex;
}

#toolbar,
#main-wrapper {
  background-color: $colorWildSand;
  @include fullWidthBackground($colorWildSand);
}

::-ms-reveal {
  display: none
}

table.sticky-header {
  visibility: hidden !important;
}
