.node-auteur {
  .top {
    margin-top: 27px;
    @include clearfix;

    @include sm {
      margin-top: 40px;
    }
  }
  .left {
    float: left;
    width: 25%;

    @include sm {
      width: 100%;
      float: none;
      margin-bottom: 40px;
    }
  }
  .field-name-field-auteur-photo {
    text-align: center;
    padding-top: 10px;
    img {
      display: inline-block;
      border-radius: 50%;
    }

    @include sm {
      padding-top: 0;

      img {
        width: 352px;
      }
    }
  }
  .right {
    float:left;
    width: 75%;

    @include sm {
      width: 100%;
      float: none;
    }
  }
  .field-name-title-field {
    color: $colorBlack;
    font-family: $fontMontserrat;
    font-size: 3.2rem;
    font-weight: bold;
    text-transform: uppercase;
    &:after {
      @include seperatorLine($width: 57px);
    }
  }
  .field-name-field-auteur-profession {
    font-weight: bold;
    margin-bottom: 25px;
  }

  .field-name-field-auteur-site-web {
    font-weight: bold;
    margin-bottom: 15px;
    color: $colorPelorous2;
  }

  .field-name-field-auteur-biographie {
    margin-bottom: 30px;
    line-height: 2.6rem !important;
  }
  .videos-tab {
    display: block;
    padding: 56px 0;
    @include fullWidthBackground($colorTuna);
    &:before {
      display: block;
    }
  }
  .title {
    @extend #page-title;
    font-size: 3.2rem !important;
    width: 50%;
    &:after {
      display: none !important;
    }

    @include sm {
      font-size: 4.2rem !important;
      width: 100%;
    }
  }
  .mes-ouvrages {
    @include clearfix;
  }

  @include sm {
    .mes-ouvrages {
      margin-bottom: 40px;

      > .list {
        @include clearfix;
        margin: 0 -10px;
      }
    }
  }
}
